import { useDispatch } from "react-redux";

import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, Typography, IconButton, Grid, FormControl, InputLabel, Select, MenuItem, Card } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from "zod";
import { Product } from "./ProductService";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from 'uuid';
import { uploadImageS3Bucket } from "../../utils/s3bucketImg";

const sizes = [
    {
        path: "products/sm",
        width: 100,
        height: 100,
        quality: 30,
    },
    {
        path: "products/md",
        width: 300,
        height: 300,
        quality: 50,
    },
    {
        path: "products/lg",
        width: 600,
        height: 600,
        quality: 80,
    }
];

const MAX_FILE_SIZE = 500000;
const ACCEPTED_IMAGE_TYPES = ["image/jpeg", "image/jpg", "image/png", "image/webp"];

const schema = z.object({
    pTitle: z.string().min(1, { message: 'Required' }),
    pSTitle: z.string().min(1, { message: 'Required' }),
    sku: z.string().min(1, { message: 'Required' }),
    select_brand: z.coerce.number().min(1, { message: 'Required' }),
    category: z.coerce.number().min(1, { message: 'Required' }),
    subcategory: z.coerce.number().min(1, { message: 'Required' }),
    subsubcategory: z.coerce.number().min(1, { message: 'Required' }),
    description: z.string(),
    sDescription: z.string(),
    cgst: z.coerce.number(),
    sgst: z.coerce.number(),
    igst: z.coerce.number(),
    cess: z.coerce.number(),
});

export default function AddProduct({ closeEvent, populateProduct, rowId }) {
    const { register, handleSubmit, reset, setValue, watch, formState: { errors } } = useForm({
        mode: "onChange", resolver: zodResolver(schema)
    });
    const [productImage, setProductImage] = useState([]);
    const [showImages, setShowImages] = useState([]);
    const [imageError, setImageError] = useState("");
    const [loading, setLoading] = useState(false);
    const [oldImages, setOldImages] = useState([])

    const unCode = 'SKU' + (new Date()).getTime();
    const [errorMsg, setErrorMsg] = useState("");

    const [brands, setBrands] = useState([]);
    const [brandValue, setBrandValue] = useState("");

    const [category, setCategory] = useState([]);
    const [categoryValue, setCategoryValue] = useState("");

    const [subCategory, setSubCategory] = useState([]);
    const [subCategoryValue, setSubCategoryValue] = useState("");

    const [subSubCategory, setSubSubCategory] = useState([]);
    const [subSubCategoryValue, setSubSubCategoryValue] = useState("");

    const sku = unCode.toUpperCase();
    populateProduct(0);
    const brandList = async () => {
        const brands = await Product.getBrandList();
        setBrands(brands.data);
    }
    const categoryList = async () => {
        const category = await Product.getCategoryList();
        setCategory(category.data);
    }
    useEffect(() => {
        brandList();
        categoryList();
        if (Object.keys(rowId).length) {
            setLoading(true)
            setValue('category', rowId.product_categories[0].category);
            setCategoryValue(rowId.product_categories[0].category)
            const subCategoryList = async () => {
                const subCategory = await Product.getSubCategoryList(rowId.product_categories[0].category);
                if (subCategory) {
                    setSubCategory(subCategory.data);
                    const subSubCategoryList = async () => {
                        const subSubCategory = await Product.getSubSubCategoryList(rowId.product_categories[0].sub_category);
                        setSubSubCategory(subSubCategory.data);
                        setLoading(false);
                    }
                    subSubCategoryList();
                }
            }
            subCategoryList();
            setValue('subcategory', rowId.product_categories[0].sub_category);
            setSubCategoryValue(rowId.product_categories[0].sub_category)
            setValue('subsubcategory', rowId.product_categories[0].sub_sub_category_id);
            setSubSubCategoryValue(rowId.product_categories[0].sub_sub_category_id)
            setValue("select_brand", rowId.brand_id, { shouldValidate: true });
            setBrandValue(rowId.brand_id)
            setValue("pTitle", rowId.title);
            setValue("pSTitle", rowId.short_title);
            setValue("sku", rowId.sku);
            setValue("description", rowId.description);
            setValue("sDescription", rowId.short_description);
            setValue("cgst", rowId.cgst);
            setValue("sgst", rowId.sgst);
            setValue("igst", rowId.igst);
            setValue("cess", rowId.cess);
            setOldImages(rowId.product_master_images);
        } else {
            setValue("sku", sku);
        }
    }, [rowId])

    const handleFileChange = (e) => {
        const file = e.target.files;
        let images = [];
        for (let img of file) {
            images.push(URL.createObjectURL(img))
        }
        setImageError("");
        setShowImages(images);
        setProductImage(file);
    };

    function onSubmit(data) {
        const productData = {
            title: data.pTitle,
            short_title: data.pSTitle,
            brand_id: data.select_brand,
            sku: data.sku,
            short_description: data.sDescription,
            description: data.description,
            category_id: subSubCategoryValue,
            cgst: data.cgst,
            sgst: data.sgst,
            igst: data.igst,
            cess: data.cess,
        };
        if (!Object.keys(rowId).length) {
            if (productImage.length < 1) {
                setImageError("At least one image is required")
                return false;
            } else {
                setImageError("");
            }
        }
        const createProduct = async () => {
            setErrorMsg("");
            setLoading(true)
            let createProduct = "";
            if (Object.keys(rowId).length) {
                createProduct = await Product.updateProduct(rowId.id, productData);
            } else {
                createProduct = await Product.addProduct(productData)
            }

            if (createProduct.success) {
                let imageindex = 0;
                if (productImage.length > 0) {
                    for (let imgRow of productImage) {
                        const imgType = (imageindex == 0) ? 1 : 0;
                        const imageName = (imageindex == 0) ? `product-${uuidv4()}-0.jpeg` : `product-${uuidv4()}-1.jpeg`;
                        const uploadFile = async () => {
                            for (let sizeImg of sizes) {
                                const imageOption = {
                                    path: sizeImg.path,
                                    file: imgRow,
                                    fileName: imageName,
                                    imgWidth: sizeImg.width,
                                    imgHeight: sizeImg.height,
                                    imgType: "JPEG",
                                    quality: sizeImg.quality
                                }
                                const imageRes = await uploadImageS3Bucket(imageOption);
                                if (sizeImg.width == 600) {
                                    const imageUploadData = {
                                        productId: (Object.keys(rowId).length) ? rowId.id : createProduct.data.id,
                                        image_type: (Object.keys(rowId).length) ? 0 : imgType,
                                        image_title: imageName,
                                        image_url: imageRes.Key
                                    }

                                    const createImage = async () => {
                                        const imgRes = await Product.productImageCreate(imageUploadData);
                                    }
                                    createImage();
                                }
                            }
                            setLoading(false)
                        };
                        uploadFile();
                        imageindex++;
                    }
                }

                Swal.fire(createProduct.message, '', 'success')
                reset();
                closeEvent();
                populateProduct(1);
            } else {
                setErrorMsg(createProduct.message);
            }
        }
        createProduct();
    }
    const brandHandle = (e) => {
        setBrandValue(e.target.value)
    }
    const categoryHandel = (e) => {
        setCategoryValue(e.target.value);
        const subCategoryList = async () => {
            const subCategory = await Product.getSubCategoryList(e.target.value);
            setSubCategory(subCategory.data);
            setSubSubCategory([]);
        }
        subCategoryList();
    }

    const subCategoryHandel = (e) => {
        setSubCategoryValue(e.target.value);
        const subSubCategoryList = async () => {
            const subSubCategory = await Product.getSubSubCategoryList(e.target.value);
            setSubSubCategory(subSubCategory.data);
        }
        subSubCategoryList();
    }

    const subSubCategoryHandle = (e) => {
        setSubSubCategoryValue(e.target.value)
    }


    return (
        <>
            <Box p={1} mt={0} />
            <Typography variant="h5" align="center">
                Add Product
            </Typography>
            <IconButton
                style={{ position: "absolute", top: 0, right: 0 }}
                onClick={closeEvent}
            >
                <CloseIcon />
            </IconButton>
            <Typography variant="inherit" color="red">
                {errorMsg}
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} mt={1}>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            id="pTitle"
                            label="Title"
                            variant="outlined"
                            name="pTitle"
                            size="small"
                            sx={{ minWidth: "100%" }}
                            {...register('pTitle')}
                            error={errors.pTitle ? true : false}
                        />
                        <Typography variant="inherit" color="red">
                            {errors.pTitle?.message}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <TextField
                            id="pSTitle"
                            name="pSTitle"
                            label="Short Title"
                            variant="outlined"
                            size="small"
                            sx={{ minWidth: "100%" }}
                            {...register('pSTitle')}
                            error={errors.pSTitle ? true : false}
                        />
                        <Typography variant="inherit" color="red">
                            {errors.pSTitle?.message}
                        </Typography>
                    </Grid>

                    <Grid item lg={2} xs={12}>
                        <FormControl sx={{ minWidth: "100%" }} size="small">
                            <InputLabel id="demo-simple-select-autowidth-label">Brand</InputLabel>
                            <Select
                                labelId="select_brand"
                                id="select_brand"
                                name="select_brand"
                                label="Brand"
                                value={brandValue}
                                onChange={brandHandle}
                                inputProps={{ ...register('select_brand') }}
                                error={errors.select_brand ? true : false}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {brands?.map((brand) => (
                                    <MenuItem value={brand.id}>{brand.title}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Typography variant="inherit" color="red">
                            {errors.select_brand?.message}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} mt={1}>
                    <Grid item lg={9} xs={12}>
                        <Grid container spacing={2} mt={1}>
                            <Grid item lg={4} xs={12}>
                                <FormControl sx={{ minWidth: "100%" }} size="small">
                                    <InputLabel id="demo-simple-select-autowidth-label">Select Category</InputLabel>
                                    <Select
                                        labelId="category"
                                        id="category"
                                        name="category"
                                        label="Select Category"
                                        value={categoryValue}
                                        onChange={categoryHandel}
                                        inputProps={{ ...register('category') }}
                                        error={errors.category ? true : false}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {category?.map((category) => (
                                            <MenuItem value={category.id}>{category.title}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <Typography variant="inherit" color="red">
                                    {errors.category?.message}
                                </Typography>
                            </Grid>

                            <Grid item lg={4} xs={12}>
                                <FormControl sx={{ minWidth: "100%" }} size="small">
                                    <InputLabel id="demo-simple-select-autowidth-label">Select Sub Category</InputLabel>
                                    <Select
                                        labelId="subcategory"
                                        id="subcategory"
                                        name="subcategory"
                                        label="Select Sub Category"
                                        value={subCategoryValue}
                                        onChange={subCategoryHandel}
                                        inputProps={{ ...register('subcategory') }}
                                        error={errors.subcategory ? true : false}                            >
                                        {subCategory?.map((subCategory) => (
                                            <MenuItem value={subCategory.id}>{subCategory.title}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <Typography variant="inherit" color="red">
                                    {errors.subcategory?.message}
                                </Typography>
                            </Grid>
                            <Grid item lg={4} xs={12}>
                                <FormControl sx={{ minWidth: "100%" }} size="small">
                                    <InputLabel id="demo-simple-select-autowidth-label">Select Sub Sub Category</InputLabel>
                                    <Select
                                        labelId="subsubcategory"
                                        id="subsubcategory"
                                        name="subsubcategory"
                                        label="Select Sub Sub Category"
                                        value={subSubCategoryValue}
                                        onChange={subSubCategoryHandle}
                                        inputProps={{ ...register('subsubcategory') }}
                                        error={errors.subcategory ? true : false}                            >
                                        {subSubCategory?.map((subsubcategory) => (
                                            <MenuItem value={subsubcategory.id}>{subsubcategory.title}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <Typography variant="inherit" color="red">
                                    {errors.subsubcategory?.message}
                                </Typography>
                            </Grid>
                            <Grid item lg={4} xs={12}>
                                <TextField
                                    id="sku"
                                    name="sku"
                                    label="SKU"
                                    variant="outlined"
                                    size="small"
                                    sx={{ minWidth: "100%" }}
                                    {...register('sku')}
                                    error={errors.sku ? true : false}
                                />
                                <Typography variant="inherit" color="red">
                                    {errors.sku?.message}
                                </Typography>
                            </Grid>
                            <Grid item lg={2} xs={6}>
                                <TextField
                                    id="cgst"
                                    name="cgst"
                                    label="CGST(%)"
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                    sx={{ minWidth: "100%" }}
                                    {...register('cgst')}
                                    error={errors.cgst ? true : false}
                                />
                                <Typography variant="inherit" color="red">
                                    {errors.cgst?.message}
                                </Typography>
                            </Grid>
                            <Grid item lg={2} xs={6}>
                                <TextField
                                    id="sgst"
                                    name="sgst"
                                    type="number"
                                    label="SGST(%)"
                                    variant="outlined"
                                    size="small"
                                    sx={{ minWidth: "100%" }}
                                    {...register('sgst')}
                                    error={errors.sgst ? true : false}
                                />
                                <Typography variant="inherit" color="red">
                                    {errors.sgst?.message}
                                </Typography>
                            </Grid>
                            <Grid item lg={2} xs={6}>
                                <TextField
                                    id="igst"
                                    name="igst"
                                    label="IGST(%)"
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                    sx={{ minWidth: "100%" }}
                                    {...register('igst')}
                                    error={errors.igst ? true : false}
                                />
                                <Typography variant="inherit" color="red">
                                    {errors.igst?.message}
                                </Typography>
                            </Grid>
                            <Grid item lg={2} xs={6}>
                                <TextField
                                    id="cess"
                                    name="cess"
                                    label="CESS(%)"
                                    variant="outlined"
                                    type="number"
                                    size="small"
                                    sx={{ minWidth: "100%" }}
                                    {...register('cess')}
                                    error={errors.cess ? true : false}
                                />
                                <Typography variant="inherit" color="red">
                                    {errors.cess?.message}
                                </Typography>
                            </Grid>
                            <Grid item lg={4} xs={12}>
                                <TextField
                                    id="sDescription"
                                    name="sDescription"
                                    label="Short Description"
                                    variant="outlined"
                                    multiline
                                    {...register('sDescription')}
                                    rows={3}
                                    sx={{ minWidth: "100%" }}
                                />
                            </Grid>
                            <Grid item lg={8} xs={12}>
                                <TextField
                                    id="description"
                                    name="description"
                                    label="Description"
                                    variant="outlined"
                                    multiline
                                    {...register('description')}
                                    rows={3}
                                    sx={{ minWidth: "100%" }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h5" align="center">
                                    <Button variant="outlined" color="secondary" type="submit">Save</Button>
                                </Typography>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item lg={3} xs={12} >
                        <Card sx={{p:1}}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <label htmlFor="upload-image">
                                    <Button sx={{width:"100%"}} variant="outlined" component="span">
                                        Choose Product Images
                                    </Button>

                                    <input
                                        id="upload-image"
                                        hidden
                                        {...register('productImage', { required: true })}
                                        error={errors.productImage ? true : false}
                                        accept="image/*"
                                        type="file"
                                        name="productImage"
                                        onChange={handleFileChange}
                                        multiple
                                    />
                                </label>
                                <Typography variant="inherit" color="red">
                                    {imageError}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {showImages && showImages.map((imageRow) => (
                                    <Box
                                        component="img"
                                        sx={{
                                            height: 40,
                                            width: 40,
                                            marginLeft: "5px",
                                            borderRadius: "10px"
                                            // maxHeight: { xs: 233, md: 167 },
                                            // maxWidth: { xs: 350, md: 250 },
                                        }}
                                        alt="The house from the offer."
                                        src={imageRow}
                                    />
                                ))}
                            </Grid>
                            <Grid item xs={12}>
                                {oldImages && oldImages.map((imageRow) => (
                                    <Box
                                        component="img"
                                        sx={{
                                            height: 80,
                                            width: 80,
                                            marginLeft: "5px",
                                            borderRadius: "10px"
                                            // maxHeight: { xs: 233, md: 167 },
                                            // maxWidth: { xs: 350, md: 250 },
                                        }}
                                        alt="The house from the offer."
                                        src={`${imageRow.image_url}md/${imageRow.image_title}`}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}