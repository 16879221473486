import React, { useEffect, useState } from 'react'

const ResendTimer = ({counter,setCounter}) => {
    const [seconds, setSeconds] = useState(); 
    const [minutes, setMinutes] = useState(); 
    
    useEffect(() => {
      var minute = Math.floor(((counter*1000) % (1000 * 60 * 60)) / (1000 * 60));
      var second = Math.floor(((counter*1000) % (1000 * 60)) / 1000);
      counter >= 0 && setTimeout(() => {
        setCounter(counter - 1);
        setMinutes(minute)
        setSeconds(second);
      }, 1000);
    }, [counter]);
  return (
    <>
      
      { minutes <10 ? "0"+minutes:minutes}:{seconds<10 ?"0"+seconds:seconds}
      
      
    </>
  )
}

export default ResendTimer
