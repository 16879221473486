import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box, Card, Stack, Typography, Button, Modal, Grid, Divider, Paper, TextField } from '@mui/material';
import { isMobile } from 'react-device-detect';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import Swal from 'sweetalert2';
import { useDispatch } from "react-redux";
import PersonalDetails from './PersonalDetail';
import BankDetails from './BankDetails';
import AddressDetails from './AddressDetails';
import { useNavigate, useParams } from 'react-router-dom';
import { User } from './UserService';
import Swal from 'sweetalert2';
import { LocalStore } from '../../storage/authStore';

const style = {
    position: 'absolute',
    top: (isMobile) ? '80%' : '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: (isMobile) ? "95%" : "25%",
    bgcolor: 'background.paper',
    border: '2px solid #ddd',
    boxShadow: 24,
    p: 2,
};

export default function UserProfile() {
    const { id } = useParams();
    const navigate = useNavigate();
    const userDetail = LocalStore.getCurrentUserDetails();
    const role = (userDetail) ? userDetail.role : 0;

    if(role==2){
        if(id != userDetail.id){
            navigate(`/admin/userProfile/${userDetail.id}`);
        }
    }

    const menuDispatch = useDispatch();
    menuDispatch({
        type: 'menuSelect',
        payload: 'profile'
    });
    const [tabValue, setTabValue] = useState(0);

    const [personalDetails, setPersonalDetails] = useState({});
    const [bankDetail, setBankDetail] = useState({});
    const [addressDetail, setAddressDetail] = useState({});

    const [loading, setLoading] = useState(false)
    const [firstOne, setFirstOne] = useState(true)
    const [userId, setUserId] = useState(0);

    const fetchUserDetails = async () => {
        try {
            setLoading(true)
            const resUser = await User.userDetailById(id);
            if (resUser.success) {
                const userData = resUser.data;
                setPersonalDetails({
                    id: userData.id,
                    first_name: userData.first_name,
                    last_name: userData.last_name,
                    gender: userData.gender,
                    mobile_number: userData.mobile_number,
                    email: userData.email,
                    gstIn: userData.gstIn,
                    profile_image: userData.profile_image,
                    banner_image: userData.banner_image,
                    role: userData.role
                });
                if (userData.user_bank_detail) {
                    setBankDetail({
                        id: userData.user_bank_detail.id,
                        userId: userData.id,
                        bank_name: userData.user_bank_detail.bank_name,
                        bank_account_number: userData.user_bank_detail.bank_account_number,
                        ifsc_code: userData.user_bank_detail.ifsc_code,
                        user_bank_name: userData.user_bank_detail.user_bank_name,
                        blank_check_image: userData.user_bank_detail.blank_check_image,
                        paytm_mobile_no: userData.user_bank_detail.paytm_mobile_no,
                        phonepay_mobile_no: userData.user_bank_detail.phonepay_mobile_no,
                        gpay_mobile_no: userData.user_bank_detail.gpay_mobile_no,
                        upi_id: userData.user_bank_detail.upi_id,
                    });
                }
                if (userData.user_address) {
                    setAddressDetail({
                        id: userData.user_address.id,
                        userId: userData.id,
                        full_address: userData.user_address.full_address,
                        home_building_no: userData.user_address.home_building_no,
                        city: userData.user_address.city,
                        landmark: userData.user_address.landmark,
                        district: userData.user_address.district,
                        state: userData.user_address.state,
                        postal_code: userData.user_address.postal_code
                    });
                }
                setUserId(userData.id)
            } else {
                Swal.fire('Request failed','','error')
            }
        } catch (error) {
            Swal.fire(error.message,'','error')
        }
        setLoading(false)
    };

    useEffect(() => {
        if (id > 0) {
            fetchUserDetails();
        }
    }, [id]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const handleFormSubmit = (e) => {
        e.preventDefault();
    };
    const handlePreviousClick = () => {
        setTabValue(0);
    };
    const handlePreviousClickForAdd = () => {
        setTabValue(1);
    };



    const handlePersonalSubmit = (data) => {
        setPersonalDetails(data);
    };

    const handleBankSubmit = (data) => {
        setBankDetail(data);
    };

    const handleAddressSubmit = (data) => {
        setAddressDetail(data);
    };

    const handleNextTab = (tab) => {
        if(userId>0){
            setTabValue(tab);
        }
    }

    const handlePrivousTab = (tab) => {
        setTabValue(tab);
    }
    return (
        <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
            <Card sx={{ minWidth: "100%", marginBottom: "10px" }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={6}>
                        <Typography
                            gutterBottom
                            variant='h5'
                            component='div'
                            sx={{ padding: '20px' }}
                        >
                            Profile
                        </Typography>
                    </Grid>

                </Grid>

            </Card>
            <Box sx={{ marginTop: 1 }}>
                <Grid container spacing={1}>

                    <Grid item xs={12} lg={12}>
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            variant="fullWidth"
                            sx={{ width: isMobile ? "100%" : "700px", backgroundColor: "#fff" }}
                        >
                            <Tab label="Personal Details" />
                            {(userId > 0) && (
                                <Tab label="Bank Details" />
                            )}
                            {(userId > 0) && (
                                <Tab label="Address" />
                            )}
                        </Tabs>
                        {tabValue === 0 && (
                            <Paper elevation={3} style={{ padding: 16 }}>
                                <PersonalDetails userIdProp={id} personalDetails={personalDetails} onSubmitPersonal={handlePersonalSubmit} tabNextChange={handleNextTab} />
                            </Paper>
                        )}

                        {tabValue === 1 && (
                            <Paper elevation={3} style={{ padding: 16 }}>
                                <BankDetails userIdProp={id} bankDetail={bankDetail} onSubmitBank={handleBankSubmit} tabNextChange={handleNextTab} />
                            </Paper>
                        )}

                        {tabValue === 2 && (
                            <Paper elevation={3} style={{ padding: 16 }}>
                                <AddressDetails userIdProp={id} addressDetail={addressDetail} onSubmitAddress={handleAddressSubmit} tabNextChange={handleNextTab} />
                            </Paper>
                        )}

                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};



