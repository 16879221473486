import { Box } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import CategoryTabs from "./CategoryTabs";

export default function Category() {
    const menuDispatch = useDispatch();
    menuDispatch({
        type: 'menuSelect',
        payload: 'category'
    });
    return (
        <>
            <Box component="main" sx={{ flexGrow: 1, p: 2 }}>            
                <Box sx={{ width: '100%', marginBottom: 2 }}>
                    <CategoryTabs />                    
                </Box>
            </Box>
        </>
    )
}
