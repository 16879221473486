import { commonRequest } from "../../services/ApiCall";
import { Helpers } from "../../services/Helpers";

export const User = {
    allUser : async (data) =>{
        return await commonRequest("POST",  `${Helpers.URL()}/users/`, data, Helpers.token());
    },

    userDetailById : async (id) =>{
        return await commonRequest("get", `${Helpers.URL()}/users/details/${id}`, {}, Helpers.token());
    },

    addUser : async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/users/create`,data, Helpers.token());
    },

    updateUser : async (id, data) =>{
        return await commonRequest("PUT",  `${Helpers.URL()}/users/${id}`, data, Helpers.token());
    },

    changeStatus: async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/users/statusChange`,data, Helpers.token());
    },

    addBankDetail : async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/users/bankDetail/create`,data, Helpers.token());
    },

    updateBankDetail : async (id, data) =>{
        return await commonRequest("PUT",  `${Helpers.URL()}/users/bankDetail/${id}`, data, Helpers.token());
    },

    addAddressDetail : async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/users/addressDetail/create`,data, Helpers.token());
    },

    updateAddressDetail : async (id, data) =>{
        return await commonRequest("PUT",  `${Helpers.URL()}/users/addressDetail/${id}`, data, Helpers.token());
    },

    locationByPincode: async(pincode) => {
        return await commonRequest("get", `${Helpers.URL()}/location/bypincode/${pincode}`, {});
    }

    // deleteBrand : async (id) =>{
    //     return await commonRequest("DELETE",  `${Helpers.URL()}/brand/${id}`, {}, Helpers.token());
    // },
    
}