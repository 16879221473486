import { Box } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import CustomerList from './CustomerList'

export default function Customer(){
    const menuDispatch = useDispatch();
    menuDispatch({
        type: 'menuSelect',
        payload: 'customer'
    });
    return (
        <>
            <CustomerList />            
        </>
    )
}
