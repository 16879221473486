import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { LocalStore } from "../../storage/authStore";

export default function Private(){
    let isLogged = LocalStore.isLogin();

    if(isLogged){
        return <Outlet />;
    }else{
        return <Navigate to={"/"} />
    }    
}

