import { Box, Button, Card, Chip, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Paper, Select, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from 'react-hook-form';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { LocalStore } from "../../storage/authStore";
import { Services } from "./Services";
import Swal from "sweetalert2";
import { DataGrid } from "@mui/x-data-grid";
import QrCodeIcon from '@mui/icons-material/QrCode';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { isMobile } from "react-device-detect";
import ImgUpload from "./ImgUpload";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { formatCurrency } from "../../utils/currencyFormatter";

const style = {
    position: 'absolute',
    top: (isMobile) ? '55%' : '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: (isMobile) ? "95%" : "30%",
    bgcolor: 'background.paper',
    border: '2px solid #ddd',
    boxShadow: 24,
    p: 2,
};
const gridStyle = {
    fontSize: '14px',

};
export default function OrderTransaction({ currentTrigger, triggerBody, totalAmt, orderId }) {
    const { register, handleSubmit, control, reset, formState: { errors } } = useForm();
    const [divTrigger, setDivTrigger] = useState(true)
    const [payList, setPayList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pStatus, setPStatus] = useState(0);
    const [qr, setQr] = useState("");
    const [trigStatus, setTrigStatus] = useState(0);
    const [needAmt, setNeedAmt] = useState(totalAmt);
    const [showImages, setShowImages] = useState("")
    const [payRowData, setPayRowData] = useState({});
    const validationRules = {
        required: 'Required',
    };
    const generateQr = async (amount) => {
        try {
            const qrRes = await Services.generateNewQr(amount);
            setQr(qrRes);
        } catch (error) {
            Swal.fire(error.message, '', 'error')
        }
    }
    const getList = async () => {
        const listRes = await Services.getOrderPaymentList(orderId);
        if (listRes.success) {
            if (listRes.data.length > 0) {
                setNeedAmt(listRes.data[0].new_amount);
            }
            setPayList(listRes.data);
        }
    }
    useEffect(() => {
        setDivTrigger(currentTrigger);
        getList();
    }, [currentTrigger, trigStatus]);

    const handleViewQrImage = (amount) => {
        generateQr(amount.toFixed(2));
        modalOpen();
    }

    const columns = [
        { field: "id", headerName: "ID", width: 50 },
        { field: "paid_amount", headerName: "Paid Amount", width: 140 },
        { field: "new_amount", headerName: "New Amount", width: 140 },
        { field: "total_amount", headerName: "Total Amount", width: 140 },
        {
            field: "qr_image", headerName: "QR Image", width: 150, renderCell: (params) => {
                return (
                    <>
                        {(params.row.payment_status < 1) ? (
                            <Button
                                variant='contained'
                                onClick={(e) => handleViewQrImage(params.row.paid_amount)}
                                sx={{ height: "30px", marginTop: "10px", marginRight: "10px" }}
                                startIcon={<QrCodeIcon />}
                                //variant="outlined"
                                color="primary"
                                size='small'
                            >
                                Show QR
                            </Button>
                        ) :

                            (<Chip sx={{ fontSize: "12px", height: "25px" }} icon={<QrCodeIcon style={{ fontSize: '12px' }} />} label="Expired" />)
                        }

                    </>
                )

            }
        },
        {
            field: "payment_status", headerName: "Status", width: 140, renderCell: (params) => {
                return (
                    <>
                        {(params.row.payment_status < 1) ? (
                            <FormControl sx={{ minWidth: "100%" }} size="small">
                                <InputLabel id="demo-simple-select-autowidth-label">Payment Status</InputLabel>
                                <Controller
                                    name="payment_status"
                                    control={control}
                                    defaultValue={params.row.payment_status}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            label="Select Order Status"
                                            InputProps={{
                                                readOnly: false
                                            }}
                                            value={pStatus}
                                            onChange={(e) => handleChangePaymentStatus(params.row, e.target.value)}
                                        >
                                            <MenuItem value="0">Pending</MenuItem>
                                            <MenuItem value="1">Completed</MenuItem>
                                            <MenuItem value="2">Cancel</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        ) :

                            (params.row.payment_status == 1) ? (
                                <Chip sx={{ fontSize: "12px", height: "25px", backgroundColor: "#b0e6a3", color: "#000000" }} icon={<CheckIcon style={{ fontSize: '12px' }} />} label="Completed" />
                            ) : (
                                <Chip sx={{ fontSize: "12px", height: "25px", backgroundColor: "#f27474", color: "#ffffff" }} icon={<CloseIcon style={{ fontSize: '12px' }} />} label="Canceled" />
                            )
                        }

                    </>
                )

            }
        },
        {
            field: "image_upload", align: 'center', headerName: "Image", width: 150, renderCell: (params) => {
                return (
                    <>
                        {(params.row.client_payment_image != null) ? (
                            <Button size="small" variant="outlined" onClick={(e) => viewPayImg(params.row.client_payment_image)}><RemoveRedEyeIcon /></Button>
                        ) : (
                            <Button size="small" variant="outlined" onClick={(e) => openModal(params.row)}><CloudUploadIcon /></Button>
                        )}
                    </>
                )
            }
        }
    ];

    const viewPayImg = (img) => {
        window.open(`${img}`);
    }

    const updatePaymentStatus = async (rowData, status) => {
        if (!rowData.id) return;
        const statusRes = await Services.changePaymentStatus(rowData.id, { payment_status: status, data: rowData });
        if (statusRes.success) {
            //setDeleteRowData(id);
            setTrigStatus(Math.floor(Math.random() * 100));
            Swal.fire('Status Change Successfully', '', 'success')
        } else {
            setTrigStatus(Math.floor(Math.random() * 100));
            Swal.fire(statusRes.message, '', 'error')
        }
    }

    const handleChangePaymentStatus = (rowData, newStatus) => {
        Swal.fire({
            title: 'Are you sure to change status?',
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Yes, Change Status!',
            denyButtonText: `No, cancel!`,
        }).then((result) => {
            if (result.isConfirmed) {
                updatePaymentStatus(rowData, newStatus);
            }
        })
    };

    function onGenerateQr(data) {
        if (data.amount > needAmt) {
            Swal.fire(`Required amount is Rs. ${needAmt.toFixed(2)} only`, '', 'error');
            return false;
        }
        const userDetail = LocalStore.getCurrentUserDetails();
        const SaveData = {
            orderId: orderId,
            payment_status: 0,
            paid_amount: data.amount,
            new_amount: 0,
            total_amount: totalAmt,
            action_by: 0,
            userId: userDetail.id,
        }
        const createTransaction = async () => {
            const payRes = await Services.createOrderStatusQrPayment(SaveData);
            if (payRes.success) {
                triggerBody(true);
                reset();
                Swal.fire(payRes.message, '', 'success');
            } else {
                Swal.fire(payRes.message, '', 'error');
            }
        }
        createTransaction();
    }

    const createNewQr = () => {
        triggerBody(false);
    }

    /************************  Modal State Manage **************************** */
    const [open, setOpen] = useState(false);

    const modalOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const [imgModalOpen, setImgModalOpen] = useState(false);

    const openModal = (data) => {
        setPayRowData(data);
        setImgModalOpen(true);
    };

    const closeModal = () => {
        setImgModalOpen(false);
    };

    const handlePImgTrig = (data) => {
        setTrigStatus(data);
    }

    /************************************************************************** */

    const addStatus = (payList[0]) ? true : false;
    return <>
        <Box>
            <ImgUpload open={imgModalOpen} onClose={closeModal} payRowData={payRowData} trigStatus={handlePImgTrig} />
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ overflow: "scroll" }}
            >
                <Box sx={style}>
                    <Box sx={{ position: 'absolute', right: '10px', top: '6px' }}>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{ marginTop: "40px" }}>
                        {qr && (
                            <div dangerouslySetInnerHTML={{ __html: qr }} />
                        )}
                    </Box>
                </Box>
            </Modal>
        </Box>
        <Card sx={{ minWidth: "100%" }}>
            
            
                <form onSubmit={handleSubmit(onGenerateQr)}>
                    <Grid container spacing={1} >
                    {(payList.length < 1 || payList[0].payment_status > 0) ? (
                        <Grid item lg={6} xs={12}>
                            <Grid container spacing={1} sx={{ padding: "14px 10px" }}>

                                {(needAmt < 1) ? (
                                    <Grid item lg={12}>
                                        <Button
                                            variant="contained"
                                            startIcon={<CheckIcon />}
                                            size="small"
                                            color="success"
                                            sx={{cursor:"none"}}                                            
                                        >
                                            Payment Done
                                        </Button>
                                    </Grid>
                                ) : (
                                    (divTrigger) && (
                                        <Grid item lg={12}>
                                            <Button
                                                variant="contained"
                                                startIcon={<AddCircleIcon />}
                                                onClick={createNewQr}
                                                size="small"

                                            >
                                                Create New QR Code
                                            </Button>
                                        </Grid>
                                    )
                                )}
                                {(!divTrigger) && (
                                    <Grid item lg={12}>
                                        <Grid container spacing={2}>
                                            <Grid item lg={6} xs={6}>
                                                <Controller
                                                    name="amount"
                                                    control={control}
                                                    rules={validationRules}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            placeholder="Enter Amount"
                                                            label="Amount"
                                                            size="small"
                                                            type="number"
                                                            InputProps={{
                                                                //readOnly: true,
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <CurrencyRupeeIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            fullWidth
                                                            error={!!errors.amount}
                                                            helperText={errors.amount && errors.amount.message}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item lg={3} xs={6}>
                                                <Button variant="contained" type="submit">Generate</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}

                            </Grid>
                        </Grid>
                    ):(
                        <Grid item lg={6} xs={12}></Grid>
                    )}
                        <Grid item lg={2} xs={0}></Grid>
                        <Grid item lg={4} xs={12}>
                            <Chip sx={{ fontSize: "14px", m: 2 }} label={`Balance Amt: ${formatCurrency(needAmt)}`} color="success" />
                        </Grid>
                    </Grid>
                </form>
            
            <Box>
                <Divider />
            </Box>
            <Box>
                <Box sx={{ minWidth: "100%", marginBottom: "10px" }}>
                    <Stack direction="row" spacing={1} className="">
                        <Typography
                            gutterBottom
                            variant='h6'
                            component='div'
                            p={2}
                        >
                            Payment History
                        </Typography>
                    </Stack>
                </Box>
                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                    <DataGrid
                        rows={payList}
                        columns={columns}
                        style={gridStyle}
                        getRowId={(row) => row.id}
                        loading={loading}
                        hideFooter={true}
                        autoHeight
                    />
                </Box>
            </Box>
        </Card>

    </>
}