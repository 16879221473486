import { useDispatch } from "react-redux";

import React, { useEffect, useState } from 'react';
import { TextField, Button, Container, Stack, Box, Typography, IconButton, Grid, FormControl, InputLabel, Select, MenuItem, InputAdornment, Divider, Chip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from "zod";
import Swal from "sweetalert2";
import { Services } from "./Services";
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import PercentIcon from '@mui/icons-material/Percent';
import { formatCurrency } from "../../utils/currencyFormatter";

const schema = z.object({
    title: z.string().min(1, { message: 'Required' }),
});

export default function AddEditOrderItem({ closeEvent, itemTrigger, rowData }) {
    const { control, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const validationRules = {
        required: 'This field is required',
    };
    const [errorMsg, setErrorMsg] = useState("");
    const [modalTtitle, setModalTtitle] = useState("Add Product");
    const [totalSellerAmt, setTotalSellerAmt] = useState(0.00);
    const [marginSalePrice, setMarginSalePrice] = useState(5);
    const [itemCalculation, setItemCalculation] = useState({
        total_amount: 0.00,
        sub_total: 0.00,
        total_tax: 0.00,
        total_discount: 0.00,
        total_profit: 0.00
    })

    useEffect(() => {
        if (Object.keys(rowData).length) {
            setModalTtitle("Edit Product")
            setValue("status", rowData.status);
            setValue("mrp", rowData.mrp);
            setValue("total_amount", rowData.total_amount);
            setValue("subtotal", rowData.subtotal);
            setValue("discount", rowData.discount);
            setValue("quantity", rowData.quantity);
            setValue("unit_price", rowData.unit_price);
            setValue("sale_price", rowData.sale_price);
            setValue("cgst", rowData.store_product.product_master.cgst);
            setValue("sgst", rowData.store_product.product_master.sgst);
            setValue("igst", rowData.store_product.product_master.igst);
            setValue("cess", rowData.store_product.product_master.cess);
            setItemCalculation({
                ...itemCalculation,
                total_amount: rowData.total_amount,
                sub_total: rowData.subtotal,
                total_tax: rowData.tax,
                total_discount: (rowData.subtotal * rowData.discount / 100),
                total_profit: (rowData.profit_amount > 0) ? rowData.profit_amount : 0.00
            })
        }
    }, [rowData])

    function onSubmit(data) {
        const addData = {
            store_orderId: rowData.store_orderId,
            store_id: rowData.store_id,
            store_productId: rowData.store_productId,
            productId: rowData.productId,
            mrp: rowData.mrp,
            unit_price: (data.unit_price) ? data.unit_price : 0.00,
            sale_price: (data.sale_price) ? data.sale_price : 0.00,
            quantity: (data.quantity) ? data.quantity : 0,
            discount: (data.discount) ? data.discount : 0,
            subtotal: itemCalculation.sub_total,
            total_amount: itemCalculation.total_amount,
            profit_amount: itemCalculation.total_profit,
            tax: itemCalculation.total_tax
        }

        const createRow = async () => {
            setErrorMsg("");

            let createRowRes = "";
            if (Object.keys(rowData).length) {
                if (data.status == 2 || data.status == 3) {
                    addData.quantity = 0;
                    addData.unit_price = 0;
                    addData.sale_price = 0;
                    addData.discount = 0;
                    addData.subtotal = 0;
                    addData.total_amount = 0;
                    addData.tax = 0;
                    addData.profit_amount = 0;
                }
                addData.status = data.status;
                createRowRes = await Services.updateNewOrderProduct(rowData.id, addData);
            } else {
                addData.store_orderId = data.store_orderId;
                addData.store_id = data.store_id;
                addData.store_productId = data.store_productId;
                addData.productId = data.productId;
                addData.mrp = data.mrp;
                createRowRes = await Services.addNewOrderProduct(addData)
            }

            if (createRowRes.success) {
                const itemData = createRowRes.data.store_order_items;
                const updateOrderData = {
                    tax: 0,
                    subtotal: 0,
                    total_amount: 0,
                    profit_amount: 0,
                };
                for (let item of itemData) {

                    updateOrderData.tax = parseFloat(updateOrderData.tax) + parseFloat(item.tax);
                    updateOrderData.total_amount = parseFloat(updateOrderData.total_amount) + parseFloat(item.total_amount);
                    updateOrderData.subtotal = parseFloat(updateOrderData.subtotal) + parseFloat(item.subtotal);
                    updateOrderData.profit_amount = parseFloat(updateOrderData.profit_amount) + parseFloat((item.profit_amount > 0) ? item.profit_amount : 0);
                }
                // if (createRowRes.data.discount > 0) {
                //     totalItemAmt = totalItemAmt - (totalItemAmt * (createRowRes.data.discount) / 100);
                // }
                if (createRowRes.data.delivery_charge > 0) {
                    updateOrderData.total_amount = parseFloat(updateOrderData.total_amount) + parseFloat(createRowRes.data.delivery_charge);
                }

                const orderRes = await Services.updateStoreOrder(createRowRes.data.id, updateOrderData)
                if (orderRes.success) {
                    Swal.fire(createRowRes.message, '', 'success')
                    reset();
                    closeEvent();
                    itemTrigger(Math.floor(Math.random() * 100));
                }
            } else {
                setErrorMsg(createRowRes.message);
            }
        }
        createRow();
    }

    const handlePriceChange = (e) => {
        const getData = getValues();
        const unitSellerPrice = getData.unit_price;
        const perUnitSalePrice = getData.sale_price;

        const minimumSalePrice = (getData.unit_price > 0) ? (parseFloat(getData.unit_price) + parseFloat((getData.unit_price * marginSalePrice / 100))) : 0.00;

        const newPerUnitSalePrice = (perUnitSalePrice >= minimumSalePrice) ? perUnitSalePrice : minimumSalePrice;
        setValue('sale_price', newPerUnitSalePrice);

        const subTotal = getData.quantity * newPerUnitSalePrice;

        let totalAmt = subTotal;
        let totalTax = 0.00;
        let totalSellerAmt = getData.quantity * unitSellerPrice;
        let totalDicountAmt = 0.00;

        if (getData.discount > 0) {
            totalDicountAmt = totalAmt * (getData.discount) / 100;
            totalAmt = totalAmt - totalDicountAmt;
            totalSellerAmt = totalSellerAmt - (totalSellerAmt * (getData.discount) / 100);
        }
        // Calculate GST For Sell Unit price
        const calculateCGST = (totalAmt * rowData.store_product.product_master.cgst) / 100;
        const calculateSGST = (totalAmt * rowData.store_product.product_master.sgst) / 100;
        const calculateIGST = (totalAmt * rowData.store_product.product_master.igst) / 100;
        const calculateCESS = (totalAmt * rowData.store_product.product_master.cess) / 100;

        const totalGST =
            parseFloat((calculateCGST > 0) ? calculateCGST : 0.00) +
            parseFloat((calculateSGST > 0) ? calculateSGST : 0.00) +
            parseFloat((calculateCESS > 0) ? calculateCESS : 0.00) +
            parseFloat((calculateIGST > 0) ? calculateIGST : 0.00);
        totalTax = parseFloat(totalGST)
        setValue("total_tax_amt", totalTax);
        totalAmt = parseFloat(totalAmt) + parseFloat(totalGST);

        // Calculate GST For Sell Unit price
        const calculateCGST_S = (totalSellerAmt * rowData.store_product.product_master.cgst) / 100;
        const calculateSGST_S = (totalSellerAmt * rowData.store_product.product_master.sgst) / 100;
        const calculateIGST_S = (totalSellerAmt * rowData.store_product.product_master.igst) / 100;
        const calculateCESS_S = (totalSellerAmt * rowData.store_product.product_master.cess) / 100;

        const totalSellerGST =
            parseFloat((calculateCGST_S > 0) ? calculateCGST_S : 0.00) +
            parseFloat((calculateSGST_S > 0) ? calculateSGST_S : 0.00) +
            parseFloat((calculateCESS_S > 0) ? calculateCESS_S : 0.00) +
            parseFloat((calculateIGST_S > 0) ? calculateIGST_S : 0.00);
        const totalSellerTax = parseFloat(totalSellerGST)
        const totalSellerAmount = parseFloat(totalSellerAmt) + parseFloat(totalSellerTax);
        const totalProfit = totalAmt - totalSellerAmount;
        setValue('total_discount_amt', totalDicountAmt);
        setItemCalculation({
            ...itemCalculation,
            total_amount: totalAmt,
            sub_total: subTotal,
            total_tax: totalTax,
            total_profit: totalProfit,
            total_discount: totalDicountAmt
        })

        setTotalSellerAmt(totalSellerAmt);
        setValue('total_amount', (totalAmt > 0) ? totalAmt.toFixed(2) : 0);
    }

    return (
        <>
            <Box p={1} mt={0} />
            <Grid container spacing={2} mt={1}>
                <Grid item lg={2} xs={6}>
                    <img
                        src={`${rowData.store_product.product_master.product_master_images[0].image_url}sm/${rowData.store_product.product_master.product_master_images[0].image_title}`}
                        alt={rowData.store_product.product_master.product_master_images[0].image_title}
                        style={{ maxWidth: '70px', maxHeight: '70px' }}
                    />
                </Grid>
                <Grid item lg={8} xs={6}>
                    {rowData.store_product.product_master.short_title}
                </Grid>
            </Grid>
            <IconButton
                style={{ position: "absolute", top: 0, right: 0 }}
                onClick={closeEvent}
            >
                <CloseIcon />
            </IconButton>
            <Typography variant="inherit" color="red">
                {errorMsg}
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item lg={8} xs={12} p={2} sx={{ borderRight: '1px solid #ddd' }}>
                        <Grid container spacing={2} mt={1}>
                            <Grid item lg={4} xs={6}>
                                <Controller
                                    name="mrp"
                                    control={control}
                                    rules={validationRules}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            placeholder="Enter MRP"
                                            label="MRP"
                                            size="small"
                                            type="number"
                                            InputProps={{
                                                readOnly: true,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <CurrencyRupeeIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            fullWidth
                                            error={!!errors.mrp}
                                            helperText={errors.mrp && errors.mrp.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item lg={4} xs={6}>
                                <FormControl sx={{ minWidth: "100%" }} size="small">
                                    <InputLabel id="demo-simple-select-autowidth-label">Order Status</InputLabel>
                                    <Controller
                                        name="status"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                label="Order Status"
                                                InputProps={{
                                                    readOnly: false
                                                }}
                                            //onChange={(e) => handleChangeItemStatus(params.row.id, e.target.value)}
                                            >
                                                <MenuItem value="0">Initiat</MenuItem>
                                                <MenuItem value="1">Accept</MenuItem>
                                                <MenuItem value="2">Out Of Stock</MenuItem>
                                                <MenuItem value="3">Cancel</MenuItem>
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={1}>
                            <Grid item lg={4} xs={6}>
                                <Controller
                                    name="quantity"
                                    control={control}
                                    rules={validationRules}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            placeholder="Enter QTY"
                                            label="Quantity"
                                            size="small"
                                            type="number"
                                            InputProps={{
                                                //readOnly: true,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <ProductionQuantityLimitsIcon />
                                                    </InputAdornment>
                                                ),
                                                inputProps: {
                                                    min: 0,   // Set the minimum value
                                                    step: "any"   // Set the step value
                                                },
                                                onChange: (e) => {
                                                    field.onChange(e); // Use React Hook Form's field.onChange function
                                                    handlePriceChange(e); // Call your custom handler here if needed
                                                }
                                            }}
                                            fullWidth
                                            error={!!errors.quantity}
                                            helperText={errors.quantity && errors.quantity.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item lg={8} xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item lg={6} xs={6}>
                                        <Controller
                                            name="unit_price"
                                            control={control}
                                            rules={validationRules}
                                            defaultValue="0.00"
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    placeholder="Unit Price (Seller Price)"
                                                    label="Unit Price (Seller Price)"
                                                    size="small"
                                                    type="number"
                                                    InputProps={{
                                                        //readOnly: true,
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <CurrencyRupeeIcon />
                                                            </InputAdornment>
                                                        ),
                                                        inputProps: {
                                                            min: 0,   // Set the minimum value
                                                            step: "any"   // Set the step value
                                                        },
                                                        onChange: (e) => {
                                                            field.onChange(e); // Use React Hook Form's field.onChange function
                                                            handlePriceChange(e); // Call your custom handler here if needed
                                                        }
                                                    }}
                                                    fullWidth
                                                    error={!!errors.unit_price}
                                                    helperText={errors.unit_price && errors.unit_price.message}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item lg={6} xs={6}>
                                        <Controller
                                            name="sale_price"
                                            control={control}
                                            defaultValue="0.00"
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    placeholder="Sell Price (Customer Price)"
                                                    label="Sell Price (Customer Price)"
                                                    size="small"
                                                    type="number"
                                                    InputProps={{
                                                        //readOnly: true,
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <CurrencyRupeeIcon />
                                                            </InputAdornment>
                                                        ),
                                                        inputProps: {
                                                            min: 0,   // Set the minimum value
                                                            step: "any"   // Set the step value
                                                        },
                                                        onChange: (e) => {
                                                            field.onChange(e); // Use React Hook Form's field.onChange function
                                                            handlePriceChange(e); // Call your custom handler here if needed
                                                        }
                                                    }}
                                                    fullWidth
                                                    error={!!errors.sale_price}
                                                    helperText={errors.sale_price && errors.sale_price.message}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item lg={12} sx={{ paddingTop: "0px !important" }}>
                                        <Typography variant="caption" display="block" gutterBottom>
                                            *Sell price should be {marginSalePrice}% greater then purchase price
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid container spacing={2} mt={1}>
                            <Grid item lg={4} xs={12}>
                                <Controller
                                    name="discount"
                                    control={control}
                                    //rules={validationRules}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            placeholder="Enter Discount(%)"
                                            label="Discount"
                                            size="small"
                                            type="number"
                                            InputProps={{
                                                readOnly: false,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PercentIcon />
                                                    </InputAdornment>
                                                ),
                                                inputProps: {
                                                    min: 0,   // Set the minimum value
                                                    step: "any"   // Set the step value
                                                },
                                                onChange: (e) => {
                                                    field.onChange(e); // Use React Hook Form's field.onChange function
                                                    handlePriceChange(e); // Call your custom handler here if needed
                                                }
                                            }}
                                            fullWidth
                                            error={!!errors.discount}
                                            helperText={errors.discount && errors.discount.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item lg={2} xs={6}>
                                <Controller
                                    name="cgst"
                                    control={control}
                                    defaultValue="0"
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            placeholder="CGST(%)"
                                            label="CGST(%)"
                                            size="small"
                                            type="number"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item lg={2} xs={6}>
                                <Controller
                                    name="sgst"
                                    control={control}
                                    defaultValue="0"
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            placeholder="SGST(%)"
                                            label="SGST(%)"
                                            size="small"
                                            type="number"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item lg={2} xs={6}>
                                <Controller
                                    name="igst"
                                    control={control}
                                    defaultValue="0"
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            placeholder="IGST(%)"
                                            label="IGST(%)"
                                            size="small"
                                            type="number"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item lg={2} xs={6}>
                                <Controller
                                    name="cess"
                                    control={control}
                                    defaultValue="0"
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            placeholder="CESS(%)"
                                            label="CESS(%)"
                                            size="small"
                                            type="number"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={4} xs={12} p={2}>
                        <Grid container spacing={2}>
                            <Grid item lg={12}>
                                <Grid container spacing={2}>
                                    <Grid item lg={7} xs={7}>
                                        <Typography variant="body1" sx={{ fontSize: "12px" }}>
                                            Sub Total
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={1} xs={1} sx={{ fontSize: "12px" }}>:</Grid>
                                    <Grid item lg={3} xs={3}>
                                        <Typography variant="body1" sx={{ fontSize: "12px" }}>
                                            {formatCurrency(itemCalculation.sub_total)}
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item lg={12}>
                                <Grid container spacing={2}>
                                    <Grid item lg={7} xs={7}>
                                        <Typography variant="body1" sx={{ fontSize: "12px" }}>
                                            Discount Amount (-)
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={1} xs={1} sx={{ fontSize: "12px" }}>:</Grid>
                                    <Grid item lg={3} xs={3}>
                                        <Typography variant="body1" sx={{ fontSize: "12px" }}>
                                            {formatCurrency(itemCalculation.total_discount)}
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item lg={12}>
                                <Grid container spacing={2}>
                                    <Grid item lg={7} xs={7}>
                                        <Typography variant="body1" sx={{ fontSize: "12px" }}>
                                            Tax Amount (+)
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={1} xs={1} sx={{ fontSize: "12px" }}>:</Grid>
                                    <Grid item lg={3} xs={3}>
                                        <Typography variant="body1" sx={{ fontSize: "12px" }}>
                                            {formatCurrency(itemCalculation.total_tax)}
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </Grid>

                            <Grid item lg={12} xs={12}><Divider /></Grid>
                            <Grid item lg={12}>

                                <Grid container spacing={2}>
                                    <Grid item lg={7} xs={6}>
                                        <Typography variant="body1" sx={{ fontSize: "13px", fontWeight: 600, color: '#5e5d5d' }}>
                                            Total Amount
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={1} xs={1} sx={{ fontSize: "12px" }}>:</Grid>
                                    <Grid item lg={3} xs={3}>
                                        <Typography variant="body1" sx={{ fontSize: "13px", fontWeight: 600, color: '#5e5d5d' }}>
                                            {formatCurrency(itemCalculation.total_amount)}
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item lg={12} xs={12}><Divider /></Grid>
                            <Grid item lg={12}>
                                <Chip variant="outlined" sx={{ width: '100%', fontSize: '1rem', backgroundColor: "#b0e6a3", color: "#000000", fontWeight: 500 }} label={`Profit Amount   :    ${formatCurrency(itemCalculation.total_profit)}`} />                                
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" align="center">
                            <Button variant="contained" color="primary" type="submit">Update Product Details</Button>
                        </Typography>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}