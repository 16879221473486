import React, { useEffect } from 'react';
import { Box, Button, Card, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';


export default function VarientProdForm({ control, variations, productDetail, getValues, sellerProductId, errors }) {
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'varientProducts',
    });
    
    const variations_id = (sellerProductId > 0) ? productDetail.product_master.variation_ids : productDetail.variation_ids;
    const variationsData = variations_id.split(',').map(Number);
    const filterVariation = variations.filter((variation) => variationsData.includes(variation.id));
    
    useEffect(() => {
        if (sellerProductId > 0) {
            const varientProducts = productDetail.variantProducts.map((variation) => {
                return {
                    store_product_id: variation.storeProductId,
                    store_id: variation.storeId,
                    product_id: variation.productId,
                    variation1: variation.variation_1_id,
                    variation2: variation.variation_2_id,
                    mrp: variation.mrp,
                    unit_price: variation.unit_price,
                    sale_price: variation.sale_price,
                    stock: variation.stock
                }
            });
            append(varientProducts);
        }
    }, []);

    const addNewVarientProd = () => {
        append({ store_product_id: '', store_id: '', product_id: '',  variation1: '', variation2: '', mrp: '', unit_price: '', sale_price: '', stock: '' });
    }
    const disableField = (sellerProductId > 0) ? true : false;
    return (
        <>
            <Typography
                gutterBottom
                variant='h6'
                component='div'
            >
                Variation Wise Product List
            </Typography>
            <Divider />
            <Box>
                {fields.map((item, index) => {
                    
                    return (
                        <Card key={item.id} sx={{ marginTop: '0.8rem', p: 1 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography
                                    gutterBottom
                                    variant='h6'
                                    component='div'
                                    sx={{ fontSize: '1rem', padding: '6px 2px' }}
                                >
                                    Varient Product {index + 1}
                                </Typography>
                                <IconButton aria-label="delete" color="secondary" onClick={() => remove(index)}>
                                    <DeleteIcon sx={{ color: '#ec2a2a' }} />
                                </IconButton>
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={6}>
                                    <FormControl sx={{ minWidth: "100%" }} size="small">
                                        <InputLabel id="demo-simple-select-autowidth-label">{`SELECT ${filterVariation[0].title}`} </InputLabel>
                                        <Controller
                                            name={`varientProducts[${index}].variation1`}
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: `${filterVariation[0].title} is required` }}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    label={`SELECT ${filterVariation[0].title}`}
                                                    error={!!errors.varientProducts?.[index]?.variation1}
                                                    disabled={(item.store_product_id > 0) ? true : false}
                                                >
                                                    {filterVariation[0].list.map((variation) => (
                                                        <MenuItem key={variation.id} value={variation.id}>{variation.title}</MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                    <Typography variant="inherit" color="red">
                                        {errors.varientProducts?.[index]?.variation1 && errors.varientProducts?.[index]?.variation1.message}
                                    </Typography>
                                </Grid>
                                {filterVariation[1] && (
                                    <Grid item xs={12} lg={6}>
                                        <FormControl sx={{ minWidth: "100%" }} size="small">
                                            <InputLabel id="demo-simple-select-autowidth-label">{`SELECT ${filterVariation[1].title}`} </InputLabel>
                                            <Controller
                                                name={`varientProducts[${index}].variation2`}
                                                control={control}
                                                defaultValue=""
                                                rules={{ required: `${filterVariation[1].title} is required` }}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        label={`SELECT ${filterVariation[1].title}`}
                                                        error={!!errors.varientProducts?.[index]?.variation2}
                                                        disabled={(item.store_product_id > 0) ? true : false}
                                                    >
                                                        {filterVariation[1].list.map((variation) => (
                                                            <MenuItem key={variation.id} value={variation.id}>{variation.title}</MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                        </FormControl>
                                        <Typography variant="inherit" color="red">
                                            {errors.varientProducts?.[index]?.variation2 && errors.varientProducts?.[index]?.variation2.message}
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item xs={6} lg={3}>
                                    <Controller
                                        name={`varientProducts[${index}].mrp`}
                                        control={control}
                                        defaultValue={item.mrp}
                                        rules={{
                                            required: 'MRP is required',
                                            validate: value => value > 0 || 'MRP should be greater than 0'
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                label="MRP"
                                                variant="outlined"
                                                size="small"
                                                disabled={(item.store_product_id > 0) ? true : false}
                                                error={!!errors.varientProducts?.[index]?.mrp}
                                                helperText={errors.varientProducts?.[index]?.mrp && errors.varientProducts[index].mrp.message}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    <Controller
                                        name={`varientProducts[${index}].unit_price`}
                                        control={control}
                                        defaultValue={item.unit_price}
                                        rules={{
                                            required: 'Unit Price is required',
                                            validate: (value) => {
                                                const unit_price = parseFloat(value);
                                                const mrp = getValues(`varientProducts[${index}].mrp`);
                                                if (unit_price < 1) {
                                                    return "Unit Price should be greater than 0"
                                                }

                                                if (unit_price > mrp) {
                                                    return "Unit Price should be less than or equal to MRP"
                                                }

                                            }
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                label="Unit Price"
                                                variant="outlined"
                                                size="small"
                                                error={!!errors.varientProducts?.[index]?.unit_price}
                                                helperText={errors.varientProducts?.[index]?.unit_price && errors.varientProducts[index].unit_price.message}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    <Controller
                                        name={`varientProducts[${index}].sale_price`}
                                        control={control}
                                        defaultValue={item.sale_price}
                                        rules={{
                                            required: 'Sale Price is required',
                                            validate: (value) => {
                                                const sale_price = parseFloat(value);
                                                const unit_price = getValues(`varientProducts[${index}].unit_price`);
                                                const mrp = getValues(`varientProducts[${index}].mrp`);
                                                console.log(mrp, unit_price, value)
                                                if (sale_price < 1) {
                                                    return "Sale Price should be greater than 0"
                                                }
                                                if (sale_price < unit_price) {
                                                    return "Sale Price should be greater than or equal to unit price"
                                                }
                                                if (sale_price > mrp) {
                                                    return "Sale Price should be less than or equal to MRP"
                                                }

                                            }
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                label="Sale Price"
                                                variant="outlined"
                                                size="small"
                                                error={!!errors.varientProducts?.[index]?.sale_price}
                                                helperText={errors.varientProducts?.[index]?.sale_price && errors.varientProducts[index].sale_price.message}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    <Controller
                                        name={`varientProducts[${index}].stock`}
                                        control={control}
                                        defaultValue={item.stock}
                                        rules={{
                                            required: 'Stock is required',
                                            validate: value => value > 0 || 'Stock should be greater than 0'
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                label="Stock"
                                                variant="outlined"
                                                size="small"
                                                error={!!errors.varientProducts?.[index]?.stock}
                                                helperText={errors.varientProducts?.[index]?.stock && errors.varientProducts[index].stock.message}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Card>
                    );
                })}
                <Box sx={{ mt: 2 }}>
                    <Button variant="outlined" onClick={addNewVarientProd}><AddCircleIcon /> Add New Varient Product</Button>
                </Box>
            </Box>
        </>
    )
}