import { commonRequest } from "../../services/ApiCall";
import { Helpers } from "../../services/Helpers";

export const Services = {

    allVariations : async (data) =>{
        return await commonRequest("POST",  `${Helpers.URL()}/variation/`, data);
    },

    allOnlyVariaton : async () =>{
        return await commonRequest("GET",  `${Helpers.URL()}/variation/`);
    },

    addVariation : async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/variation/create`,data, Helpers.token());
    },

    updateVariation : async (id, data) =>{
        return await commonRequest("PUT",  `${Helpers.URL()}/variation/${id}`, data, Helpers.token());
    },

    deleteVariation : async (id) =>{
        return await commonRequest("DELETE",  `${Helpers.URL()}/variation/${id}`, {}, Helpers.token());
    },
    
}