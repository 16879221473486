import React, { useEffect, useState } from 'react';
import {
    Tabs,
    Tab,
    Box,
    Card,
    Stack,
    Typography,
    TextField,
    Button,
    Modal,
    Grid,
    Divider,
    Paper,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Tooltip,
    IconButton,
} from '@mui/material';
import { CategoryService } from './CategoryService';
import AddCategory from './AddCategory';
import { isMobile } from 'react-device-detect';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import Variations from './Variations'
import { useForm, Controller } from 'react-hook-form';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';
import { FileUpload } from '@mui/icons-material';
import UploadImage from './UploadImage';
import { GenerateUniqueVal } from '../../utils/generateUniqValue';

const style = {
    position: 'absolute',
    top: (isMobile) ? '80%' : '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #ddd',
    boxShadow: 24,
    p: 2,
};


const CategoryTabs = ({ }) => {
    const { control, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const [selectedTab, setSelectedTab] = useState(0);
    const [categories, setCategories] = useState([]);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };
    const [loading, setLoading] = useState(false);
    const [controller, setController] = useState({
        searchKey: "",
        status: "",
    });
    const [deleteCat, setDeleteCat] = useState(0);

    /************************  Modal State Manage **************************** */
    const [open, setOpen] = useState(false);
    const [ids, setIds] = useState({});
    const [catType, setCatType] = useState(1);
    const [populateTable, setPopulateTable] = useState(0);
    /************************ VAriation Modal State Manage **************************** */
    const [openVariation, setOpenVaration] = useState(false);
    const [catData, setCatData] = useState([])

    const getData = async () => {
        try {
            setLoading(true);
            const response = await CategoryService.allCategories({
                searchKey: controller.searchKey,
                status: controller.status
            });
            console.log("category response:",response)
            setCategories(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getData();
    }, [controller, deleteCat, populateTable])

    /************************  Modal Manage **************************** */
    const modalOpen = (id, catType) => {
        setOpen(true);
        setIds(id);
        setCatType(catType)
    }
    const modalVariationOpen = (catData) => {
        setOpenVaration(true);
        setCatData(catData);
    }

    const handleClose = () => {
        setOpen(false);
        setIds(0);
        setOpenVaration(false);
        setCatData([]);
    };
    const afterAddPop = (data) => {
        setPopulateTable(data);
    }

    /************************  Remove Category **************************** */
    const removeTableRow = async (id) => {
        if (!id) return;
        const delP = await CategoryService.deleteCategory(id);
        if (delP.success) {
            setDeleteCat(id);
            Swal.fire('Deleted!', '', 'success')
        } else {
            Swal.fire(delP.message, '', 'error')
        }
    }
    const deleteRow = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Yes, delete it!',
            denyButtonText: `No, cancel!`,
        }).then((result) => {
            if (result.isConfirmed) {
                removeTableRow(id);
            }
        })
    }

    const updateStatus = async (id, status) => {
        if (!id) return;
        // const statusRes = await Brand.changeStatus({ id: id, status: status });
        // if (statusRes.success) {
        //     setDeleteCat(id);
        //     Swal.fire('Status Change Successfully', '', 'success')
        // } else {
        //     Swal.fire(statusRes.message, '', 'error')
        // }
    }
    const changeStatus = (id, status) => {
        Swal.fire({
            title: 'Are you sure to change status?',
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Yes, Change Status!',
            denyButtonText: `No, cancel!`,
        }).then((result) => {
            if (result.isConfirmed) {
                const newStatus = (status == 0) ? 1 : 0;
                updateStatus(id, newStatus);
            }
        })
    }    
    
    function onSubmit(data) {
        const filtersData = getValues();
        setController({
            ...controller,
            searchKey: filtersData.searchKey,
            status: filtersData.status
        });
    }

    const clearFilters = () => {
        reset();
        setController({
            ...controller,
            searchKey: "",
            status: ""
        });
    }
    /*================== image upload realted ====================== */
    const [trigStatus, setTrigStatus] = useState(null);
    const [imgModalOpen, setImgModalOpen] = useState(false);
    const [imgType, setImgType] = useState("");

    const openImgModal = (imgType) => {
        setImgType(imgType);
        setImgModalOpen(true);
    };

    const closeModal = () => {
        setImgModalOpen(false);
    };

    const handlePImgTrig = (data) => {
        setTrigStatus(data);
    }
    const imgData = {
        path: "admin_profile_image",
        // image_name: `img-${GenerateUniqueVal(1000000)}-${personalDetails.id}.jpeg`,
        img_type: "single"
    };
    const updateUserProfileImage = async (data, imgType) => {
        let pData;
        if (imgType == "profile") {
          pData = { profile_image: data.Location };
        } else {
          pData = { banner_image: data.Location };
        }
        // const updateUser = await User.updateUser(userIdProp, pData);
        // if (updateUser.success) {
        //   if (imgType == "profile") {
        //     setProfileImg(data.Location);
        //   } else {
        //     setBannerImg(data.Location)
        //   }
        //   closeModal();
        //   Swal.fire("Image Uploaded", '', 'success')
        // }
      }
      const imgUploadResponse = (data) => {
        // if (imgType == "profile") {
        //   updateUserProfileImage(data, "profile");
        // }
        // if (imgType == "banner") {
        //   updateUserProfileImage(data, "banner");
        // }
      }
    return (
        <Box>
            <Box>
                {/* ============== image upload dialog ============= */}
                <UploadImage open={imgModalOpen} onClose={closeModal} imgData={imgData} imgUploadResponse={imgUploadResponse} trigStatus={handlePImgTrig} />
                {/*================= variation modal ===================*/}
                <Modal
                    open={openVariation}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{ overflow: "scroll" }}
                >
                    <Box sx={style} width={(isMobile) ? "95%" : "45%"}>
                        <Variations
                            // populateList={afterAddPop} 
                            closeEvent={handleClose}
                            catDetail={{ catData }}
                        />
                    </Box>
                </Modal>
                {/*================= Add Category modal ================*/}
                <Modal
                    open={open}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{ overflow: "scroll" }}
                >
                    <Box sx={style} width={(isMobile) ? "95%" : "25%"}>
                        <AddCategory populateList={afterAddPop} closeEvent={handleClose} catType={catType} rowId={ids} />
                    </Box>
                </Modal>
            </Box>
            {/* ======================= Page  title and menu section ================ */}
            <Card sx={{ minWidth: "100%", marginBottom: "10px" }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={6}>
                        <Typography
                            gutterBottom
                            variant='h5'
                            component='div'
                            sx={{ padding: '20px' }}
                        >
                            Manage Categories
                        </Typography>
                    </Grid>


                    <Grid item xs={12} lg={6}>
                        <Grid container spacing={1} sx={{ padding: 2 }}>
                            <Grid item xs={6} lg={3}>
                                <Button variant='contained' size='small' onClick={() => modalOpen({}, 1)} sx={{ marginTop: "10px", marginRight: "10px" }} endIcon={<AddCircleIcon />}>
                                    Category
                                </Button>
                            </Grid>
                            <Grid item xs={6} lg={4} sx={{textAlign:'center',}}>
                                <Button variant='contained' size='small' onClick={() => modalOpen({}, 2)} sx={{ marginTop: "10px", marginRight: "10px" }} endIcon={<AddCircleIcon />}>
                                    Sub Category
                                </Button>
                            </Grid>
                            <Grid item xs={12} lg={5}>
                                <Button variant='contained' size='small' 
                                    // onClick={() => modalOpen({}, 3)} 
                                    sx={{ marginTop: "10px", marginRight: "10px" }} endIcon={<AddCircleIcon />}
                                >
                                    Sub Sub Category
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Card>
            {/* ======================= Search  and Filter section ================ */}
            <Card sx={{ minWidth: "100%", marginBottom: "10px" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} sx={{ p: 2 }}>
                        <Grid item xs={12} lg={2}>
                            <Controller
                                name="searchKey"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        placeholder="Search here....."
                                        label="Search"
                                        size="small"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} lg={2}>
                            <FormControl sx={{ minWidth: "100%" }} size="small">
                                <InputLabel id="demo-simple-select-autowidth-label">Select Status</InputLabel>
                                <Controller
                                    name="status"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            label="Select Status"
                                        >
                                            <MenuItem value="2">All</MenuItem>
                                            <MenuItem value="1">Active</MenuItem>
                                            <MenuItem value="0">Inactive</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={2}>
                        <Tooltip title="Search Button">
                                <IconButton
                                    color="primary"
                                    type="submit"
                                    sx={{ border: "1px solid", borderRadius: "7px", padding: "6px 6px" }}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Clear All Filters">
                                <IconButton
                                    color="primary"
                                    sx={{ marginLeft: "10px", border: "1px solid", color: "#f00", borderRadius: "7px", padding: "6px 6px" }}
                                    onClick={clearFilters}
                                >
                                    <RestartAltIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </form>
            </Card>
            {/* ========================= Content Section ======================== */}
            <Box sx={{ marginTop: 2 }}>
                <Grid container spacing={1}>
                    {/* ========= category tab section ======== */}
                    <Grid item xs={5} lg={2}>
                        <Tabs
                            value={selectedTab}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            orientation="vertical" // Set the orientation to 'vertical'
                            variant="scrollable" // Use 'scrollable' variant for longer lists
                            scrollButtons="auto"
                            sx={{ backgroundColor: "#fff" }}
                            align="left"
                        >
                            {categories.map((category, index) => (
                                <Tab key={index} label={category.title} />
                            ))}
                        </Tabs>
                    </Grid>
                    <Grid item xs={7} lg={10}>
                        <Box sx={{ padding: 2, backgroundColor: "#f9f9f9" }}>
                            <Box>
                                <Stack spacing={1} direction="row">
                                    <Button
                                        //variant='contained' 
                                        onClick={(e) => modalOpen(categories[selectedTab], 1)}
                                        sx={{ height: "30px", marginTop: "10px", marginRight: "10px" }}
                                        startIcon={<EditIcon />}
                                        variant="outlined"
                                        color="primary"
                                    >
                                        {isMobile ? "" : "Edit Category"}
                                    </Button>
                                    {/* <Button
                                        //variant='contained' 
                                        onClick={() => deleteRow(categories[selectedTab].id)}
                                        sx={{ height: "30px", marginTop: "10px", marginRight: "10px" }}
                                        startIcon={<DeleteIcon />}
                                        variant="outlined"
                                        color="error"
                                    >
                                        {isMobile ? "" : "Delete Category"}
                                    </Button> */}
                                    {/* <Button
                                        //variant='contained' 
                                        onClick={(e) => modalVariationOpen({ id: categories[selectedTab].id, title: categories[selectedTab].title })}
                                        sx={{ height: "30px", marginTop: "10px", marginRight: "10px" }}
                                        startIcon={<AddToPhotosIcon />}
                                        variant="outlined"
                                        color="primary"
                                    >
                                        {isMobile ? "" : "Variation"}
                                    </Button> */}
                                    <Button
                                        //variant='contained' 
                                        // onClick={(e) => modalOpen(categories[selectedTab], 1)}
                                        onClick={(e) => openImgModal('profile')}
                                        sx={{ height: "30px", marginTop: "10px", marginRight: "10px" }}
                                        startIcon={<AddToPhotosIcon />}
                                        variant="outlined"
                                        color="primary"
                                    >
                                        {isMobile ? "" : "Image Upload"}
                                    </Button>
                                </Stack>
                            </Box>

                            <Divider sx={{ marginTop: 2, marginBottom: 2 }} />

                            <Grid container spacing={2}>
                                {categories[0] && categories[selectedTab].childCategories.map((subcategory, index) => (

                                    <Grid item xs={12} lg={4} key={index}>
                                        <Paper elevation={1} style={{ padding: 7 }}>
                                            <Grid container spacing={2} >
                                                <Grid item xs={9}>
                                                    <Typography variant="h6" sx={{ fontSize: "15px", fontWeight: 600 }} gutterBottom>
                                                        {subcategory.title}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Stack spacing={2} direction="row" justifyContent="flex-end">

                                                        <EditIcon
                                                            style={{
                                                                fontSize: "20px",
                                                                color: "blue",
                                                                cursor: "pointer",
                                                            }}
                                                            className="cursor-pointer"
                                                            onClick={(e) => modalOpen(subcategory, 2)}
                                                        />

                                                        <DeleteIcon
                                                            style={{
                                                                fontSize: "20px",
                                                                color: "red",
                                                                cursor: "pointer",
                                                            }}
                                                            className="cursor-pointer"
                                                            // onClick={(e) => deleteRow(subcategory.id)}
                                                        />
                                                    </Stack>
                                                </Grid>
                                            </Grid>

                                            <Divider sx={{ marginTop: 1, marginBottom: 1 }} />

                                            {/* {subcategory.subsubcategories.map((subSubcategory, subIndex) => (
                                                <Grid container spacing={2}>
                                                    <Grid item xs={9}>
                                                        <Typography variant="h6" sx={{ fontSize: "14px", fontWeight: 500 }} gutterBottom>
                                                            {subSubcategory.title}
                                                        </Typography>

                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Stack spacing={2} direction="row" justifyContent="flex-end">

                                                            <EditIcon
                                                                style={{
                                                                    fontSize: "20px",
                                                                    color: "blue",
                                                                    cursor: "pointer",
                                                                }}
                                                                className="cursor-pointer"
                                                                onClick={(e) => modalOpen(subSubcategory, 3)}
                                                            />
                                                            <DeleteIcon
                                                                justifyContent="flex-end"
                                                                style={{
                                                                    fontSize: "20px",
                                                                    color: "red",
                                                                    cursor: "pointer",
                                                                }}
                                                                className="cursor-pointer"
                                                                onClick={(e) => deleteRow(subSubcategory.id)}
                                                            />
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            ))} */}
                                        </Paper>

                                    </Grid>
                                ))}

                            </Grid>
                        </Box>
                    </Grid>
                </Grid>



            </Box>
        </Box>
    );
};

export default CategoryTabs;
