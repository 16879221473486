import { useState, useEffect, useCallback } from 'react';
import { Card, Typography, Divider, Stack, TextField, Grid, Button, Box, Toolbar, MenuItem, FormControl, InputLabel, Select, TableCell, Tooltip, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import Modal from '@mui/material/Modal';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { isMobile } from 'react-device-detect';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';

import AddBrand from './AddBrand';
import { Services } from './SellerService';
import { LocalStore } from '../../storage/authStore';
import { formatDateTime } from '../../utils/dateUtils';
import { useForm, Controller } from 'react-hook-form';
import CustomNoRowsOverlay from '../../components/NoRows';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { formatCurrency } from '../../utils/currencyFormatter';

const style = {
    position: 'absolute',
    top: (isMobile) ? '80%' : '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: (isMobile) ? "95%" : "25%",
    bgcolor: 'background.paper',
    border: '2px solid #ddd',
    boxShadow: 24,
    p: 2,
};


export default function ProductList() {
    const { control, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const userDetail = LocalStore.getCurrentUserDetails();
    const [sellerList, setSellerList] = useState([]);
    const [selectedStore, setSelectedStore] = useState("");

    /************************  Table Data State Manage **************************** */
    const [list, setList] = useState([]); // Filtered data    const [allRowCount, setAllRowCount] = useState(0);
    const [deleteProd, setDeleteRow] = useState(0);
    const [allRowCount, setAllRowCount] = useState(0);
    const [controller, setController] = useState({
        page: 0,
        pageSize: 5,
        store_id: userDetail.id,
        filters: {
            searchKey: "",
            status: "1",
        }
    });
    const [loading, setLoading] = useState(false);
    const [checkFirst, setCheckFirst] = useState(0)

    /************************  Manage Use Effect **************************** */
    useEffect(() => {
        const sellerdata = async () => {
            try {
                setLoading(true)
                const listResponse = await Services.getSellerList();
                setSellerList(listResponse.data);
                setLoading(false);
            } catch (error) {
                Swal.fire(error.message, '', 'error')
            }
        }
        if (!checkFirst) {
            sellerdata();
            setCheckFirst(1)
        }
        const getData = async () => {
            try {
                setLoading(true);
                const listResponse = await Services.allStoreProducts({
                    page: controller.page,
                    limit: controller.pageSize,
                    store_id: controller.store_id,
                    filters: {
                        searchKey: controller.filters.searchKey,   // Use the title filter from the controller
                        status: controller.filters.status, // Use the status filter from the controller
                    }
                });
                setValue('status', controller.filters.status)
                setLoading(false);
                if (listResponse.success) {
                    const data = listResponse.data.list;
                    setList(listResponse.data.list);
                    setAllRowCount(listResponse.data.totalRows);
                } else {
                    Swal.fire('Request failed', '', 'error')
                }
            } catch (error) {
                Swal.fire(error.message, '', 'error')
            }
        };
        if (controller.store_id > 0) {
            getData();
        }

    }, [controller, deleteProd]);

    /************************  Table Column Manage **************************** */
    const columns = [
        { field: "id", headerName: "SP Id", width: 70, editable: false },
        {
            field: "image", headerName: "Image", width: 80, editable: false, renderCell: (params) => (
                <TableCell>
                    {params.row.product_master.product_master_images.length > 0 ? <img
                        src={`${params.row.product_master.product_master_images[0].image_url}sm/${params.row.product_master.product_master_images[0].image_title}`}
                        alt={params.row.product_master.product_master_images[0].image_title}
                        style={{ maxWidth: '50px', maxHeight: '50px' }}
                    /> : "No Image"}

                </TableCell>
            ),
        },
        {
            field: "product_name", headerName: "Product Variation", width: 250, editable: false, renderCell: (params) => {
                return <>
                    <Typography variant="caption" component="p" sx={{ whiteSpace: 'pre-wrap' }}>
                        {params.row.product_master.title}
                    </Typography>
                </>
            }
        },
        {
            field: "product_variations", headerName: "Variation Details", width: 200, editable: false, renderCell: (params) => {
                return (
                    <>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingTop: "10px", width: '200px' }}>
                            {params.row.variation_1_details && (
                                <Typography variant="caption" component="p" >
                                    <span style={{ marginRight: '8px' }}>{params.row.variation_1_details.variation_name}: </span> <strong>{params.row.variation_1_details.variation_value}</strong>
                                </Typography>
                            )}
                            {params.row.variation_2_details && (
                                <Typography variant="caption" component="p" >
                                    <span style={{ marginRight: '8px' }}>{params.row.variation_2_details.variation_name}: </span> <strong>{params.row.variation_2_details.variation_value}</strong>
                                </Typography>
                            )}
                        </div>
                    </>
                )
            }
        },
        {
            field: "prices", headerName: "Price Details", width: 130, editable: false, renderCell: (params) => {
                return (
                    <>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingTop: "10px" }}>
                            <Typography variant="caption" component="p" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <span style={{ fontSize: '12px', marginRight: '8px' }}>MRP:</span> <strong>{formatCurrency(params.row.mrp)}</strong>
                            </Typography>
                            <Typography variant="caption" component="p" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <span style={{ fontSize: '12px', marginRight: '8px'}}>Vendor:</span> <strong>{formatCurrency(params.row.unit_price)}</strong>
                            </Typography>
                            <Typography variant="caption" component="p" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <span style={{ fontSize: '12px', marginRight: '8px' }}>Sell:</span> <strong>{formatCurrency(params.row.sale_price)}</strong>
                            </Typography>
                        </div>
                    </>
                )
            }
        },
        { field: "stock", headerName: "stock", width: 80 },
        {
            field: "status", headerName: "Status", width: 100, editable: false, renderCell: (params) => {
                return (
                    <>
                        {params.row.status ? (
                            <Button
                                variant="contained"
                                size='small'
                                sx={{ fontSize: "11px" }}
                                color="success"
                                onClick={(e) => changeStatus(params.row.id, params.row.status)}
                            >
                                Active
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                size='small'
                                sx={{ fontSize: "11px" }}
                                color="error"
                                onClick={(e) => changeStatus(params.row.id, params.row.status)}
                            >
                                InActive
                            </Button>
                        )}
                    </>
                )
            }
        },
        {
            field: "createdAt", headerName: "Created Date", flex: 1, valueGetter: (params) => {
                return formatDateTime(params.row.createdAt, "datetime")
            }
        }, {
            field: "actions", headerName: "Action", width: 100, renderCell: (params) => {
                return (
                    <Stack spacing={2} direction="row">
                        <Button
                            sx={{ height: "30px", marginTop: "10px", marginRight: "10px" }}
                            startIcon={<EditIcon />}
                            variant="outlined"
                            color="primary"
                            size='small'
                            onClick={(e) => window.open(`addSellerProduct/${(userDetail.role == 2) ? userDetail.id : selectedStore}/${params.row.id}/${params.row.product_id}`, '_blank')}
                        >
                            {isMobile ? "" : " Edit"}
                        </Button>
                        {/* <DeleteIcon
                            style={{
                                fontSize: "20px",
                                color: "red",
                                cursor: "pointer",
                            }}
                            className="cursor-pointer"
                            onClick={(e) => deleteRow(params.row.id)}
                        /> */}
                    </Stack>
                );
            }
        },
    ];

    /************************  Remove table row **************************** */
    const removeTableRow = async (id) => {
        if (!id) return;
        const delP = await Services.disableProduct(id);
        if (delP.success) {
            setDeleteRow(id);
            Swal.fire('Deleted!', '', 'success')
        } else {
            Swal.fire(delP.message, '', 'error')
        }
    }

    const deleteRow = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Yes, delete it!',
            denyButtonText: `No, cancel!`,
        }).then((result) => {
            if (result.isConfirmed) {
                removeTableRow(id);
            }
        })
    }
    /************************ End Remove table row **************************** */

    const handleChangeStore = (e) => {
        setSelectedStore(e.target.value);
        setController({
            ...controller,
            store_id: e.target.value,
        });
    }

    const handleAddProduct = () => {
        if (userDetail.role == 2) {
            window.open(`addSellerProduct/${userDetail.id}/0/0`, '_blank')
        } else {
            if (selectedStore > 0) {
                window.open(`addSellerProduct/${selectedStore}/0/0`, '_blank')
            } else {
                Swal.fire("Without store selection you can not add any products", '', 'error')
            }
        }
    }
    const updateStatus = async (id, status) => {
        if (!id) return;
        const statusRes = await Services.changeStatus({ id: id, status: status });
        if (statusRes.success) {
            setDeleteRow(id);
            Swal.fire('Status Change Successfully', '', 'success')
        } else {
            Swal.fire(statusRes.message, '', 'error')
        }
    }
    const changeStatus = (id, status) => {
        Swal.fire({
            title: 'Are you sure to change status?',
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Yes, Change Status!',
            denyButtonText: `No, cancel!`,
        }).then((result) => {
            if (result.isConfirmed) {
                const newStatus = (status == 0) ? 1 : 0;
                updateStatus(id, newStatus);
            }
        })
    }

    const handlePaginationChange = (newPagination) => {
        setController({
            ...controller,
            page: newPagination.page,
            pageSize: newPagination.pageSize
        });
    }

    function onSubmit(data) {
        const filtersData = getValues();
        setController({
            ...controller,
            filters: {
                ...controller.filters,
                searchKey: filtersData.searchKey,
                status: filtersData.status
            }
        });
    }
    const clearFilters = () => {
        reset();
        setController({
            ...controller,
            filters: {
                ...controller.filters,
                searchKey: "",
                status: ""
            }
        });
    }
    return (
        <>
            <Card sx={{ minWidth: "100%", marginBottom: "10px" }}>
                <Stack direction="row" spacing={2} className="">
                    <Typography
                        gutterBottom
                        variant='h5'
                        component='div'
                        sx={{ padding: '20px', width: (isMobile) ? "200px" : "100%" }}
                    >
                        Product List
                    </Typography>
                </Stack>
            </Card>

            <Card sx={{ minWidth: "100%", marginBottom: "10px" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} sx={{ p: 2 }}>
                        <Grid item xs={12} lg={2}>
                            <Controller
                                name="searchKey"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        placeholder="Search here....."
                                        label="Search"
                                        size="small"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} lg={2}>
                            <FormControl sx={{ minWidth: "100%" }} size="small">
                                <InputLabel id="demo-simple-select-autowidth-label">Select Status</InputLabel>
                                <Controller
                                    name="status"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            label="Select Status"
                                        >
                                            <MenuItem value="2">All</MenuItem>
                                            <MenuItem value="1">Active</MenuItem>
                                            <MenuItem value="0">Inactive</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={2}>
                            <Tooltip title="Search Button">
                                <IconButton
                                    color="primary"
                                    type="submit"
                                    sx={{ border: "1px solid", borderRadius: "7px", padding: "6px 6px" }}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Clear All Filters">
                                <IconButton
                                    color="primary"
                                    sx={{ marginLeft: "10px", border: "1px solid", color: "#f00", borderRadius: "7px", padding: "6px 6px" }}
                                    onClick={clearFilters}
                                >
                                    <RestartAltIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </form>
            </Card>

            <Box sx={{ overflow: "auto", backgroundColor: "#fff" }}>
                <Box sx={{ width: "100%" }}>
                    <Stack direction="row" spacing={2}>
                        <Box>
                            {(userDetail.role != 2) && (
                                <Typography sx={{ marginTop: "15px", marginLeft: "10px" }}>
                                    <FormControl sx={{ width: "200px" }}>
                                        <InputLabel id="demo-simple-select-label" sx={{ lineHeight: "14px" }}>Select Store</InputLabel>
                                        <Controller
                                            name="selectStore"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    value={selectedStore}
                                                    size='small'
                                                    onChange={handleChangeStore}
                                                    label="Select Status"
                                                >

                                                    {sellerList && sellerList.map((seller) => (
                                                        <MenuItem value={seller.id}>{seller.first_name} {seller.last_name}</MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                </Typography>
                            )}
                        </Box>
                        <Box sx={{ flex: 1 }}></Box>
                        <Box>
                            <Typography
                                gutterBottom
                                variant='h5'
                                component='div'
                                sx={{ padding: '10px' }}
                            >
                                <Button title='Add New Seller Product' variant='contained' onClick={handleAddProduct} sx={{ marginTop: "10px" }} endIcon={<AddCircleIcon />}>
                                    {isMobile ? "" : "Add Product"}
                                </Button>
                            </Typography>
                        </Box>
                    </Stack>
                </Box>
                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                    <DataGrid
                        rows={list}
                        columns={columns}
                        getRowId={(row) => row.id}
                        //checkboxSelection={true}
                        rowCount={allRowCount}  // Use the length of the filtered list
                        pageSizeOptions={[5, 10, 20, 50, 100]}
                        paginationModel={controller}
                        paginationMode="server"
                        onPaginationModelChange={handlePaginationChange}
                        components={{ Toolbar: GridToolbar }}
                        loading={loading}
                        rowHeight={80}
                        disableColumnMenu
                        slots={{
                            noRowsOverlay: () => <CustomNoRowsOverlay />,
                        }}
                    />
                </Box>
            </Box>
        </>
    )
}