import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { isMobile } from 'react-device-detect';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { uploadImageS3Bucket } from '../../utils/s3bucketImg';
import { useForm, Controller } from 'react-hook-form';

// import { Services } from './Services';
import Swal from 'sweetalert2';
const directories =['category', 'subcategory'];
const UploadImage=({ open, onClose, imgData, imgUploadResponse, trigStatus })=> {
    const { control, handleSubmit, setValue, formState: { errors,isDirty } } = useForm({
        mode: "onChange"
    });
    const [selectedImage, setSelectedImage] = useState(null);
    const [image, setImage] = useState("");
    const [imgErr, setImgErr] = useState("");

    useEffect(() => {
        setImage("");

    }, [open])
    console.log(imgData)
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setImage(URL.createObjectURL(file))
        setSelectedImage(file);
    };

    const handleUpload = async () => {
        if (selectedImage == "" || selectedImage == null) {
            setImgErr("Please choose payment image.")
            return false;
        }
        setImgErr("");
        let imageOption = {
            file: selectedImage,
            imgWidth: "600",
            imgHeight: "600",
            imgType: "JPEG",
            quality: "70",
        }
        
        if (imgData.img_type == 'single') {
            imageOption.path = imgData.path;
            imageOption.fileName = imgData.image_name;
        } else {

        }


        const imageRes = await uploadImageS3Bucket(imageOption);
        if (imageRes) {
            const updateImageData = {
                client_payment_image: imageRes.Location,
            }
            imgUploadResponse(imageRes)            
        }
    };

    return (
        <div>
            <Dialog
                onClose={onClose}
                open={open}
                // maxWidth="lg"
                fullWidth
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" >
                    Image Upload
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers sx={{ }}>
                    <Grid container spacing={2}>
                        <Grid item lg={12}>
                            <Controller
                                control={control}
                                name='folder'
                                // name='image'
                                rules={{

                                }}
                                render={({field,fieldState:{error}})=>(
                                    <FormControl size='small' sx={{ minWidth: "100%" }}>
                                        <InputLabel id="select_subject">Select Subject</InputLabel>
                                        <Select
                                            {...field}
                                            id='select_subject'
                                            label='select subject'
                                            error={!!error}
                                        >
                                          {
                                            directories.map((directory,dirIndex)=>(
                                              <MenuItem value={directory} sx={{textTransform:'uppercase'}} key={dirIndex}>{directory}</MenuItem>
                                            ))
                                          }
                                        </Select>
                                    </FormControl>
                                )}  
                            />
                        </Grid>
                        <Grid item lg={12}>
                            <Controller
                                control={control}
                                name='folder'
                                // name='image'
                                rules={{

                                }}
                                render={({field,fieldState:{error}})=>(
                                    <FormControl size='small' sx={{ minWidth: "100%" }}>
                                        <InputLabel id="select_subject">Select Category</InputLabel>
                                        <Select
                                            {...field}
                                            id='select_subject'
                                            label='select subject'
                                            error={!!error}
                                        >
                                          {
                                            directories.map((directory,dirIndex)=>(
                                              <MenuItem value={directory} sx={{textTransform:'uppercase'}} key={dirIndex}>{directory}</MenuItem>
                                            ))
                                          }
                                        </Select>
                                    </FormControl>
                                )}  
                            />
                        </Grid>
                        <Grid item lg={12}>
                            <Controller
                                control={control}
                                name='folder'
                                // name='image'
                                rules={{

                                }}
                                render={({field,fieldState:{error}})=>(
                                    <FormControl size='small' fullWidth>
                                        <InputLabel id="select_subject">Select Sub Category</InputLabel>
                                        <Select
                                            {...field}
                                            id='select_subject'
                                            label='select subject'
                                            error={!!error}
                                        >
                                          {
                                            directories.map((directory,dirIndex)=>(
                                              <MenuItem value={directory} sx={{textTransform:'uppercase'}} key={dirIndex}>{directory}</MenuItem>
                                            ))
                                          }
                                        </Select>
                                    </FormControl>
                                )}  
                            />
                        </Grid>
                        <Grid item lg={12}>
                            <label htmlFor="upload-image">
                                <Button sx={{ width: "100%" }} variant="outlined" component="span">
                                    Choose Product Images
                                </Button>

                                <input
                                    id="upload-image"
                                    hidden
                                    accept="image/*"
                                    type="file"
                                    onChange={handleImageChange}
                                />
                            </label>
                        </Grid>
                        {imgErr && (
                            <Grid item lg={12}>
                                <Typography variant='body' color="red">
                                    {imgErr}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item lg={12} >
                            {image && (
                                <Box
                                    component="img"
                                    sx={{
                                        width: 100,
                                        marginTop: "20px",
                                        marginLeft: "5px",
                                        borderRadius: "10px"
                                        // maxHeight: { xs: 233, md: 167 },
                                        // maxWidth: { xs: 350, md: 250 },
                                    }}
                                    alt="Payment Image"
                                    src={image}
                                />
                            )}
                        </Grid>
                    </Grid>
                    {/* <input type="file" accept="image/*" onChange={handleImageChange} /> */}


                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleUpload}>
                        Upload Image
                    </Button>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};



export default UploadImage