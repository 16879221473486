import { useState, useEffect } from 'react';
import { Typography, Stack, Button, Box, TableCell, Chip, Grid, Divider, TableRow, TableContainer, Table, TableHead, TableBody, Paper } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import Modal from '@mui/material/Modal';
import { isMobile } from 'react-device-detect';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { LocalStore } from '../../storage/authStore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useForm, Controller } from 'react-hook-form';
import AddEditOrderItem from './AddEditOrderItem';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PendingIcon from '@mui/icons-material/Pending';
import { formatCurrency } from '../../utils/currencyFormatter';

const style = {
    position: 'absolute',
    top: (isMobile) ? '80%' : '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: (isMobile) ? "95%" : "55%",
    bgcolor: 'background.paper',
    border: '2px solid #ddd',
    boxShadow: 24,
    p: 2,
};

export default function OrderItems({ itemList, triggerMain, currentOrdStatus, itemsTotal }) {
    const { control, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const userDetail = LocalStore.getCurrentUserDetails();
    const role = (userDetail) ? userDetail.role : 0;
    const [mainTrigger, setMainTrigger] = useState(0);

    /************************  Table Data State Manage **************************** */
    const [list, setList] = useState([]); // Filtered data    const [allRowCount, setAllRowCount] = useState(0);
    const [allRowCount, setAllRowCount] = useState(0);

    const [loading, setLoading] = useState(false);

    /************************  Modal State Manage **************************** */
    const [open, setOpen] = useState(false);
    const [rowItem, setRowItem] = useState({});

    /************************  Manage Use Effect **************************** */
    useEffect(() => {

        setList(itemList)
    }, [itemList, mainTrigger]);

    /************************  Modal Manage **************************** */
    const modalOpen = (data) => {
        setOpen(true);
        setRowItem(data);
    }

    const handleClose = () => {
        setOpen(false);
        setRowItem(0);
    };

    /************************  Table Column Manage **************************** */
    const itemTriggerHandle = (data) => {
        setMainTrigger(data);
        triggerMain(data);
    }

    function onSubmit(data) {

    }

    return (
        <>
            <div>
                <Modal
                    open={open}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{ overflow: "scroll" }}
                >
                    <Box sx={style}>
                        <AddEditOrderItem itemTrigger={itemTriggerHandle} closeEvent={handleClose} rowData={rowItem} />
                    </Box>
                </Modal>
            </div>
            <Box sx={{ overflow: "auto", backgroundColor: "#fff" }}>
                <Box sx={{ minWidth: "100%", marginBottom: "10px" }}>
                    <Stack direction="row" spacing={1} className="">
                        <Typography
                            gutterBottom
                            variant='h6'
                            component='div'
                            sx={{ padding: '10px' }}
                        >
                            Order Product List
                        </Typography>
                        <Typography
                            variant='h3'
                            component='div'
                            sx={{ flexGrow: 1 }}
                        ></Typography>
                    </Stack>
                </Box>
                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" sx={{ width: '200px', p: 0 }}>Product Details</TableCell>
                                    <TableCell align="center" sx={{ width: '100px', p: 0 }}>S.Price</TableCell>
                                    <TableCell align="center" sx={{ width: '70px', p: 0 }}>QTY</TableCell>
                                    <TableCell align="center" sx={{ width: '70px', p: 0 }}>Disc(%)</TableCell>
                                    <TableCell align="center" sx={{ width: '120px', p: 0 }}>SubTotal</TableCell>
                                    <TableCell align="center" sx={{ width: '80px', p: 0 }}>GST</TableCell>
                                    <TableCell align="center" sx={{ width: '80px', p: 0 }}>Tax Amt</TableCell>
                                    <TableCell align="center" sx={{ width: '120px', p: 0 }}>Total Amt</TableCell>
                                    <TableCell align="center" sx={{ width: '100px', p: 0 }}>Status</TableCell>
                                    <TableCell align="center" sx={{ width: '70px', p: 0 }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {list.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >

                                        <TableCell align="center" sx={{ padding: "0px 10px" }}>
                                            <Stack direction="row" spacing={1}>
                                                <Box>
                                                    {(row.store_product.product_master.product_master_images[0]) ? (
                                                        <img
                                                            src={`${row.store_product.product_master.product_master_images[0].image_url}sm/${row.store_product.product_master.product_master_images[0].image_title}`}
                                                            alt={row.store_product.product_master.product_master_images[0].image_title}
                                                            style={{ maxWidth: '30px', maxHeight: '30px', cursor: 'pointer' }}
                                                            onClick={() => window.open(`../../admin/addSellerProduct/${row.store_id}/${row.store_productId}`, '_blank')}
                                                        />
                                                    ) : (
                                                        <ImageNotSupportedIcon />
                                                    )}
                                                </Box>
                                                <Box sx={{ width: "100%" }}>
                                                    <Typography
                                                        variant='subtitle2'
                                                        sx={{ fontSize: '0.75rem', cursor: 'pointer', maxHeight: '29px', overflow: 'hidden', lineHeight: '14px' }}
                                                        onClick={() => window.open(`../../admin/addSellerProduct/${row.store_id}/${row.store_productId}`, '_blank')}
                                                    >
                                                        {row.store_product.product_master.title}
                                                    </Typography>
                                                    <Typography
                                                        variant='subtitle2'
                                                        sx={{ fontSize: '0.75rem', cursor: 'pointer' }}
                                                        onClick={() => window.open(`../../admin/userProfile/${row.store_id}`, '_blank')}
                                                    >
                                                        SL: <strong>{row.user.first_name}</strong>
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                        </TableCell>
                                        <TableCell align="center" sx={{ p: 0 }}>{row.sale_price}</TableCell>
                                        <TableCell align="center" sx={{ p: 0 }}>{row.quantity}</TableCell>
                                        <TableCell align="center" sx={{ p: 0 }}>{row.discount}</TableCell>
                                        <TableCell align="center" sx={{ p: 0 }}>{row.subtotal}</TableCell>
                                        <TableCell align="center" sx={{ p: 0 }}>
                                            <Stack direction="column" spacing={0}>
                                                <Typography variant='subtitle2' sx={{ fontSize: '0.6rem' }}>
                                                    CGST: <strong>{row.store_product.product_master.cgst}</strong>
                                                </Typography>
                                                <Typography variant='subtitle2' sx={{ fontSize: '0.6rem' }}>
                                                    SGST: <strong>{row.store_product.product_master.sgst}</strong>
                                                </Typography>
                                                <Typography variant='subtitle2' sx={{ fontSize: '0.6rem' }}>
                                                    IGST: <strong>{row.store_product.product_master.igst}</strong>
                                                </Typography>
                                                <Typography variant='subtitle2' sx={{ fontSize: '0.6rem' }}>
                                                    CESS: <strong>{row.store_product.product_master.cess}</strong>
                                                </Typography>
                                            </Stack>
                                        </TableCell>
                                        <TableCell align="center" sx={{ p: 0 }}>{row.tax}</TableCell>
                                        <TableCell align="center" sx={{ p: 0 }}>{row.total_amount}</TableCell>
                                        <TableCell align="center" sx={{ p: 0 }}>
                                            {(row.status > 1) ? (
                                                (row.status == 2) ? (
                                                    <Chip size='small' sx={{ fontSize: '10px', backgroundColor: "#e6bca3", color: "#000000" }} icon={<CloseIcon sx={{ width: "9px" }} />} label="OOS" />
                                                ) : (
                                                    <Chip size='small' sx={{ fontSize: '10px', backgroundColor: "#f27474", color: "#ffffff" }} icon={<CloseIcon sx={{ width: "9px" }} />} label="Canceled" />
                                                )
                                            ) : (
                                                (row.status == 1) ? (
                                                    <Chip variant="outlined" size='small' sx={{ fontSize: '10px', backgroundColor: "#b0e6a3", color: "#000000" }} icon={<CheckIcon sx={{ width: "9px" }} />} label="Accepted" />
                                                ) : (
                                                    <Chip size='small' sx={{ fontSize: '10px' }} icon={<PendingIcon sx={{ width: "9px" }} />} label="Initiated" variant="outlined" />
                                                )
                                            )}
                                        </TableCell>
                                        <TableCell align="center" sx={{ p: 0 }}>
                                            {((currentOrdStatus > 3 || role == 2) ? (
                                                <Button
                                                    sx={{ width: '40px', height: "20px", marginTop: "10px", marginRight: "10px" }}
                                                    variant="outlined"
                                                    color="primary"
                                                    size='small'
                                                    disabled
                                                >
                                                    <EditIcon sx={{ width: "10px" }} />
                                                </Button>
                                            ) : (
                                                <Button
                                                    //variant='contained' 
                                                    onClick={(e) => modalOpen(row)}
                                                    sx={{ width: '40px', height: "20px", marginTop: "10px", marginRight: "10px" }}
                                                    variant="outlined"
                                                    color="primary"
                                                    size='small'
                                                >
                                                    <EditIcon sx={{ width: "10px" }} />
                                                </Button>
                                            ))
                                            }

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                {(role == 2) && (
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item lg={9} xs={0}></Grid>
                            <Grid item lg={3} xs={12} sx={{ textAlign: "right" }}>
                                <Grid container spacing={2}>
                                    <Grid item lg={7} xs={7}>
                                        <Typography variant="body1" sx={{ fontSize: "12px" }}>
                                            Sub Total
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={1} xs={1} sx={{ fontSize: "12px" }}>:</Grid>
                                    <Grid item lg={3} xs={3}>
                                        <Typography variant="body1" sx={{ fontSize: "12px" }}>
                                            {formatCurrency(itemsTotal.subTotal)}
                                        </Typography>
                                    </Grid>

                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item lg={7} xs={7}>
                                        <Typography variant="body1" sx={{ fontSize: "12px" }}>
                                            Total Discount
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={1} xs={1} sx={{ fontSize: "12px" }}>:</Grid>
                                    <Grid item lg={3} xs={3}>
                                        <Typography variant="body1" sx={{ fontSize: "12px" }}>
                                            {formatCurrency(itemsTotal.totalItemDiscount)}
                                        </Typography>
                                    </Grid>

                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item lg={7} xs={7}>
                                        <Typography variant="body1" sx={{ fontSize: "12px" }}>
                                            Total Tax
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={1} xs={1} sx={{ fontSize: "12px" }}>:</Grid>
                                    <Grid item lg={3} xs={3}>
                                        <Typography variant="body1" sx={{ fontSize: "12px" }}>
                                            {formatCurrency(itemsTotal.totalTax)}
                                        </Typography>
                                    </Grid>

                                </Grid>
                                <Grid item lg={12} xs={12}><Divider /></Grid>
                                <Grid container spacing={2}>
                                    <Grid item lg={7} xs={7}>
                                        <Typography variant="body1" sx={{ fontSize: "12px" }}>
                                            Total Order Amount
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={1} xs={1} sx={{ fontSize: "12px" }}>:</Grid>
                                    <Grid item lg={3} xs={3}>
                                        <Typography variant="body1" sx={{ fontSize: "12px" }}>
                                            {formatCurrency(itemsTotal.totalAmt)}
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                )}

            </Box >
        </>
    )
}