import { useDispatch } from "react-redux";

import React, { useEffect, useState } from 'react';
import { TextField, Button, Container, Stack, Box, Typography, IconButton, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import Swal from "sweetalert2";
import { Product } from "../Product/ProductService";
import { Services } from "./Service";

export default function AddVariation({ closeEvent, populateList, rowId, catType }) {
    const { register, handleSubmit, reset, setValue, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const [errorMsg, setErrorMsg] = useState("");
    const [modalTtitle, setModalTtitle] = useState("Add Variation");

    const [variations, setVariation] = useState([]);
    const [variationValue, setVariationValue] = useState("");

    populateList(0);
    const categoryList = async () => {
        const variation = await Services.allOnlyVariaton();
        setVariation(variation.data);
    }
    useEffect(() => {
        categoryList();
        if (Object.keys(rowId).length) {
            setModalTtitle("Edit")
            if (catType == 1) {
                setValue("title", rowId.title);
            }
            if (catType == 2) {
                
                setVariationValue(rowId.parent_variation)
                setValue("variation", rowId.parent_variation)
                setValue("title", rowId.title);
            }
        }

    }, [rowId])

    

    const handleCat = (e) => {
        setVariationValue(e.target.value)
    }

    const catForm = (catType == 1) ? (
        <Grid item xs={12} >
            <TextField
                id="title"
                label="Variation Name"
                variant="outlined"
                name="title"
                size="small"
                sx={{ minWidth: "100%" }}
                {...register('title', { required: true })}
                error={errors.title ? true : false}
            />
            <Typography variant="inherit" color="red">
                {errors.title && 'Variation Name is required'}
            </Typography>
        </Grid>
    ) : (

        <>
            <Grid item xs={12}>
                <FormControl sx={{ minWidth: "100%" }} size="small">
                    <InputLabel id="demo-simple-select-autowidth-label">Select Variation</InputLabel>
                    <Select
                        labelId="variation"
                        id="variation"
                        name="variation"
                        value={variationValue}
                        onChange={handleCat}
                        label="Select Variation"
                        inputProps={{ ...register('variation', { required: true }) }}
                        error={errors.variation ? true : false}
                    >
                        {variations?.map((variation) => (
                            <MenuItem value={variation.id}>{variation.title}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Typography variant="inherit" color="red">
                    {errors.variation && 'Variation choose first.'}
                </Typography>
            </Grid>
            <Grid item xs={12} >
                <TextField
                    id="title"
                    label="Variation Type"
                    variant="outlined"
                    name="title"
                    size="small"
                    sx={{ minWidth: "100%" }}
                    {...register('title', { required: true })}
                    error={errors.title ? true : false}
                />
                <Typography variant="inherit" color="red">
                    {errors.title && 'Variation Type is required'}
                </Typography>
            </Grid>
        </>
    );



    function onSubmit(data) {

        const addData = {
            title: data.title,
            parent_variation: (catType == 1) ? 0 : data.variation
        };
        const createRow = async () => {
            setErrorMsg("");
            let createRowRes = "";
            if (Object.keys(rowId).length) {
                createRowRes = await Services.updateVariation(rowId.id, addData);
            } else {
                createRowRes = await Services.addVariation(addData)
            }

            if (createRowRes.success) {
                Swal.fire(createRowRes.message, '', 'success')
                reset();
                closeEvent();
                populateList(1);
            } else {
                setErrorMsg(createRowRes.message);
            }
        }
        createRow();
    }

    return (

        <>
            <Typography variant="h5" align="center">
                {modalTtitle}
            </Typography>
            <IconButton
                style={{ position: "absolute", top: 0, right: 0 }}
                onClick={closeEvent}
            >
                <CloseIcon />
            </IconButton>
            <Typography variant="inherit" color="red">
                {errorMsg}
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} mt={1}>
                    {catForm}
                    <Grid item xs={12}>
                        <Typography variant="h5" align="center">
                            <Button variant="outlined" color="secondary" type="submit">Save</Button>
                        </Typography>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}