import { commonRequest } from "../../services/ApiCall";
import { Helpers } from "../../services/Helpers";

export const Brand = {
    allBrands : async (data) =>{
        return await commonRequest("POST",  `${Helpers.URL()}/brand/`, data);
    },

    addBrand : async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/brand/create`,data, Helpers.token());
    },

    updateBrand : async (id, data) =>{
        return await commonRequest("PUT",  `${Helpers.URL()}/brand/${id}`, data, Helpers.token());
    },

    changeStatus: async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/brand/statusChange`,data, Helpers.token());
    },

    deleteBrand : async (id) =>{
        return await commonRequest("DELETE",  `${Helpers.URL()}/brand/${id}`, {}, Helpers.token());
    },
    
}