import { useDispatch } from "react-redux";

import React, { useEffect, useState } from 'react';
import { TextField, Button, Container, Stack, Box, Typography, IconButton, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from "zod";
import Swal from "sweetalert2";
import { Services } from "./Services";

const schema = z.object({
    title: z.string().min(1, { message: 'Required' }),
});

export default function AddBrand({ closeEvent, populateList, rowId }) {
    const { register, handleSubmit, reset, setValue, watch, formState: { errors } } = useForm({
        mode: "onChange",resolver: zodResolver(schema)
    });

    const [errorMsg, setErrorMsg] = useState("");
    const [modalTtitle, setModalTtitle ] = useState("Add Brand");

    populateList(0);

    useEffect(() => {   
        if (Object.keys(rowId).length) {
            setModalTtitle("Edit Brand")
            setValue("title", rowId.title);            
        }
    }, [rowId])

    function onSubmit(data) {
        const addData = {
            title: data.title,
        };
        const createRow = async () => {
            setErrorMsg("");
            let createRowRes="";
            if (Object.keys(rowId).length) {
                createRowRes = await Services.updateBrand(rowId.id, addData);                
            }else{
                createRowRes = await Services.addBrand(addData)
            }
                
            if (createRowRes.success) {
                Swal.fire(createRowRes.message, '', 'success')
                reset();
                closeEvent();
                populateList(1);
            } else {
                setErrorMsg(createRowRes.message);
            }
        }
        createRow();
    }    
    
    return (
        <>  
            <Box p={1} mt={0} />
            <Typography variant="h5" align="center">
                {modalTtitle}
            </Typography>
            <IconButton
                style={{ position: "absolute", top: 0, right: 0 }}
                onClick={closeEvent}
            >
                <CloseIcon />
            </IconButton>
            <Typography variant="inherit" color="red">
                {errorMsg}
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} mt={1}>
                    <Grid item xs={12} >
                        <TextField
                            id="title"
                            label="Brand Name"
                            variant="outlined"
                            name="title"
                            size="small"
                            sx={{ minWidth: "100%" }}
                            {...register('title')}
                            error={errors.title ? true : false}
                        />
                        <Typography variant="inherit" color="red">
                            {errors.title?.message}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" align="center">
                            <Button variant="outlined" color="secondary" type="submit">Save</Button>
                        </Typography>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}