import { useDispatch } from "react-redux";

import React, { useEffect, useState } from 'react';
import { TextField, Button, Stack, Box, Typography, Grid, FormControl, InputLabel, Select, MenuItem, Card, Divider, Autocomplete, Checkbox } from '@mui/material';

import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from "zod";
import { Product } from "./ProductService";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from 'uuid';
import { useParams } from "react-router-dom";
import { uploadImageS3Bucket } from "../../utils/s3bucketImg";
import { GenerateUniqueVal } from "../../utils/generateUniqValue";
import { CategoryService } from "../Category/CategoryService";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import VarientDetailsForm from "./component/VarientDetailsForm";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const sizes = [
    {
        path: "products/sm",
        width: 100,
        height: 100,
        quality: 30,
    },
    {
        path: "products/md",
        width: 300,
        height: 300,
        quality: 30,
    },
    {
        path: "products/lg",
        width: 600,
        height: 600,
        quality: 80,
    }
];

const MAX_FILE_SIZE = 500000;
const ACCEPTED_IMAGE_TYPES = ["image/jpeg", "image/jpg", "image/png", "image/webp"];


export default function AddEditProduct() {
    const { control, register, handleSubmit, reset, setValue, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const { id } = useParams();
    const [rowId, setRowId] = useState({});
    const [productImage, setProductImage] = useState([]);
    const [showImages, setShowImages] = useState([]);
    const [imageError, setImageError] = useState("");
    const [loading, setLoading] = useState(false);
    const [oldImages, setOldImages] = useState([])
    const unCode = 'SKU' + (new Date()).getTime();
    const [errorMsg, setErrorMsg] = useState("");
    const [triggerPage, setTriggerPage] = useState(null);
    const [productVariants, setProductVariants] = useState([]);
    const [brands, setBrands] = useState([]);

    const [category, setCategory] = useState([]);

    const [subCategory, setSubCategory] = useState([]);

    const [subSubCategory, setSubSubCategory] = useState([]);
    const [subSubCategoryValue, setSubSubCategoryValue] = useState("");
    // Varient selection
    const [selectVarientObj, setSelectVarientObj] = useState([]);
    const [catVariants, setCatVariants] = useState([]);
    const handleVariationChange = (e, selectedValue) => {
        setSelectVarientObj(selectedValue);
    }
    //******************************* */
    const sku = unCode.toUpperCase();
    const brandList = async () => {
        const brands = await Product.getBrandList();
        setBrands(brands.data);
    }
    const categoryList = async () => {
        const category = await Product.getCategoryList();
        setCategory(category.data);
    }
    const productById = async (id) => {
        const prodRes = await Product.getProductById(id);
        if (prodRes.success) {
            console.log(prodRes.data);
            setRowId(prodRes.data);
            setValue('category', prodRes.data.product_categories[0].category);
            const catVariations = await CategoryService.getCatVariations(prodRes.data.product_categories[0].category);
            if (catVariations) {
                const filter_id = prodRes.data.variation_ids;
                const filterVarientIds = catVariations.data.filter(item => filter_id.split(',').includes(item.id.toString()));
                setCatVariants(catVariations.data);
                setSelectVarientObj(filterVarientIds);
            }
            const subCategoryList = async () => {
                const subCategory = await Product.getSubCategoryList(prodRes.data.product_categories[0].category);
                if (subCategory) {
                    setSubCategory(subCategory.data);
                    const subSubCategoryList = async () => {
                        const subSubCategory = await Product.getSubSubCategoryList(prodRes.data.product_categories[0].sub_category);
                        setSubSubCategory(subSubCategory.data);
                    }
                    subSubCategoryList();
                }
            }
            subCategoryList();
            setProductVariants(prodRes.data.store_product_variations);
            setValue('subcategory', prodRes.data.product_categories[0].sub_category);
            setValue('subsubcategory', prodRes.data.product_categories[0].sub_sub_category_id);
            setSubSubCategoryValue(prodRes.data.product_categories[0].sub_sub_category_id)
            setValue("select_brand", prodRes.data.brand_id, { shouldValidate: true });

            setValue("pTitle", prodRes.data.title);
            setValue("pSTitle", prodRes.data.short_title);
            setValue("sku", prodRes.data.sku);
            setValue("description", prodRes.data.description);
            setValue("sDescription", prodRes.data.short_description);
            setValue("cgst", prodRes.data.cgst);
            setValue("sgst", prodRes.data.sgst);
            setValue("igst", prodRes.data.igst);
            setValue("cess", prodRes.data.cess);
            setOldImages(prodRes.data.product_master_images);
        }
    }

    useEffect(() => {
        brandList();
        categoryList();
        if (id > 0) {
            productById(id);
        } else {
            setValue("sku", sku);
        }
    }, [triggerPage])

    const handleFileChange = (e) => {
        const file = e.target.files;
        let images = [];
        for (let img of file) {
            images.push(URL.createObjectURL(img))
        }
        setImageError("");
        setShowImages(images);
        setProductImage(file);
    };

    function onSubmit(data) {
        try {
            if (!Object.keys(selectVarientObj).length) {
                Swal.fire("At least one variant is required", '', 'error');
                return false;
            }
            if (selectVarientObj.length > 2) {
                Swal.fire("Maximum 2 variants are allowed", '', 'error');
                return false;
            }
            const selectedIds = selectVarientObj.map(variant => variant.id).join(',');
            let variationDetailList = [];
            for (let dataVar of data.varientDetails) {
                if (dataVar.variation_details_id > 0) {
                    for (let varRow of catVariants) {
                        for (let varDetail of varRow.list) {
                            if (varDetail.id == dataVar.variation_details_id) {
                                variationDetailList.push({
                                    variation_details_id: varDetail.id,
                                    variation_title: varRow.title,
                                    variation_value: varDetail.title,
                                })
                            }
                        }
                    }
                }
            }
            const productData = {
                title: data.pTitle,
                short_title: data.pSTitle,
                brand_id: data.select_brand,
                sku: data.sku,
                short_description: data.sDescription,
                description: data.description,
                category_id: subSubCategoryValue,
                variation_ids: selectedIds,
                cgst: data.cgst,
                sgst: data.sgst,
                igst: data.igst,
                cess: data.cess,
                variationDetailList: variationDetailList
            };
            if (!Object.keys(rowId).length) {
                if (productImage.length < 1) {
                    setImageError("At least one image is required")
                    return false;
                } else {
                    setImageError("");
                }
            }
            const createProduct = async () => {
                setErrorMsg("");
                setLoading(true)
                let createProduct = "";
                if (Object.keys(rowId).length) {
                    createProduct = await Product.updateProduct(rowId.id, productData);
                } else {
                    createProduct = await Product.addProduct(productData)
                }
                //                 console.log("response", createProduct)
                // return false;
                if (createProduct.success) {
                    let imageindex = 0;
                    if (productImage.length > 0) {
                        for (let imgRow of productImage) {
                            const imgType = (imageindex == 0) ? 1 : 0;
                            const imageName = (imageindex == 0) ? `product-${uuidv4()}-0.jpeg` : `product-${uuidv4()}-1.jpeg`;
                            const uploadFile = async () => {
                                for (let sizeImg of sizes) {
                                    const imageOption = {
                                        path: sizeImg.path,
                                        file: imgRow,
                                        fileName: imageName,
                                        imgWidth: sizeImg.width,
                                        imgHeight: sizeImg.height,
                                        imgType: "JPEG",
                                        quality: sizeImg.quality
                                    }
                                    const imageRes = await uploadImageS3Bucket(imageOption);
                                    if (sizeImg.width == 600) {
                                        const imageUploadData = {
                                            productId: (Object.keys(rowId).length) ? rowId.id : createProduct.data.product.id,
                                            image_type: (Object.keys(rowId).length) ? 0 : imgType,
                                            image_title: imageName,
                                            image_url: imageRes.Key
                                        }

                                        const createImage = async () => {
                                            const imgRes = await Product.productImageCreate(imageUploadData);
                                        }
                                        createImage();
                                    }
                                }
                                setLoading(false)
                            };
                            uploadFile();
                            imageindex++;
                        }
                    }
                    Swal.fire(createProduct.message, '', 'success')
                    setTriggerPage(GenerateUniqueVal());
                } else {
                    Swal.fire(createProduct.message, '', 'error');
                }
            }
            createProduct();
        } catch (error) {
            Swal.fire("Something went wrong", '', 'error');
        }
    }

    const categoryHandel = (e) => {
        const subCategoryList = async () => {
            const subCategory = await Product.getSubCategoryList(e.target.value);
            const catVariations = await CategoryService.getCatVariations(e.target.value);
            console.log(catVariations.data)
            if (catVariations) {
                setCatVariants(catVariations.data);
            }
            setSubCategory(subCategory.data);
            setSubSubCategory([]);
        }
        subCategoryList();
    }

    const subCategoryHandel = (e) => {
        const subSubCategoryList = async () => {
            const subSubCategory = await Product.getSubSubCategoryList(e.target.value);
            setSubSubCategory(subSubCategory.data);
        }
        subSubCategoryList();
    }

    const subSubCategoryHandle = (e) => {
        setSubSubCategoryValue(e.target.value)
    }


    return (
        <>
            <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
                <Card sx={{ minWidth: "100%", marginBottom: "10px" }}>
                    <Stack direction="row" spacing={2} className="">
                        <Typography
                            gutterBottom
                            variant='h5'
                            component='div'
                            sx={{ padding: '20px' }}
                        >
                            {(id > 0) ? "Edit Product" : "Add New Product"}
                        </Typography>
                    </Stack>
                </Card>

                <Typography variant="inherit" color="red">
                    {errorMsg}
                </Typography>
                <Box sx={{ overflow: "auto", backgroundColor: "#fff", p: 2 }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2} mt={1}>
                            <Grid item lg={9} xs={12}>
                                <Grid container spacing={2} mt={1} p={1}>
                                    <Grid item xs={12} sx={{ border: '1px solid #eee', borderRadius: '6px', padding: "1rem", marginBottom: "1rem" }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} lg={12}>
                                                <Controller
                                                    name="pTitle"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{
                                                        required: "Product Name is required",
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            placeholder="Enter Product Name"
                                                            label="Product Name"
                                                            size="small"
                                                            fullWidth
                                                            error={!!errors.pTitle}
                                                            helperText={errors.pTitle?.message}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={8}>
                                                <Controller
                                                    name="pSTitle"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{
                                                        required: "Product Short Name is required",
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            placeholder="Enter Product Short Name"
                                                            label="Product Short Name"
                                                            size="small"
                                                            fullWidth
                                                            error={!!errors.pSTitle}
                                                            helperText={errors.pSTitle?.message}
                                                        />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item lg={4} xs={12}>
                                                <FormControl sx={{ minWidth: "100%" }} size="small">
                                                    <InputLabel id="demo-simple-select-autowidth-label">Select Brand</InputLabel>
                                                    <Controller
                                                        name="select_brand"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{ required: `Brand is required` }}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                label="Select Brand"
                                                                error={!!errors.select_brand}
                                                            >
                                                                <MenuItem value="">
                                                                    <em>None</em>
                                                                </MenuItem>
                                                                {brands && brands?.map((brand) => (
                                                                    <MenuItem key={brand.id} value={brand.id}>{brand.title}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        )}
                                                    />
                                                </FormControl>
                                                <Typography variant="inherit" color="red">
                                                    {errors.select_brand?.message}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sx={{ border: '1px solid #eee', borderRadius: '6px', padding: "1rem", marginBottom: "1rem" }}>
                                        <Grid container spacing={2}>
                                            <Grid item lg={4} xs={12}>
                                                <FormControl sx={{ minWidth: "100%" }} size="small">
                                                    <InputLabel id="demo-simple-select-autowidth-label">Select Category</InputLabel>
                                                    <Controller
                                                        name="category"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{ required: `Category is required` }}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                label="Select category"
                                                                onChange={(e) => {
                                                                    field.onChange(e.target.value); // Update the form state with the selected value
                                                                    categoryHandel(e); // Call your custom event handler
                                                                }}
                                                                error={!!errors.category}
                                                            >
                                                                <MenuItem value="">
                                                                    <em>None</em>
                                                                </MenuItem>
                                                                {category && category?.map((category) => (
                                                                    <MenuItem key={category.id} value={category.id}>{category.title}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        )}
                                                    />
                                                </FormControl>
                                                <Typography variant="inherit" color="red">
                                                    {errors.category?.message}
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={4} xs={12}>
                                                <FormControl sx={{ minWidth: "100%" }} size="small">
                                                    <InputLabel id="demo-simple-select-autowidth-label">Select Sub Category</InputLabel>
                                                    <Controller
                                                        name="subcategory"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{ required: `Sub Category is required` }}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                label="Select Sub category"
                                                                onChange={(e) => {
                                                                    field.onChange(e.target.value); // Update the form state with the selected value
                                                                    subCategoryHandel(e); // Call your custom event handler
                                                                }}
                                                                error={!!errors.subcategory}
                                                            >
                                                                <MenuItem value="">
                                                                    <em>None</em>
                                                                </MenuItem>
                                                                {subCategory && subCategory?.map((subCategory) => (
                                                                    <MenuItem key={subCategory.id} value={subCategory.id}>{subCategory.title}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        )}
                                                    />
                                                </FormControl>
                                                <Typography variant="inherit" color="red">
                                                    {errors.subcategory?.message}
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={4} xs={12}>
                                                <FormControl sx={{ minWidth: "100%" }} size="small">
                                                    <InputLabel id="demo-simple-select-autowidth-label">Select Super Category</InputLabel>
                                                    <Controller
                                                        name="subsubcategory"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{ required: `Super Category is required` }}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                label="Select Super category"
                                                                onChange={(e) => {
                                                                    field.onChange(e.target.value); // Update the form state with the selected value
                                                                    subSubCategoryHandle(e); // Call your custom event handler
                                                                }}
                                                                error={!!errors.subsubcategory}
                                                            >
                                                                <MenuItem value="">
                                                                    <em>None</em>
                                                                </MenuItem>
                                                                {subSubCategory && subSubCategory?.map((subsubcategory) => (
                                                                    <MenuItem key={subsubcategory.id} value={subsubcategory.id}>{subsubcategory.title}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        )}
                                                    />
                                                </FormControl>
                                                <Typography variant="inherit" color="red">
                                                    {errors.subsubcategory?.message}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sx={{ border: '1px solid #eee', borderRadius: '6px', padding: "1rem", marginBottom: "1rem" }}>
                                        <Grid container spacing={2}>
                                            <Grid item lg={8} xs={12}>
                                                <Controller
                                                    name="variantOptions"
                                                    control={control}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Autocomplete
                                                            multiple
                                                            id="tags-standard"
                                                            options={catVariants}
                                                            getOptionLabel={(option) => option.title}
                                                            value={selectVarientObj} // Pass the value from react-hook-form
                                                            onChange={handleVariationChange}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Variant Options"
                                                                    placeholder="Variant Options"
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                />
                                                <Typography variant="inherit" color="red">
                                                    {errors.variantOptions?.message}
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={4} xs={12}>
                                                <Controller
                                                    name="sku"
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            placeholder="Enter Product SKU"
                                                            label="Enter Product SKU"
                                                            size="small"
                                                            fullWidth
                                                            error={!!errors.sku}
                                                        />
                                                    )}
                                                />
                                                <Typography variant="inherit" color="red">
                                                    {errors.sku?.message}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {selectVarientObj && selectVarientObj.length > 0 && (
                                        <Grid item xs={12} sx={{ paddingLeft: "0px !important" }}>
                                            <Card sx={{ minWidth: "100%", marginBottom: "10px", p: 2 }}>
                                                <VarientDetailsForm control={control} catVariants={catVariants} selectVarientObj={selectVarientObj} productVariants={productVariants} errors={errors} />
                                            </Card>
                                        </Grid>
                                    )}
                                    <Grid item xs={12} sx={{ paddingLeft: "0px !important" }} >
                                        <Card sx={{ minWidth: "100%", marginBottom: "10px", p: 2 }}>
                                            <Typography
                                                gutterBottom
                                                variant='h6'
                                                component='div'
                                            >
                                                GST
                                            </Typography>
                                            <Divider />
                                            <Box>
                                                <Grid container spacing={2} sx={{ marginTop: '0.7rem' }}>
                                                    <Grid item lg={3} xs={6}>
                                                        <Controller
                                                            name="cgst"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    placeholder="Enter Product CGST(%)"
                                                                    label="Enter CGST(%)"
                                                                    size="small"
                                                                    type="number"
                                                                    fullWidth
                                                                    error={!!errors.cgst}
                                                                />
                                                            )}
                                                        />
                                                        <Typography variant="inherit" color="red">
                                                            {errors.cgst?.message}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item lg={3} xs={6}>
                                                        <Controller
                                                            name="sgst"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    placeholder="Enter Product SGST(%)"
                                                                    label="Enter SGST(%)"
                                                                    size="small"
                                                                    type="number"
                                                                    fullWidth
                                                                    error={!!errors.sgst}
                                                                />
                                                            )}
                                                        />
                                                        <Typography variant="inherit" color="red">
                                                            {errors.sgst?.message}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item lg={3} xs={6}>
                                                        <Controller
                                                            name="igst"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    placeholder="Enter Product IGST(%)"
                                                                    label="Enter IGST(%)"
                                                                    size="small"
                                                                    type="number"
                                                                    fullWidth
                                                                    error={!!errors.igst}
                                                                />
                                                            )}
                                                        />
                                                        <Typography variant="inherit" color="red">
                                                            {errors.igst?.message}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item lg={3} xs={6}>
                                                        <Controller
                                                            name="cess"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    placeholder="Enter Product CESS(%)"
                                                                    label="Enter CESS(%)"
                                                                    size="small"
                                                                    type="number"
                                                                    fullWidth
                                                                    error={!!errors.cess}
                                                                />
                                                            )}
                                                        />
                                                        <Typography variant="inherit" color="red">
                                                            {errors.cess?.message}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sx={{ paddingLeft: "0px !important" }} >
                                        <Card sx={{ minWidth: "100%", marginBottom: "10px", p: 2 }}>
                                            <Typography
                                                gutterBottom
                                                variant='h6'
                                                component='div'
                                            >
                                                Descriptions
                                            </Typography>
                                            <Divider />
                                            <Box>
                                                <Grid container spacing={2} sx={{ marginTop: '0.7rem' }}>
                                                    <Grid item lg={4} xs={12}>
                                                        <Controller
                                                            name="sDescription"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    placeholder="Enter Short Description"
                                                                    label="Short Description"
                                                                    size="small"
                                                                    rows={3}
                                                                    multiline
                                                                    fullWidth
                                                                    error={!!errors.sDescription}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={8} xs={12}>
                                                        <Controller
                                                            name="description"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    placeholder="Enter Description"
                                                                    label="Description"
                                                                    size="small"
                                                                    rows={3}
                                                                    multiline
                                                                    fullWidth
                                                                    error={!!errors.description}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h5" align="center">
                                            <Button variant="outlined" color="secondary" type="submit">Save</Button>
                                        </Typography>
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item lg={3} s={12} >
                                <Card sx={{ p: 1 }}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>

                                            <label htmlFor="upload-image">
                                                <Button sx={{ width: "100%" }} variant="outlined" component="span">
                                                    Choose Product Images
                                                </Button>

                                                <Controller
                                                    name="productImage"
                                                    control={control}
                                                    defaultValue={null}
                                                    rules={{ required: (id > 0) ? false : "Product Images are required" }}
                                                    render={({ field }) => (
                                                        <input
                                                            id="upload-image"
                                                            hidden
                                                            accept="image/*"
                                                            type="file"
                                                            onChange={(e) => {
                                                                field.onChange(e); // Call onChange method of field
                                                                handleFileChange(e); // Handle file change
                                                            }}
                                                            multiple
                                                        />
                                                    )}
                                                />
                                                <Typography variant="inherit" color="red">
                                                    {errors.productImage?.message}
                                                </Typography>
                                            </label>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {showImages && showImages.map((imageRow) => (
                                                <Box
                                                    component="img"
                                                    sx={{
                                                        height: 40,
                                                        width: 40,
                                                        marginLeft: "5px",
                                                        borderRadius: "10px"
                                                        // maxHeight: { xs: 233, md: 167 },
                                                        // maxWidth: { xs: 350, md: 250 },
                                                    }}
                                                    alt="The house from the offer."
                                                    src={imageRow}
                                                />
                                            ))}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {oldImages && oldImages.map((imageRow) => (
                                                <Box
                                                    component="img"
                                                    sx={{
                                                        height: 80,
                                                        width: 80,
                                                        marginLeft: "5px",
                                                        borderRadius: "10px"
                                                        // maxHeight: { xs: 233, md: 167 },
                                                        // maxWidth: { xs: 350, md: 250 },
                                                    }}
                                                    alt="The house from the offer."
                                                    src={`${imageRow.image_url}md/${imageRow.image_title}`}
                                                />
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Box >
        </>
    )
}