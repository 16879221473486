import { Box } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import BookingList from "./BookingList";

export default function Booking() {
    const menuDispatch = useDispatch();
    menuDispatch({
        type: 'menuSelect',
        payload: 'bookings'
    });
    return (
        <>
            <BookingList/>
        </>
    )
}
