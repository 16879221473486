import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';


export default function VarientDetailsForm({ control, catVariants, selectVarientObj, productVariants, errors }) {
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'varientDetails',
    });
    //console.log("detail form data", productDetail, variations)
    const [filterVariation, setFilterVariation] = useState([]);
    const [fieldsAppended, setFieldsAppended] = useState(false);

    useEffect(() => {
        if (!fieldsAppended) {
            const variationsData = selectVarientObj;
            const filterVariation = catVariants.filter((variation) => {
                for (let i = 0; i < variationsData.length; i++) {
                    if (variation.id === variationsData[i].id) {
                        return false;
                    } else {
                        return variation;
                    }
                }
            });
            setFilterVariation(filterVariation);
            for (let variation of filterVariation) {
                if (productVariants.length > 0) {
                    let st = false;
                    let ids = [];
                    for (let variant of productVariants) {
                        if (variation.title == variant.variation_title) {
                            st = true
                            ids = {
                                variation_details_id: variant.variation_details_id,
                                variation_title: variant.variation_title,
                                variation_value: variant.variation_value,
                            }
                        }
                    }
                    if (st) {
                        append(ids);
                    } else {
                        append({
                            variation_details_id: "",
                            variation_title: variation.title,
                            variation_value: "",
                        });
                    }
                } else {
                    append({
                        variation_details_id: "",
                        variation_title: variation.title,
                        variation_value: "",
                    });
                }
            }
            setFieldsAppended(true);
        }
    }, [catVariants, selectVarientObj, productVariants]);

    //const disableField = (sellerProductId > 0) ? true : false;
    return (
        <>
            <Typography
                gutterBottom
                variant='h6'
                component='div'
            >
                Product Meta Data
            </Typography>
            <Divider />
            <Box>
                <Grid container spacing={2} sx={{ marginTop: '0.7rem' }}>
                    {fields.map((items, index) => {
                        // console.log("item", item, index, filterVariation[index].list)                   
                        return (

                            <Grid key={index} item xs={12} lg={6} >
                                {filterVariation.length > 0 && (


                                    <FormControl sx={{ minWidth: "100%" }} size="small">
                                        <InputLabel id="demo-simple-select-autowidth-label">{`SELECT ${(filterVariation[index]) ? filterVariation[index].title : ""}`} </InputLabel>
                                        <Controller
                                            name={`varientDetails[${index}].variation_details_id`}
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: false }}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    label={`SELECT ${(filterVariation[index]) ? filterVariation[index].title : ""}}`}
                                                >
                                                    {filterVariation[index] && filterVariation[index].list.map((variation) => (
                                                        <MenuItem key={variation.id} value={variation.id}>{variation.title}</MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                )}
                            </Grid>

                        );
                    })}
                </Grid>
            </Box>
        </>
    )
}