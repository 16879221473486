import AWS from "aws-sdk";
import { ResizeImage } from "./resizeImage";
import { DeleteObjectCommand, S3Client } from "@aws-sdk/client-s3";

const REGION = process.env.REACT_APP_REGION;
const ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;

export const deletS3BucketFile = async (options) => {
    const client = new S3Client({});
    const command = new DeleteObjectCommand({
        Bucket: options.path,
        Key: options.fileName,
    });
    try {
        const response = await client.send(command);
        
        return response;
    } catch (err) {
        console.error(err);
    }
};

export const uploadImageS3Bucket = async (options) => {
    const S3_BUCKET = `bharat2bharat/images/${options.path}`;
console.log("dddddddddddddddd", options)
    AWS.config.update({
        accessKeyId: ACCESS_KEY_ID,
        secretAccessKey: SECRET_ACCESS_KEY,
    });

    const s3 = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
    });

    const imageUri = await ResizeImage(options.file, options.imgWidth, options.imgHeight, options.imgType, options.quality)
    try {
        if (imageUri) {
            const base64ImageData = imageUri;
            const base64Data = base64ImageData.replace(/^data:image\/\w+;base64,/, '');
            const imageBuffer = new Uint8Array(atob(base64Data).split('').map(char => char.charCodeAt(0)));

            const params = {
                Bucket: S3_BUCKET,
                Key: options.fileName,
                Body: imageBuffer,
                ContentEncoding: 'base64',
                ContentType: 'image/jpeg'
            };

            const resS3 = await s3.upload(params).promise(); // Use .promise() to await the promise
            return resS3; // You can return the response data if needed
        }
    } catch (error) {
        console.error('Error uploading image:', error);
        throw error; // Handle the error as needed
    }
}

