import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  Typography,
  Grid,
  Box,
  InputLabel,
  Stack,
  Avatar,
} from '@mui/material';
import { User } from './UserService';
import Swal from 'sweetalert2';
import { Helpers } from '../../services/Helpers';
import ImgUploadDialog from '../../components/ImgUpload/imgUpload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { GenerateUniqueVal } from '../../utils/generateUniqValue';

export default function PersonalDetails({ userIdProp, personalDetails, onSubmitPersonal, tabNextChange }) {
  const { control, handleSubmit, setValue, formState: { errors } } = useForm({
    mode: "onChange"
  });
  const validationRules = {
    required: 'This field is required',
  };
  const [profileImg, setProfileImg] = useState("");
  const [bannerImg, setBannerImg] = useState("");

  useEffect(() => {
console.log("personalDetails",personalDetails)
    if (personalDetails) {
      setValue('first_name', personalDetails.first_name);
      setValue('last_name', personalDetails.last_name);
      setValue('email', personalDetails.email);
      setValue('gstIn', personalDetails.gstIn);
      setValue('gender', personalDetails.gender);
      setValue('mobile_number', personalDetails.mobile_number);
      setValue('role', personalDetails.role);
      setProfileImg(personalDetails.profile_image)
      setBannerImg(personalDetails.banner_image)
    }
  }, [personalDetails]);

  const handleNext = () => {
    tabNextChange(1)
  }
  const onSubmit = (data) => {
    const name = `${data.first_name} ${data.last_name}`;
    const personalData = {
      first_name: data.first_name,
      last_name: data.last_name,
      mobile_number: data.mobile_number,
      gender: data.gender,
      email: data.email,
      gstIn: data.gstIn,
      role: data.role,
      profile_image: personalDetails.profile_image,
      banner_image: personalDetails.banner_image
    }

    const createRow = async () => {
      let createRowRes = "";
      let msg = "";
      if (userIdProp > 0) {
        createRowRes = await User.updateUser(userIdProp, personalData);
        personalData.id = userIdProp;
        msg = "Update Successfully";
      } else {
        if (personalDetails.id > 0) {
          createRowRes = await User.updateUser(personalDetails.id, personalData);
          personalData.id = personalDetails.id;
          msg = "Update Successfully";
        } else {
          personalData.slug = Helpers.generateSlug(name.trim());
          createRowRes = await User.addUser(personalData);
          personalData.id = createRowRes.data.id;
          msg = "User Registerd Successfully";
        }
      }

      if (createRowRes.success) {
        onSubmitPersonal(personalData)
        Swal.fire(msg, '', 'success')
      } else {
        Swal.fire(createRowRes.message, '', 'error');
      }
    }
    createRow();
  };
  const [trigStatus, setTrigStatus] = useState(null);
  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [imgType, setImgType] = useState("");
  const openImgModal = (imgType) => {
    setImgType(imgType);
    setImgModalOpen(true);
  };

  const closeModal = () => {
    setImgModalOpen(false);
  };

  const handlePImgTrig = (data) => {
    setTrigStatus(data);
  }
  const imgData = {
    path: "admin_profile_image",
    image_name: `img-${GenerateUniqueVal(1000000)}-${personalDetails.id}.jpeg`,
    img_type: "single"
  };
  const updateUserProfileImage = async (data, imgType) => {
    let pData;
    if (imgType == "profile") {
      pData = { profile_image: data.Location };
    } else {
      pData = { banner_image: data.Location };
    }
    const updateUser = await User.updateUser(userIdProp, pData);
    if (updateUser.success) {
      if (imgType == "profile") {
        setProfileImg(data.Location);
      } else {
        setBannerImg(data.Location)
      }
      closeModal();
      Swal.fire("Image Uploaded", '', 'success')
    }
  }
  const imgUploadResponse = (data) => {
    if (imgType == "profile") {
      updateUserProfileImage(data, "profile");
    }
    if (imgType == "banner") {
      updateUserProfileImage(data, "banner");
    }
  }

  return (
    <Box sx={{ overflow: "auto", backgroundColor: "#fff" }}>

      <ImgUploadDialog open={imgModalOpen} onClose={closeModal} imgData={imgData} imgUploadResponse={imgUploadResponse} trigStatus={handlePImgTrig} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} sx={{ paddingTop: '15px' }}>
          <Grid item lg={5} xs={12}>
            <Grid container spacing={2} sx={{ paddingTop: '15px' }}>
              <Grid item lg={6} xs={12}>
                <Controller
                  name="first_name"
                  control={control}
                  defaultValue=""
                  rules={validationRules}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter First Name"
                      label="First Name"
                      size="small"
                      fullWidth
                      error={!!errors.first_name}
                      helperText={errors.first_name && errors.first_name.message}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <Controller
                  name="last_name"
                  control={control}
                  defaultValue=""
                  rules={validationRules}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter Last Name"
                      label="Last Name"
                      size="small"
                      fullWidth
                      error={!!errors.last_name}
                      helperText={errors.last_name && errors.last_name.message}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <Controller
                  name="mobile_number"
                  control={control}
                  defaultValue=""
                  rules={validationRules}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter Mobile Number"
                      label="Mobile Number"
                      size="small"
                      fullWidth
                      error={!!errors.mobile_number}
                      helperText={errors.mobile_number && errors.mobile_number.message}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <FormControl sx={{ minWidth: "100%" }} size="small">
                  <InputLabel id="demo-simple-select-autowidth-label">Select Gender</InputLabel>
                  <Controller
                    name="gender"
                    control={control}
                    defaultValue=""
                    rules={validationRules}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Mobile Number"
                        error={!!errors.gender}
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
                <Typography variant="inherit" color="red">
                  {errors.gender && errors.gender.message}
                </Typography>
              </Grid>
              <Grid item lg={12} xs={12}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={validationRules}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter Email"
                      label="Email"
                      size="small"
                      fullWidth
                      error={!!errors.email}
                      helperText={errors.email && errors.email.message}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <Controller
                  name="gstIn"
                  control={control}
                  defaultValue=""
                  rules={validationRules}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter GSTIN"
                      label="Enter GSTIN"
                      size="small"
                      fullWidth
                      error={!!errors.gstIn}
                      helperText={errors.gstIn && errors.gstIn.message}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <FormControl sx={{ minWidth: "100%" }} size="small">
                  <InputLabel id="demo-simple-select-autowidth-label">Select Role</InputLabel>
                  <Controller
                    name="role"
                    control={control}
                    defaultValue=""
                    rules={validationRules}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Mobile Number"
                        error={!!errors.role}
                      >
                        <MenuItem value="4">Admin</MenuItem>
                        <MenuItem value="3">Sarthi</MenuItem>
                        <MenuItem value="2">Seller</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
                <Typography variant="inherit" color="red">
                  {errors.role && errors.role.message}
                </Typography>
              </Grid>
              <Grid item lg={12} xs={12}>
                <Stack direction="row">
                  <Button type="submit" variant="contained" color="primary">
                    Save
                  </Button>
                  <Box sx={{ flexGrow: 1 }}></Box>
                  <Button type="button" onClick={handleNext} variant="contained" color="primary">
                    Next
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={7} xs={12}>
            <Grid container spacing={2}>
              <Grid item lg={6} xs={12}>
                {userIdProp > 0 && (
                  <Stack direction="column" spacing={2}>
                    <Typography variant='div'>
                      <Avatar
                        alt={personalDetails.first_name}
                        src={profileImg}
                        sx={{ width: 150, height: 150 }}
                      />
                    </Typography>
                    <Typography variant='div'>
                      <Button size="small" sx={{ width: "300px" }} variant="outlined" startIcon={<CloudUploadIcon />} onClick={(e) => openImgModal('profile')}>{(profileImg) ? "Upload Profile Image" : "Change Profile Image"}</Button>
                    </Typography>
                  </Stack>
                )}
              </Grid>
              <Grid item lg={6} xs={12}>
                {userIdProp > 0 && (
                  <Stack direction="column" spacing={2}>
                    <Typography variant='div'>
                      <img src={bannerImg} style={{ width: "300px" }} />
                    </Typography>
                    <Typography variant='div'>
                      <Button size="small" sx={{ width: "300px" }} variant="outlined" startIcon={<CloudUploadIcon />} onClick={(e) => openImgModal('banner')}>Change Banner Image</Button>
                    </Typography>
                  </Stack>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box >
  );
}
