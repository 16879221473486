import { Box, Button, Card, Chip, Divider, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Services } from "./Services";
import { CategoryService } from "../Category/CategoryService";
import { useForm, Controller } from 'react-hook-form';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Swal from "sweetalert2";
import { LocalStore } from "../../storage/authStore";
import OrderTransaction from "./OrderTransaction";
import OrderItems from "./OrderItems";
import SellerInvoice from "./Invoice/SellerInvoice";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { formatCurrency } from "../../utils/currencyFormatter";
import { GenerateUniqueVal } from "../../utils/generateUniqValue";


export default function AddEditOrder() {
    const { control, handleSubmit, getValues, setValue, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const validationRules = {
        required: 'Required',
    };
    const [storeItems, setStoreItems] = useState([]);
    const userDetail = LocalStore.getCurrentUserDetails();

    const role = (userDetail) ? userDetail.role : 0;
    const menuDispatch = useDispatch();
    menuDispatch({
        type: 'menuSelect',
        payload: 'orders'
    });
    const [orderStatusList, setOrderStatusList] = useState([]);
    const [slectedStatus, setSelectedStatus] = useState("");
    const [orderDetail, setOrderDetail] = useState([]);
    const [loading, setLoading] = useState(false);
    const [calError, setCalError] = useState("");
    const [oldMessage, setOldMessage] = useState([])
    const [updateStatus, setUpdateStatus] = useState(0)
    const [firstTime, setFirstTime] = useState(true);
    const { id } = useParams();
    const [triggerAdd, setTriggerAdd] = useState(true)
    const [triggerMain, setTriggerMain] = useState(0)

    const [currentOrdStatus, setCurrentOrdStatus] = useState(0);
    const [orderCalculation, setOrderCalculation] = useState({
        subTotal: 0.00,
        total_amount: 0.00,
        delivery_charge: 0.00,
        tax: 0.00,
        profit_amount: 0.00,
        total_item_discount: 0.00
    })
    const [itemsTotal, setItemsTotal] = useState({})
    const getOrders = async (id) => {
        try {
            const sellerId = (role == 2) ? userDetail.id : 0;
            const response = await Services.orderById(id, sellerId);
            const orderData = response.data;
            const orderItems = orderData.store_order_items;
            if (orderItems.length > 0) {
                setStoreItems(orderItems);
            }
            let totalCal = {
                totalItemDiscount: 0,
                subTotal: 0,
                totalAmt: 0,
                totalQty: 0,
                totalTax: 0,
            }
            for (let items of orderItems) {
                let discountAmt = (items.sale_price * items.quantity * items.discount) / 100;
                totalCal.subTotal += items.subtotal;
                totalCal.totalItemDiscount += ((discountAmt < 1) ? 0 : discountAmt);
                totalCal.totalQty += items.quantity;
                totalCal.totalAmt += items.total_amount;
                totalCal.totalTax += items.tax;
            }
            setItemsTotal(totalCal)
            setCurrentOrdStatus(response.data.order_statusId)
            setSelectedStatus(response.data.order_statusId)
            setValue('order_status', response.data.order_statusId)
            setValue('order_number', response.data.order_number)
            setValue('customer', `${response.data.customer.first_name} ${response.data.customer.last_name}`)
            setValue('c_m_no', response.data.customer.mobile_number)
            setValue('email', response.data.customer.email)
            setValue('add_full_name', response.data.add_full_name)
            setValue('add_phone_number', response.data.add_phone_number)
            setValue('add_pincode', response.data.add_pincode)
            setValue('add_state', response.data.add_state)
            setValue('add_city', response.data.add_city)
            setValue('add_hno_buildname', response.data.add_hno_buildname)
            setValue('add_road_area_colony', response.data.add_road_area_colony)
            setValue('delivery_charge', response.data.delivery_charge)
            setOrderCalculation({
                ...orderCalculation,
                subTotal: orderData.subtotal,
                total_amount: orderData.total_amount,
                delivery_charge: orderData.delivery_charge,
                tax: orderData.tax,
                profit_amount: orderData.profit_amount,
                total_item_discount: totalCal.totalItemDiscount
            });

            setOrderDetail(response.data);
            setOldMessage(response.data.OrderMessages);
        } catch (err) {
            Swal.fire(err.message, '', 'error')
        }
    };
    useEffect(() => {
        const getStatusList = async (id) => {
            try {
                const response = await Services.orderStatusList();
                if (response.success) {
                    setOrderStatusList(response.data);
                }
            } catch (error) {
                Swal.fire(error.message, '', 'error');
            }
        }
        if (firstTime) {
            getStatusList();
        }
        setFirstTime(false);
        if (id > 0) {
            getOrders(id);
        }
    }, [id, updateStatus, triggerMain])

    const handleSearch = (event) => {
        const query = event.target.value;
    };

    function onSubmit(data) {
        const orderUpdateData = {
            delivery_charge: (data.delivery_charge) ? data.delivery_charge : 0,
            total_amount: orderCalculation.total_amount,
            subtotal: orderCalculation.subTotal,
            discount: 0,
            order_statusId: slectedStatus,
            //"payment_status": 1,
            add_full_name: data.add_full_name,
            add_pincode: data.add_pincode,
            add_phone_number: data.add_phone_number,
            add_state: data.add_state,
            add_city: data.add_city,
            add_hno_buildname: data.add_hno_buildname,
            add_road_area_colony: data.add_road_area_colony,
            add_address_type: orderDetail.add_address_type,
        }
        const loginUserDetail = JSON.parse(localStorage.getItem("isUser"));
        const messageData = {
            order_id: orderDetail.id,
            edited_by: loginUserDetail.user,
            message: data.message
        };

        const createRow = async () => {
            let createRowRes = "";
            if (id > 0) {
                createRowRes = await Services.updateStoreOrder(id, orderUpdateData);
            } else {
                //createRowRes = await Services.addSellerProduct(saveData)
            }

            if (createRowRes.success) {
                const msgRes = await Services.createUpdateOrderMsg(messageData);
                setUpdateStatus(GenerateUniqueVal())
                if (id < 1) {

                    //reset();
                }
                setValue('message', '')
                Swal.fire(createRowRes.message, '', 'success');
            } else {
                Swal.fire(createRowRes.message, '', 'error');
            }
        }
        createRow();
    }

    const handlePriceChange = (e) => {
        const getData = getValues();
        let totalAmt = itemsTotal.totalAmt;
        // if (getData.discount > 0) {
        //     totalAmt = totalAmt - (totalAmt * (getData.discount) / 100);
        // }
        if (getData.delivery_charge > 0) {
            totalAmt = parseFloat(totalAmt) + parseFloat(getData.delivery_charge);
        }
        setOrderCalculation({
            ...orderCalculation,
            total_amount: (totalAmt > 0) ? totalAmt.toFixed(2) : 0,
            delivery_charge: (getData.delivery_charge > 0) ? getData.delivery_charge : 0.00
        });
        //setValue('total_amount', (totalAmt > 0) ? totalAmt.toFixed(2) : 0);
    }
    const chengeOrderStatus = (e) => {
        setSelectedStatus(e.target.value);
        setTriggerAdd()
    }

    const triggerAddEvent = (status) => {
        setTriggerAdd(status)
    }
    const handleTrigger = (data) => {
        setTriggerMain(data);
    }

    /************************  Modal Manage **************************** */
    const [open, setOpen] = useState(false);
    const modalOpen = (data) => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const read_only = (role == 2) ? true : false;
    return (
        <>
            <Box>
                {open && (
                    <SellerInvoice open={open} onClose={handleClose} orderDetail={orderDetail} itemsTotal={itemsTotal} />
                )}
            </Box>
            <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
                <Card sx={{ minWidth: "100%", marginBottom: "10px" }}>
                    <Stack direction="row" spacing={2} className="">
                        <Typography
                            gutterBottom
                            variant='h5'
                            component='div'
                            sx={{ padding: '20px' }}
                        >
                            {(id > 0) ? "Edit Order" : "Add New Order"}
                        </Typography>
                    </Stack>
                </Card>

                <Card sx={{ minWidth: "100%", marginBottom: "10px" }}>
                    <OrderItems triggerMain={handleTrigger} itemList={storeItems} currentOrdStatus={currentOrdStatus} itemsTotal={itemsTotal} />
                    {(orderDetail.order_statusId == 4 && role == 2) && (
                        <Grid container spacing={2} sx={{ marginTop: "20px", marginBottom: "20px" }}>
                            <Grid item xs={0} lg={9}></Grid>
                            <Grid item xs={12} lg={3}>
                                <Button variant="outlined" startIcon={<PictureAsPdfIcon />} onClick={modalOpen}>Customer Invoice</Button>
                            </Grid>
                        </Grid>
                    )}
                </Card>

                {(role != 2) && (
                    <>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={1} >
                                <Grid item lg={9} xs={12} p={2}>
                                    <Card sx={{ minWidth: "100%", marginBottom: "10px", p: 2 }}>
                                        <Grid container spacing={2}>

                                            <Grid item lg={3} xs={12}>
                                                <Controller
                                                    name="order_number"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={validationRules}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            placeholder="Enter Order Number"
                                                            label="Order Number"
                                                            size="small"
                                                            InputProps={{
                                                                readOnly: true
                                                            }}
                                                            fullWidth
                                                            error={!!errors.order_number}
                                                            helperText={errors.order_number && errors.order_number.message}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={3}>
                                                <FormControl sx={{ minWidth: "100%" }} size="small">
                                                    <InputLabel id="demo-simple-select-autowidth-label">Select Order Status</InputLabel>
                                                    <Controller
                                                        name="order_status"
                                                        control={control}
                                                        defaultValue={slectedStatus}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                label="Select Order Status"
                                                                InputProps={{
                                                                    readOnly: read_only
                                                                }}
                                                                value={slectedStatus}
                                                                onChange={chengeOrderStatus}
                                                            >
                                                                {orderStatusList && orderStatusList.map((item) => (
                                                                    <MenuItem value={item.status_id}>{item.order_status_title}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={3} xs={12}>
                                                <Controller
                                                    name="delivery_charge"
                                                    control={control}
                                                    defaultValue="0"
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            placeholder="Delivery Charge"
                                                            label="Delivery Charge"
                                                            size="small"
                                                            type="number"
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <CurrencyRupeeIcon />
                                                                    </InputAdornment>
                                                                ),
                                                                readOnly: read_only,
                                                                inputProps: {
                                                                    min: 0,   // Set the minimum value
                                                                    step: "any"   // Set the step value
                                                                },
                                                                onChange: (e) => {
                                                                    field.onChange(e); // Use React Hook Form's field.onChange function
                                                                    handlePriceChange(e); // Call your custom handler here if needed
                                                                }
                                                            }}
                                                            fullWidth
                                                            error={!!errors.delivery_charge}
                                                            helperText={errors.delivery_charge && errors.delivery_charge.message}
                                                        />
                                                    )}
                                                />

                                            </Grid>
                                            {(orderDetail.order_statusId == 4 && role == 2) && (
                                                <Grid item xs={12} lg={3}>
                                                    <Button variant="outlined" startIcon={<PictureAsPdfIcon />} onClick={modalOpen}>Customer Invoice</Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Card>
                                    {(role != 2) && (
                                        <>
                                            <Card sx={{ minWidth: "100%", marginBottom: "10px", p: 2 }}>
                                                <Typography
                                                    gutterBottom
                                                    variant='h6'
                                                    component='div'
                                                >
                                                    Customer Details
                                                </Typography>
                                                <Divider />
                                                <Grid container spacing={2} sx={{ paddingTop: '15px' }}>
                                                    <Grid item lg={4} xs={12}>
                                                        <Controller
                                                            name="customer"
                                                            control={control}
                                                            defaultValue=""
                                                            rules={validationRules}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    placeholder="Enter Customer Name"
                                                                    label="Customer Name"
                                                                    size="small"
                                                                    InputProps={{
                                                                        readOnly: true,
                                                                    }}
                                                                    fullWidth
                                                                    error={!!errors.customer}
                                                                    helperText={errors.customer && errors.customer.message}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={4} xs={12}>
                                                        <Controller
                                                            name="c_m_no"
                                                            control={control}
                                                            defaultValue=""
                                                            rules={validationRules}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    placeholder="Enter Customer Mobile Number"
                                                                    label="Customer Mobile Number"
                                                                    size="small"
                                                                    InputProps={{
                                                                        readOnly: true,
                                                                    }}
                                                                    fullWidth
                                                                    error={!!errors.c_m_no}
                                                                    helperText={errors.c_m_no && errors.c_m_no.message}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={4} xs={12}>
                                                        <Controller
                                                            name="email"
                                                            control={control}
                                                            defaultValue=""
                                                            rules={validationRules}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    placeholder="Enter Customer Email"
                                                                    label="Customer Email"
                                                                    size="small"
                                                                    InputProps={{
                                                                        readOnly: true,
                                                                    }}
                                                                    fullWidth
                                                                    error={!!errors.email}
                                                                    helperText={errors.email && errors.email.message}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                            <Card sx={{ minWidth: "100%", marginBottom: "10px", p: 2 }}>
                                                <Typography
                                                    gutterBottom
                                                    variant='h6'
                                                    component='div'
                                                >
                                                    Customer Delivery Address
                                                </Typography>
                                                <Divider />
                                                <Grid container spacing={2} sx={{ paddingTop: '15px' }}>
                                                    <Grid item lg={4} xs={12}>
                                                        <Controller
                                                            name="add_full_name"
                                                            control={control}
                                                            defaultValue=""
                                                            rules={validationRules}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    placeholder="Enter Full Name"
                                                                    label="Full Name"
                                                                    size="small"
                                                                    InputProps={{
                                                                        readOnly: read_only,
                                                                    }}
                                                                    fullWidth
                                                                    error={!!errors.add_full_name}
                                                                    helperText={errors.add_full_name && errors.add_full_name.message}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={4} xs={12}>
                                                        <Controller
                                                            name="add_phone_number"
                                                            control={control}
                                                            defaultValue=""
                                                            rules={validationRules}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    placeholder="Enter Phone Number"
                                                                    label="Phone Number"
                                                                    size="small"
                                                                    InputProps={{
                                                                        readOnly: read_only,
                                                                    }}
                                                                    fullWidth
                                                                    error={!!errors.add_phone_number}
                                                                    helperText={errors.add_phone_number && errors.add_phone_number.message}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={4} xs={12}>

                                                    </Grid>
                                                    <Grid item lg={6} xs={12}>
                                                        <Controller
                                                            name="add_hno_buildname"
                                                            control={control}
                                                            defaultValue=""
                                                            rules={validationRules}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    placeholder="Enter House Number & Building Name"
                                                                    label="House Number & Building Name"
                                                                    size="small"
                                                                    InputProps={{
                                                                        readOnly: read_only,
                                                                    }}
                                                                    fullWidth
                                                                    error={!!errors.add_hno_buildname}
                                                                    helperText={errors.add_hno_buildname && errors.add_hno_buildname.message}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={6} xs={12}>
                                                        <Controller
                                                            name="add_road_area_colony"
                                                            control={control}
                                                            defaultValue=""
                                                            rules={validationRules}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    placeholder="Enter Road Name, Area name & Colony Name"
                                                                    label="Road Name, Area name & Colony Name"
                                                                    size="small"
                                                                    InputProps={{
                                                                        readOnly: read_only,
                                                                    }}
                                                                    fullWidth
                                                                    error={!!errors.add_road_area_colony}
                                                                    helperText={errors.add_road_area_colony && errors.add_road_area_colony.message}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={4} xs={6}>
                                                        <Controller
                                                            name="add_state"
                                                            control={control}
                                                            defaultValue=""
                                                            rules={validationRules}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    placeholder="Enter State"
                                                                    label="State"
                                                                    size="small"
                                                                    InputProps={{
                                                                        readOnly: read_only,
                                                                    }}
                                                                    fullWidth
                                                                    error={!!errors.add_state}
                                                                    helperText={errors.add_state && errors.add_state.message}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={4} xs={6}>
                                                        <Controller
                                                            name="add_city"
                                                            control={control}
                                                            defaultValue=""
                                                            rules={validationRules}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    placeholder="Enter City"
                                                                    label="City"
                                                                    size="small"
                                                                    InputProps={{
                                                                        readOnly: read_only,
                                                                    }}
                                                                    fullWidth
                                                                    error={!!errors.add_city}
                                                                    helperText={errors.add_city && errors.add_city.message}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={4} xs={12}>
                                                        <Controller
                                                            name="add_pincode"
                                                            control={control}
                                                            defaultValue=""
                                                            rules={validationRules}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    placeholder="Enter Pincode"
                                                                    label="Pincode"
                                                                    size="small"
                                                                    InputProps={{
                                                                        readOnly: read_only,
                                                                    }}
                                                                    fullWidth
                                                                    error={!!errors.add_pincode}
                                                                    helperText={errors.add_pincode && errors.add_pincode.message}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Card>

                                            <Card sx={{ minWidth: "100%", marginBottom: "10px", p: 2 }}>
                                                <Typography
                                                    gutterBottom
                                                    variant='h6'
                                                    component='div'
                                                >
                                                    Order Related Message
                                                </Typography>
                                                <Divider />
                                                <Grid container spacing={2} sx={{ paddingTop: '15px' }}>
                                                    {oldMessage && oldMessage.map((rowMsg, index) => (
                                                        <Grid item lg={12} xs={12}>
                                                            <Typography>
                                                                {index + 1}. {rowMsg.message}
                                                            </Typography>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Card>
                                        </>
                                    )}
                                </Grid>
                                <Grid item lg={3} xs={12} >
                                    <Grid container spacing={0} >
                                        <Grid item lg={12}>
                                            <Card sx={{ minWidth: "100%", marginBottom: "10px", p: 2 }}>
                                                <Grid container spacing={2} sx={{ paddingTop: '15px' }}>
                                                    <Grid item lg={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item lg={12} >
                                                                <Grid container spacing={2}>
                                                                    <Grid item lg={7} xs={7}>
                                                                        <Typography variant="body1" sx={{ fontSize: "12px" }}>
                                                                            Sub Total
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item lg={1} xs={1} sx={{ fontSize: "12px" }}>:</Grid>
                                                                    <Grid item lg={3} xs={3}>
                                                                        <Typography variant="body1" sx={{ fontSize: "12px" }}>
                                                                            {formatCurrency(orderCalculation.subTotal)}
                                                                        </Typography>
                                                                    </Grid>

                                                                </Grid>
                                                            </Grid>
                                                            <Grid item lg={12}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item lg={7} xs={7}>
                                                                        <Typography variant="body1" sx={{ fontSize: "12px" }}>
                                                                            Discount Amount (-)
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item lg={1} xs={1} sx={{ fontSize: "12px" }}>:</Grid>
                                                                    <Grid item lg={3} xs={3}>
                                                                        <Typography variant="body1" sx={{ fontSize: "12px" }}>
                                                                            {formatCurrency(orderCalculation.total_item_discount)}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item lg={12}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item lg={7} xs={7}>
                                                                        <Typography variant="body1" sx={{ fontSize: "12px" }}>
                                                                            Tax Amount (+)
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item lg={1} xs={1} sx={{ fontSize: "12px" }}>:</Grid>
                                                                    <Grid item lg={3} xs={3}>
                                                                        <Typography variant="body1" sx={{ fontSize: "12px" }}>
                                                                            {formatCurrency(orderCalculation.tax)}
                                                                        </Typography>
                                                                    </Grid>

                                                                </Grid>
                                                            </Grid>
                                                            <Grid item lg={12}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item lg={7} xs={7}>
                                                                        <Typography variant="body1" sx={{ fontSize: "12px" }}>
                                                                            Delivery Charge (+)
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item lg={1} xs={1} sx={{ fontSize: "12px" }}>:</Grid>
                                                                    <Grid item lg={3} xs={3}>
                                                                        <Typography variant="body1" sx={{ fontSize: "12px" }}>
                                                                            {formatCurrency(orderCalculation.delivery_charge)}
                                                                        </Typography>
                                                                    </Grid>

                                                                </Grid>
                                                            </Grid>

                                                            <Grid item lg={12} xs={12}><Divider /></Grid>
                                                            <Grid item lg={12}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item lg={7} xs={6}>
                                                                        <Typography variant="body1" sx={{ fontSize: "13px", fontWeight: 600, color: '#5e5d5d' }}>
                                                                            Total Amount
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item lg={1} xs={1} sx={{ fontSize: "12px" }}>:</Grid>
                                                                    <Grid item lg={3} xs={3}>
                                                                        <Typography variant="body1" sx={{ fontSize: "13px", fontWeight: 600, color: '#5e5d5d' }}>
                                                                            {formatCurrency(orderCalculation.total_amount)}
                                                                        </Typography>
                                                                    </Grid>

                                                                </Grid>
                                                            </Grid>
                                                            <Grid item lg={12} xs={12}><Divider /></Grid>
                                                            <Grid item lg={12}>
                                                                <Chip variant="outlined" sx={{ width: '100%', fontSize: '1rem', backgroundColor: "#b0e6a3", color: "#000000", fontWeight: 500 }} label={`Total Earning   :    ${formatCurrency(orderCalculation.profit_amount)}`} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                            </Card>
                                        </Grid>
                                        <Grid item lg={12}>
                                            <Card sx={{ minWidth: "100%", p: 2 }}>
                                                <Grid container spacing={2} sx={{ paddingTop: '15px' }}>
                                                    {/* <Grid item lg={6} xs={6}>
                                                <Controller
                                                    name="discount"
                                                    control={control}
                                                    defaultValue="0"
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            placeholder="Enter Discount (%)"
                                                            label="Discount (%)"
                                                            size="small"
                                                            sx={{ fontSize: "13px" }}
                                                            type="number"
                                                            InputProps={{
                                                                readOnly: read_only,
                                                                inputProps: {
                                                                    min: 0,   // Set the minimum value
                                                                    step: "any"   // Set the step value
                                                                },
                                                                onChange: (e) => {
                                                                    field.onChange(e); // Use React Hook Form's field.onChange function
                                                                    handlePriceChange(e); // Call your custom handler here if needed
                                                                }
                                                            }}
                                                            fullWidth
                                                            error={!!errors.discount}
                                                            helperText={errors.discount && errors.discount.message}
                                                        />
                                                    )}
                                                />

                                            </Grid> */}

                                                    {(role != 2) && (
                                                        <>
                                                            <Grid item lg={12}>
                                                                <Controller
                                                                    name="message"
                                                                    control={control}
                                                                    defaultValue=""
                                                                    rules={validationRules}
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            {...field}
                                                                            placeholder="Enter Update Related Message"
                                                                            label="Enter Update Related Message"
                                                                            multiline
                                                                            rows={2}
                                                                            fullWidth
                                                                            error={!!errors.message}
                                                                            helperText={errors.message && errors.message.message}
                                                                        />
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item lg={12} xs={12}>
                                                                {(currentOrdStatus > 3) ? (
                                                                    <Button variant="contained" sx={{ width: "100%" }} disabled>Save</Button>
                                                                ) : (
                                                                    <Button variant="contained" sx={{ width: "100%" }} type="submit">Save</Button>
                                                                )}
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>
                                            </Card>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </form >
                        <Grid container spacing={1} >
                            <Grid item lg={9} xs={12} p={2}>
                                {(slectedStatus === 4) && (
                                    <OrderTransaction currentTrigger={triggerAdd} triggerBody={triggerAddEvent} totalAmt={orderDetail.total_amount} orderId={orderDetail.id} />
                                )}
                            </Grid>
                        </Grid>
                    </>
                )}

            </Box >
        </>
    )
}
