import { Close } from '@mui/icons-material';
import { Box, Card, Dialog, DialogContent, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';

const ViewProfile = ({customerDetails}) => {
    const [dialogOpen,setDialogOpen] = useState(true)
  return (
    <Dialog fullWidth open={dialogOpen}>
        <Card sx={{display:'flex',justifyContent:'space-between',alignItems:'center',p:'1% 2%'}}>
            <Typography variant={'h5'}>Customer Profile</Typography>
            <IconButton size='small' color='error' onClick={()=>setDialogOpen(false)}><Close/></IconButton>
        </Card>
      <DialogContent>

      </DialogContent>
    </Dialog>
  );
}

export default ViewProfile;
