import React, { useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { isMobile } from 'react-device-detect';
import { Box, Container, Divider, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import logo from "../../../assets/images/b2b.svg";
import { formatCurrency } from '../../../utils/currencyFormatter';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { formatDateTime } from '../../../utils/dateUtils';


const SellerInvoice = ({ open, onClose, orderDetail, itemsTotal }) => {
    const [items, setItems] = useState([])
    useEffect(() => {
        setItems(orderDetail.store_order_items);
    }, [orderDetail])
    const pdfRef = useRef();
    const DownloadPdf = () => {
        const input = pdfRef.current;
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4', true);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
            const imgx = (pdfWidth - imgWidth * ratio) / 2;
            const imgy = 30;
            pdf.addImage(imgData, 'PNG', imgx, imgy, imgWidth * ratio, imgHeight * ratio);
            pdf.save('invoice.pdf');
        })
    }

    return (
        <div>
            <Dialog
                onClose={onClose}
                open={open}
                maxWidth="lg"
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" >
                    INVOICE
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers sx={{ minWidth: '800px', minHeight: '500px' }}>
                    <Box ref={pdfRef} p={3}>
                        <Grid container spacing={2}>
                            <Grid item lg={9}>
                                <Stack direction="column" spacing={0}>
                                    <Typography variant='h6' sx={{ fontSize: "1.2rem", fontWeight: "600" }}>
                                        Bharat 2 Bharat
                                    </Typography>
                                    <Typography variant='body' sx={{ fontSize: ".8rem", fontWeight: "400" }}>
                                        GSTIN  <strong>24234124123113</strong>
                                    </Typography>
                                    <Typography variant='body' sx={{ fontSize: ".8rem", fontWeight: "400" }}>
                                        Kutumbh Care Pvt Ltd, B 154, B Block, <br />
                                        Sector 63, Noida, Uttar Pradesh 201301
                                    </Typography>
                                    <Typography variant='body' sx={{ fontSize: ".8rem", fontWeight: "400" }}>
                                        Mobile 99999999999
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item lg={3}>
                                <img src={logo} style={{ width: '200px' }} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={1}>
                            <Grid item lg={4}>
                                <Typography variant='body' sx={{ fontSize: "1rem" }}>
                                    Invoice #: <strong>INV#{orderDetail.order_number}</strong>
                                </Typography>
                            </Grid>
                            <Grid item lg={4}>
                                <Typography variant='body' sx={{ fontSize: "1rem" }}>
                                    Invoice Date: <strong>{formatDateTime(orderDetail.createdAt, 'date')}</strong>
                                </Typography>
                            </Grid>
                            <Grid item lg={4}></Grid>
                        </Grid>

                        <Grid container spacing={2} mt={1}>
                            <Grid item lg={4}>
                                {(items.length > 0) && (
                                    <Stack direction="column" spacing={0}>
                                        <Typography variant='body' sx={{ fontSize: ".8rem" }}>
                                            Sold By:
                                        </Typography>
                                        <Typography variant='body' sx={{ fontSize: ".9rem", fontWeight: "600" }}>
                                            {orderDetail.store_order_items[0].user.first_name} {items[0].user.last_name}
                                        </Typography>
                                        <Typography variant='body' sx={{ fontSize: ".9rem", fontWeight: "600" }}>
                                            GSTIN: {items[0].user.gstIn}
                                        </Typography>
                                        <Typography variant='body' sx={{ fontSize: ".9rem", fontWeight: "600" }}>
                                            Ph: {items[0].user.mobile_number}
                                        </Typography>
                                        <Typography variant='body' sx={{ fontSize: ".9rem", fontWeight: "400" }}>
                                            {items[0].user.user_address.home_building_no}, {items[0].user.user_address.landmark}<br />
                                            {items[0].user.user_address.city}, {items[0].user.user_address.district}<br />
                                            {items[0].user.user_address.state}, {items[0].user.user_address.postal_code}
                                        </Typography>
                                    </Stack>
                                )}
                            </Grid>
                            <Grid item lg={4}>
                                <Stack direction="column" spacing={0}>
                                    <Typography variant='body' sx={{ fontSize: ".8rem" }}>
                                        Bill To:
                                    </Typography>
                                    <Typography variant='body' sx={{ fontSize: ".9rem", fontWeight: "600" }}>
                                        {orderDetail.add_full_name}
                                    </Typography>
                                    <Typography variant='body' sx={{ fontSize: ".9rem", fontWeight: "600" }}>
                                        Mobile Number: {orderDetail.add_phone_number}
                                    </Typography>
                                    <Typography variant='body' sx={{ fontSize: ".9rem", fontWeight: "400" }}>
                                        {orderDetail.add_hno_buildname}<br />
                                        {orderDetail.add_road_area_colony}<br />
                                        {orderDetail.add_city}, {orderDetail.add_state}, {orderDetail.add_pincode}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item lg={4}>
                                <Stack direction="column" spacing={0}>
                                    <Typography variant='body' sx={{ fontSize: ".8rem" }}>
                                        Ship To:
                                    </Typography>
                                    <Typography variant='body' sx={{ fontSize: ".9rem", fontWeight: "600" }}>
                                        {orderDetail.add_full_name}
                                    </Typography>
                                    <Typography variant='body' sx={{ fontSize: ".9rem", fontWeight: "600" }}>
                                        Mobile Number: {orderDetail.add_phone_number}
                                    </Typography>
                                    <Typography variant='body' sx={{ fontSize: ".9rem", fontWeight: "400" }}>
                                        {orderDetail.add_hno_buildname}<br />
                                        {orderDetail.add_road_area_colony}<br />
                                        {orderDetail.add_city}, {orderDetail.add_state}, {orderDetail.add_pincode}
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={1}>
                            <Grid item lg={12}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650, fontSize: '12px' }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>#</TableCell>
                                                <TableCell align="left" style={{ width: '250px' }}>Item</TableCell>
                                                <TableCell align="left">Rate</TableCell>
                                                <TableCell align="left">Qty</TableCell>
                                                <TableCell align="left">SubTotal</TableCell>
                                                <TableCell align="left">Disc(%)</TableCell>
                                                <TableCell align="left">GST(%)</TableCell>
                                                <TableCell align="left">Tax Amount</TableCell>
                                                <TableCell align="left">Amount</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {items && items.map((row, index) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell align="left">{row.store_product.product_master.title}</TableCell>
                                                    <TableCell align="left">{row.unit_price}</TableCell>
                                                    <TableCell align="left">{row.quantity}</TableCell>
                                                    <TableCell align="left">{row.subtotal}</TableCell>
                                                    <TableCell align="left">{row.discount}</TableCell>
                                                    <TableCell align="left">{row.store_product.product_master.cgst * 2}</TableCell>
                                                    <TableCell align="left">{row.tax}</TableCell>
                                                    <TableCell align="left">{row.total_amount}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item lg={12}>
                                <Box>
                                    <Grid container spacing={2}>
                                        <Grid item lg={9} xs={0}></Grid>
                                        <Grid item lg={3} xs={12} sx={{ textAlign: "right" }}>
                                            <Grid container spacing={2}>
                                                <Grid item lg={7} xs={7}>
                                                    <Typography variant="body1" sx={{ fontSize: "13px" }}>
                                                        Sub Total
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={1} xs={1} sx={{ fontSize: "13px" }}>:</Grid>
                                                <Grid item lg={3} xs={3}>
                                                    <Typography variant="body1" sx={{ fontSize: "13px" }}>
                                                        {formatCurrency(itemsTotal.subTotal)}
                                                    </Typography>
                                                </Grid>

                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item lg={7} xs={7}>
                                                    <Typography variant="body1" sx={{ fontSize: "13px" }}>
                                                        Total Discount
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={1} xs={1} sx={{ fontSize: "13px" }}>:</Grid>
                                                <Grid item lg={3} xs={3}>
                                                    <Typography variant="body1" sx={{ fontSize: "13px" }}>
                                                        {formatCurrency(itemsTotal.totalItemDiscount)}
                                                    </Typography>
                                                </Grid>

                                            </Grid>
                                            {/* <Grid container spacing={2}>
                                                <Grid item lg={7} xs={7}>
                                                    <Typography variant="body1" sx={{ fontSize: "13px" }}>
                                                        Delivery Charge
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={1} xs={1} sx={{ fontSize: "13px" }}>:</Grid>
                                                <Grid item lg={3} xs={3}>
                                                    <Typography variant="body1" sx={{ fontSize: "13px" }}>
                                                        {formatCurrency(orderDetail.delivery_charge)}
                                                    </Typography>
                                                </Grid>
                                            </Grid> */}
                                            <Grid container spacing={2}>
                                                <Grid item lg={7} xs={7}>
                                                    <Typography variant="body1" sx={{ fontSize: "13px" }}>
                                                        Total Tax
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={1} xs={1} sx={{ fontSize: "13px" }}>:</Grid>
                                                <Grid item lg={3} xs={3}>
                                                    <Typography variant="body1" sx={{ fontSize: "13px" }}>
                                                        {(itemsTotal.totalTax > 0) ? formatCurrency(itemsTotal.totalTax) : formatCurrency(0.00)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item lg={12} xs={12}><Divider /></Grid>
                                            <Grid container spacing={2}>
                                                <Grid item lg={7} xs={7}>
                                                    <Typography variant="body1" sx={{ fontSize: "13px" }}>
                                                        Total Order Amount
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={1} xs={1} sx={{ fontSize: "13px" }}>:</Grid>
                                                <Grid item lg={3} xs={3}>
                                                    <Typography variant="body1" sx={{ fontSize: "13px" }}>
                                                        {formatCurrency(itemsTotal.totalAmt)}
                                                    </Typography>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus variant="outlined" startIcon={<PictureAsPdfIcon />} onClick={DownloadPdf}>
                        Download Invoice
                    </Button>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default SellerInvoice;