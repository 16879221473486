import React, { useContext, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  Typography,
  Grid,
  Box,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  ListItemText,
  OutlinedInput,
  Autocomplete,
  Chip,
  Divider,
} from '@mui/material';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { DesktopDatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import BookingResult from './BookingResult';
import { BookingService } from './Service';
import { CustomerService } from '../Customer/Service';
import ServiceArea from './ServiceAreaData.json'
import { BookingDetailContext } from './BookingInfo';

export default function BookingDetails() {
  const {bookingDataInitialState} = useContext(BookingDetailContext);
  
  const { control, handleSubmit, setValue, formState: { errors ,isDirty},watch,reset } = useForm({
    mode: "onChange",
    // defaultValues:{
    //   paymentMode:'pay after service',
    //   bookingStartTime: dayjs().add(1, 'hour').format('HH:mm'), // Set default time as one hour from now
    //   state:'Uttar Pradesh'
    // }
    defaultValues:bookingDataInitialState
  });
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (personalDetails) {
  //     setValue('firstName', personalDetails.firstName);
  //     setValue('middleName', personalDetails.middleName);
  //     setValue('lastName', personalDetails.lastName);
  //     setValue('email', personalDetails.email);
  //     setValue('dob', personalDetails.dob);
  //     setValue('gender', personalDetails.gender);
  //     setValue('mobileNumber', personalDetails.mobileNumber);
  //   }
  // }, [personalDetails]);

  const [bookingResponseDetails,setBookingResponseDetails] = useState({
    status:'',
    message:'',
    data:''
  })
 
  const onSubmit = async(data) => {
    console.log("submitted booking form data:",data);
    const items = data.services.map((service)=>{
      return {
        providerServiceId: service.id,
        // providerServiceId: data.serviceExpert,//there is error if providerid is 48
        serviceName: service.title,
        price: '0.00'
      }
    });
    const formattedDatatobeSend = {
      customerId: data.customer.id,
      providerId: data.serviceExpert,
      paymentId: 1,
      serviceDate: data.serviceDate,
      bookingStartTime:dayjs(data.bookingStartTime, "HH:mm").format("HH:mm:ss"),
      subTotal: '0.00',
      couponId: '0',
      couponAmount: '0.00',
      discount: '0.00',
      platformCharges: '0.00',
      total: '0.00',  
      callbackRequest: true,
      add_customerFullName: `${data.customer.profile.firstName} ${data.customer.profile.middleName} ${data.customer.profile.lastName}`,
      add_mobileNumber: data.customer.mobileNumber,
      add_pincode: data.serviceArea.pincode,
      add_latitude: '',
      add_longitude: '',
      add_city: data.serviceArea.city,
      add_state: data.serviceArea.state,
      add_landmark: data.landmark,
      add_address:data.address ,
      items: items,
      // [
      //     {            
      //         providerServiceId: '1',
      //         serviceName: 'asdfad',
      //         price: '300'
      //     },
      //     {
      //         providerServiceId: '2',
      //         serviceName: 'service 3',
      //         price: '200'
      //     }
      // ] 
    }
   console.log("data to be send for booking:",formattedDatatobeSend);
    try {
        const bookingResponse = await BookingService.addBooking(formattedDatatobeSend);
        console.log("new booking Response:",bookingResponse);
        if(bookingResponse.status=='success'){
          setBookingResponseDetails({status:'true',message:'booking successfully',data:bookingResponse.data})
        }else{
          setBookingResponseDetails({status:'false',message:'booking failed',data:''})
        }
    } catch (error) {
      
    }
  };

  const hours = Array.from({ length: 12 }, (_, i) => i.toString().padStart(2, '0'));
  const minutesSeconds = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'));

  const validatebookingStartTime = (value) => {
    const serviceDate = watch('serviceDate');
    if (!serviceDate) return "Please select a booking date first";

    const selectedDate = dayjs(serviceDate, "DD-MM-YYYY");
    const selectedTime = dayjs(value, 'HH:mm');
    const now = dayjs();
    
    const startOfAllowedTime = dayjs().hour(9).minute(0);
    const endOfAllowedTime = dayjs().hour(21).minute(0);

    // Check if booking is for today
    if (selectedDate.isSame(now, 'day')) {
      const oneHourLater = now.add(1, 'hour');
      if (selectedTime.isBefore(oneHourLater)) {
        return 'Time must be at least one hour from now for today.';
      }
      if (selectedTime.isBefore(startOfAllowedTime) || selectedTime.isAfter(endOfAllowedTime)) {
        return 'Time must be between 9 AM and 9 PM.';
      }
    } else {
      // For future dates
      if (selectedTime.isBefore(startOfAllowedTime) || selectedTime.isAfter(endOfAllowedTime)) {
        return 'Time must be between 9 AM and 9 PM for future days.';
      }
    }

    return true;
  };

  const [customerList,setCustomerList] = useState([]);
  const [categoryList,setCategoryList] = useState([]);
  const [subCategoryList,setSubCategoryList] = useState([]);
  const [servicablePincodeList,setServicablePincodeList] = useState([]);
  const [serviceList,setServiceList] = useState([]);
  const [serviceExpertList,setServiceExpertList]=useState([]);
  useEffect(()=>{
    const fetchCusomerlist = async()=>{
      try {
        const customerListResponse = await CustomerService.unsortedCustomerList();
        console.log("customer list Response:",customerListResponse);
        if(customerListResponse.success===1){
          setCustomerList(customerListResponse.data.customers)
        }
      } catch (error) {
        
      }
    }
    const fetchCategoryList= async()=>{
      try {
        const categoryListResponse = await BookingService.categoryList();
        console.log("category list Response:",categoryListResponse);
        if(categoryListResponse.success===1){
          setCategoryList(categoryListResponse.data)
        }
      } catch (error) {
        
      }
    }
    const fetchServicablePincodes= async()=>{
      try {
        const servicablePincodeListResponse = await BookingService.servicablePincodeList();
        console.log("servicable pincodes list Response:",servicablePincodeListResponse);
        if(servicablePincodeListResponse.success===1){
          setServicablePincodeList(servicablePincodeListResponse.data)
        }
      } catch (error) {
        
      }
    }
    
    fetchCusomerlist();
    fetchCategoryList();
    fetchServicablePincodes();
  },[])
  const categoryId= watch('categoryId');
  useEffect(()=>{
    if(categoryId){
      
      const fetchSubCategoryList= async()=>{
        try {
          const subCategoryListResponse = await BookingService.subCategoryList({categoryId:categoryId});
          console.log("sub category list Response:",subCategoryListResponse);
          if(subCategoryListResponse.success===1){
            setSubCategoryList(subCategoryListResponse.data)
          }
        } catch (error) {
          
        }
      }
      fetchSubCategoryList();
    }
  },[categoryId])

  const serviceArea = watch('serviceArea');
  useEffect(()=>{
    if(serviceArea){
      
      const fetchServiceExpertList= async()=>{
        try {
          const serviceExpertListResponse = await BookingService.serviceExpertList({pincode:serviceArea.pincode});
          console.log("service expert list Response:",serviceExpertListResponse);
          if(serviceExpertListResponse.success===1){
            setServiceExpertList(serviceExpertListResponse.data)
          }
        } catch (error) {
          
        }
      }
      fetchServiceExpertList();
    }
  },[serviceArea])

  const subCategoryId=watch('subCategoryId');
  const serviceExpert = watch('serviceExpert');
  useEffect(()=>{
    if(subCategoryId && serviceExpert){
      console.log(serviceExpert)
      const fetchServiceList= async()=>{
        try {
          const serviceListResponse = await BookingService.serviceList({subCategoryId:subCategoryId,providerId:serviceExpert});
          console.log("service list Response:",serviceListResponse);
          if(serviceListResponse.success===1){
            setServiceList(serviceListResponse.data)
          }
        } catch (error) {
          
        }
      }
      fetchServiceList();
    }
  },[subCategoryId,serviceExpert])
  /*..........related to customer list autocomplete................ */

  const getFullName = (profile) => {
    if (!profile) return '';
    return [profile.firstName, profile.middleName, profile.lastName]
      .filter(Boolean)
      .join(' ');
  };
  const [options, setOptions] = useState([]);

  const handleInputChange = (event, newInputValue) => {
    // Filter and sort customers based on input value
    const filteredOptions = customerList
      .filter(customer => {
        const fullName = getFullName(customer.profile).toLowerCase();
        const mobileNumber = customer.mobileNumber;
        const searchValue = newInputValue.toLowerCase();
        return fullName.includes(searchValue) || mobileNumber.includes(searchValue);
      })
      .sort((a, b) => {
        const aFullName = getFullName(a.profile).toLowerCase();
        const bFullName = getFullName(b.profile).toLowerCase();
        const aIndex = aFullName.indexOf(newInputValue.toLowerCase());
        const bIndex = bFullName.indexOf(newInputValue.toLowerCase());
        if (aIndex === bIndex) {
          return aFullName.localeCompare(bFullName);
        }
        return aIndex - bIndex;
      })
      .slice(0, 5);

    setOptions(filteredOptions);
  };
  const selectedState = watch('state');
  const [cityList,setCityList] = useState([]);
  useEffect(()=>{
    if(selectedState){
      const selectedArea = ServiceArea.find((item)=>item.state == selectedState);
      setCityList(selectedArea.cities)
    }
  },[selectedState])
  return (
    <Box sx={{ overflow: "auto", backgroundColor: "#fff" ,padding:{xs:'2%',md:'2% 0%'} }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} >
          <Grid item lg={9} xs={12} sx={{margin:'auto'}}>
            {/* first container....customer selection */}
            <Grid container spacing={2} >
              {/* ............left section................... */}
              <Grid item xs={12} lg={5}>
                <Grid container>
                  <Grid item xs={12} sx={{my:'1%'}}>
                    <Grid container sx={{mb:'1%'}}>
                      <Grid item lg={7} xs={12}>
                        <Typography sx={{fontWeight:'700'}}>Select Customer :</Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={12} xs={12}>
                        <Controller
                          name="customer"
                          control={control}
                          rules={{
                            required: 'Select customer',
                            validate: (value) => (value && Object.keys(value).length > 0) || 'Select customer'
                          }}
                          // defaultValue={null}  // Set a default value
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Autocomplete
                              options={customerList || []}  // Provide a fallback empty array
                              getOptionLabel={(option) => {
                                if (!option) return '';  // Handle null/undefined option
                                const profile = option.profile || {};  // Use empty object as fallback
                                const fullName = getFullName(profile);
                                return fullName ? `${fullName} (${option.mobileNumber || ''})` : '';
                              }}
                              value={value}
                              onChange={(event, newValue) => {
                                onChange(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size='small'
                                  variant="outlined"
                                  error={!!error}
                                  // helperText={error?.message}
                                />
                              )}
                              isOptionEqualToValue={(option, value) => {
                                if (!option || !value) return false;  // Handle null/undefined values
                                return option.id === value.id;
                              }}
                              noOptionsText="No customers found" // Custom text when no options match
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Divider sx={{my:'1%',borderBlockStyle:'dashed',borderBlockWidth:'1px'}}/>
            {/* second container */}
            <Grid container spacing={2} >
              {/* ............left section................... */}
              <Grid item xs={12} lg={5}>
                <Grid container>
                  <Grid item xs={12} sx={{my:'1%'}}>
                    <Grid container sx={{mb:'1%'}}>
                      <Grid item lg={7} xs={12}>
                        <Typography sx={{fontWeight:'700'}}>Select Category :</Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={12} xs={12}>
                        <Controller
                          name='categoryId'
                          control={control}
                          rules={{required:'select category'}}
                          render={({field,fieldState:{error}})=>(
                            <FormControl size='small' sx={{ minWidth: "100%" }}>
                              {/* <InputLabel id="">Select Gender</InputLabel> */}
                                <Select
                                    {...field}
                                    // label="Select Gender"
                                    // sx={{height:'2rem'}}
                                    error={!!error}
                                    // helperText={error && error?.message}
                                >
                                  { categoryList &&
                                    categoryList.map((category,index)=>(
                                      <MenuItem value={category.id} key={index}>{category.title}</MenuItem>
                                    ))

                                  }
                                </Select>
                            </FormControl>
                          )}  
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  
                </Grid>
              </Grid>
              {/* ............central gap................... */}
              <Grid item xs={'none'} lg={2}>
                
              </Grid>
              {/* ............right section................... */}
              <Grid item xs={12} lg={5}>
                <Grid container>
                  <Grid item xs={12} sx={{my:'1%'}}>
                    <Grid container sx={{mb:'1%'}}>
                      <Grid item lg={7} xs={12}>
                        <Typography sx={{fontWeight:'700'}}>Select Sub-Category :</Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={12} xs={12}>
                        <Controller
                          name='subCategoryId'
                          control={control}
                          rules={{required:'select sub-category'}}
                          render={({field,fieldState:{error}})=>(
                            <FormControl size='small' sx={{ minWidth: "100%" }}>
                              {/* <InputLabel id="">Select Gender</InputLabel> */}
                                <Select
                                    {...field}
                                    // label="Select Gender"
                                    // sx={{height:'2rem'}}
                                    error={!!error}
                                    // helperText={error && error?.message}
                                >
                                  {subCategoryList &&
                                    subCategoryList.map((subCategory,scIndex)=>(
                                      <MenuItem value={subCategory.id} key={scIndex}>{subCategory.title}</MenuItem>
                                    ))
                                  }
                                </Select>
                            </FormControl>
                          )}  
                        />
                      </Grid>
                    </Grid>
                  </Grid> 
                </Grid>
              </Grid>
              {/* ............right section ends................... */}
            </Grid>
            
            <Divider sx={{my:'1%',borderBlockStyle:'dashed',borderBlockWidth:'1px'}}/>
            {/*  third container */}
            <Grid container spacing={2} >
              {/* ............left section................... */}
              <Grid item xs={12} lg={5}>
                <Grid container>
                  <Grid item xs={12} sx={{my:'1%'}}>
                    <Grid container sx={{mb:'1%'}}>
                      <Grid item lg={7} xs={12}>
                        <Typography sx={{fontWeight:'700'}}>Enter Pincode :</Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={12} xs={12}>
                        <Controller
                          name='serviceArea'
                          control={control}
                          rules={{
                            required: "Pincode selection is required"
                          }}
                          render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
                            <Autocomplete
                              {...field}
                              options={servicablePincodeList}
                              value={value || null}
                              getOptionLabel={(option) => 
                                option ? `${option.pincode} - ${option.city}, ${option.state}` : ''
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  size='small'
                                  error={!!error}
                                  // helperText={error && error.message}
                                  placeholder="Select a pincode"
                                />
                              )}
                              onChange={(_, newValue) => onChange(newValue)}
                              isOptionEqualToValue={(option, value) => 
                                option && value ? option.pincode === value.pincode : option === value
                              }
                              disableClearable
                              noOptionsText="Service not avaialble" // Custom text when no options match
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sx={{my:'1%'}}>
                    <Grid container sx={{mb:'1%'}}>
                      <Grid item lg={7} xs={12}>
                        <Typography sx={{fontWeight:'700'}}>Select Service Expert :</Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={12} xs={12}>
                        <Controller
                          name='serviceExpert'
                          control={control}
                          rules={{required:'select service Expert'}}
                          render={({field,fieldState:{error}})=>(
                            <FormControl size='small' sx={{ minWidth: "100%" }}>
                                <Select
                                    {...field}
                                    error={!!error}
                                >
                                  {
                                    serviceExpertList.map((expert,exIndex)=>(
                                      <MenuItem value={expert.id} sx={{textTransform:'uppercase'}} key={exIndex}>{expert.providerName}</MenuItem>
                                    ))
                                  }
                                </Select>
                            </FormControl>
                          )}  
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* ............central gap................... */}
              <Grid item xs={'none'} lg={2}>
                
              </Grid>
              {/* ............right section................... */}
              <Grid item xs={12} lg={5}>
                <Grid container>
                  <Grid item xs={12} sx={{my:'1%'}}>
                    <Grid container sx={{mb:'1%'}}>
                      <Grid item lg={7} xs={12}>
                        <Typography sx={{fontWeight:'700'}}>Select Services :</Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={12} xs={12}>
                        <Controller
                          name='services'
                          control={control}
                          rules={{ required: 'Select service' }}
                          render={({ field, fieldState: { error } }) => (
                            <Autocomplete
                              {...field}
                              multiple
                              id="services-autocomplete"
                              options={serviceList}
                              getOptionLabel={(option) => option.title}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size='small'
                                  error={!!error}
                                  // helperText={error ? error.message : ''}
                                  variant="outlined"
                                  // label="Select Services"
                                  // placeholder="Services"
                                />
                              )}
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip
                                    key={option.id}
                                    variant="outlined"
                                    label={option.title}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              onChange={(_, newValue) => {
                                // Filter out duplicates based on id
                                const uniqueValues = newValue.filter((item, index, self) =>
                                  index === self.findIndex((t) => t.id === item.id)
                                );
                                field.onChange(uniqueValues);
                              }}
                              value={field.value || []}
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              noOptionsText="service not provided by selected expert" // Custom text when no options match
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* ............right section ends................... */}
            </Grid>

            <Divider sx={{my:'1%',borderBlockStyle:'dashed',borderBlockWidth:'1px'}}/>
            {/* fourth container.....date time selction section  */}

            <Grid container spacing={2} >
              {/* ............left section................... */}
              <Grid item xs={12} lg={5}>
                <Grid container>
                  <Grid item xs={12} sx={{my:'1%'}}>
                    <Grid container sx={{mb:'1%'}}>
                      <Grid item lg={7} xs={12}>
                        <Typography sx={{fontWeight:'700'}}>Select Date :</Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={12} xs={12}>
                        {/* <Controller
                            name="serviceDate"
                            control={control}
                            rules={{ 
                                // required: watch('serviceDate') ? "This field is required when drug Lic2 is provided" : false,
                                validate: (value) => {
                                  // if (!value) return true; // Skip validation if no value
                                  const date = dayjs(value, "DD-MM-YYYY", true);
                                  const today = dayjs().startOf('day'); // Get today's date without time
                                  return date.isValid() && (date.isSame(today, 'day') || date.isAfter(today)) || "Booking Date must be today or in the future";
                                }
                            }}
                            render={({ field: { onChange, value, ...restField }, fieldState: { error } }) => (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        // disabled={!watch('drugLic2')}
                                        {...restField}
                                        value={value ? dayjs(value, "DD-MM-YYYY") : null}
                                        onChange={(newValue) => {
                                            onChange(newValue ? newValue.format("DD-MM-YYYY") : null);
                                        }}
                                        format="DD-MM-YYYY"
                                        minDate={dayjs()}
                                        // disableOpenPicker
                                        slotProps={{
                                            textField: {
                                              error: !!error,
                                              helperText: error?.message,
                                              fullWidth: true,
                                              required: false,
                                              size:'small'
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            )}
                        /> */}
                        <Controller
      name="serviceDate"
      control={control}
      rules={{
        validate: (value) => {
          if (!value) return "This field is required";
          const date = dayjs(value);
          const today = dayjs().startOf('day');
          return date.isValid() && (date.isSame(today, 'day') || date.isAfter(today)) || "Booking Date must be today or in the future";
        }
      }}
      render={({ field: { onChange, value, ...restField }, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            {...restField}
            value={value ? dayjs(value) : null}
            onChange={(newValue) => {
              // Store the date in YYYY-MM-DD format for database compatibility
              onChange(newValue ? newValue.format("YYYY-MM-DD") : null);
            }}
            format="DD-MM-YYYY" // Display format for user
            minDate={dayjs()}
            slotProps={{
              textField: {
                error: !!error,
                helperText: error?.message,
                fullWidth: true,
                required: true,
                size: 'small'
              }
            }}
          />
        </LocalizationProvider>
      )}
    />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* ............central gap................... */}
              <Grid item xs={'none'} lg={2}>
                
              </Grid>
              {/* ............right section................... */}
              <Grid item xs={12} lg={5}>
                <Grid container>
                  <Grid item xs={12} sx={{my:'1%'}}>
                    <Grid container sx={{mb:'1%'}}>
                      <Grid item lg={7} xs={12}>
                        <Typography sx={{fontWeight:'700'}}>Select Time :</Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={12} xs={12}>
                      <Controller
                          name="bookingStartTime"
                          control={control}
                          rules={{
                            required: 'Required',
                            validate: validatebookingStartTime, // Apply time validation
                          }}
                          render={({ field, fieldState: { error } }) => (
                            <TextField
                              {...field}
                              fullWidth
                              size="small"
                              id="time"
                              type="time"
                              inputProps={{
                                step: 300, // 5 minutes step
                              }}
                              // sx={{ width: 150 }}
                              error={!!error}
                              helperText={error ? error.message : ''}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* ............right section ends................... */}
            </Grid>
            
            <Divider sx={{my:'1%',borderBlockStyle:'dashed',borderBlockWidth:'1px'}}/>

            {/* fifth container ...Address Section*/}

            {/* <Grid container spacing={2} >
              
              <Grid item xs={12} lg={5}>
                <Grid container>
                  <Grid item xs={12} sx={{my:'1%'}}>
                    <Grid container sx={{mb:'1%'}}>
                      <Grid item lg={7} xs={12}>
                        <Typography sx={{fontWeight:'700'}}>Select State :</Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={12} xs={12}>
                      <Controller
                          name='state'
                          control={control}
                          rules={{required:'select state'}}
                          render={({field,fieldState:{error}})=>(
                            <FormControl size='small' sx={{ minWidth: "100%" }}>
                                <Select
                                    {...field}
                                    disabled
                                    error={!!error}
                                >
                                  {ServiceArea &&
                                    ServiceArea.map((address,addIndex)=>(
                                      <MenuItem value={address.state} key={addIndex}>{address.state}</MenuItem>
                                    ))
                                  }
                                </Select>
                            </FormControl>
                          )}  
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={'none'} lg={2}>
                
              </Grid>
              <Grid item xs={12} lg={5}>
                <Grid container>
                  <Grid item xs={12} sx={{my:'1%'}}>
                    <Grid container sx={{mb:'1%'}}>
                      <Grid item lg={7} xs={12}>
                        <Typography sx={{fontWeight:'700'}}>Select city :</Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={12} xs={12}>
                        <Controller
                          name='city'
                          control={control}
                          rules={{required:'select city'}}
                          render={({field,fieldState:{error}})=>(
                            <FormControl size='small' sx={{ minWidth: "100%" }}>
                                <Select
                                    {...field}
                                    error={!!error}
                                >
                                  {cityList &&
                                    cityList.map((city,cityIndex)=>(
                                      <MenuItem value={city} key={cityIndex}>{city}</MenuItem>
                                    ))
                                  }
                                </Select>
                            </FormControl>
                          )}  
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid> */}
            <Grid container spacing={2} >
              {/* ............left section................... */}
              <Grid item xs={12} lg={5}>
                  <Grid item xs={12} sx={{my:'1%'}}>
                    <Grid container sx={{mb:'1%'}}>
                      <Grid item lg={7} xs={12}>
                        <Typography sx={{fontWeight:'700'}}>Enter Address :</Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={12} xs={12}>
                        <Controller
                          name='address'
                          control={control}
                          rules={{required:'Enter Address'}}
                          render={({field,fieldState:{error}})=>(
                            <TextField
                              {...field}
                              size='small'
                              fullWidth
                              multiline
                              error={!!error}
                            />
                          )}  
                        />
                      </Grid>
                    </Grid>
                  </Grid>
              </Grid>
              {/* ............central gap................... */}
              <Grid item xs={'none'} lg={2}></Grid>
              {/* ............right section................... */}
              <Grid item xs={12} lg={5}>
                <Grid container>
                  <Grid item xs={12} sx={{my:'1%'}}>
                    <Grid container sx={{mb:'1%'}}>
                      <Grid item lg={7} xs={12}>
                        <Typography sx={{fontWeight:'700'}}>landmark (optional) :</Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={12} xs={12}>
                      <Controller
                          name='landmark'
                          control={control}
                          // rules={{required:'Enter Address'}}
                          render={({field,fieldState:{error}})=>(
                            <TextField
                              {...field}
                              size='small'
                              fullWidth
                              error={!!error}
                            />
                          )}  
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Divider sx={{my:'1%',borderBlockStyle:'dashed',borderBlockWidth:'1px'}}/>
            
            {/* sixth container */}
            <Grid container spacing={2} >
              {/* ............left section................... */}
              <Grid item xs={12} lg={5}>
                <Grid container>
                  <Grid item xs={12} sx={{my:'1%'}}>
                    <Grid container sx={{mb:'1%'}}>
                      <Grid item lg={7} xs={12}>
                        <Typography sx={{fontWeight:'700'}}>Payment Method :</Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={12} xs={12}>
                        <Controller
                          name="paymentMode"
                          control={control}
                          // rules={{ required: "Please select paymentMode" }}
                          render={({ field, fieldState: { error } }) => (
                            <FormControl sx={{ minWidth: "100%" }} component="fieldset" error={!!error}>
                              {/* Replace Select with Checkboxes */}
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      {...field}
                                      // checked={watch('paymentMode')}
                                      checked={watch('paymentMode')=='pay after service'}
                                      onChange={(e) => field.onChange('pay after service')}
                                    />
                                  }
                                  label="Pay After Service"
                                />
                                
                              </FormGroup>
                              {/* {error && <FormHelperText>{error.message}</FormHelperText>} */}
                            </FormControl>
                          )}
                        />

                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* submit button section */}
            <Grid container>    
              <Grid item xs={12} sx={{my:'2%'}}>
                <Box textAlign={'center'}>
                  <Button disabled={!isDirty} type="submit" variant="contained" color="primary">
                    Book
                  </Button>
                  <Box sx={{ flexGrow: 1 }}></Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
      {bookingResponseDetails.message && 
        <BookingResult 
          bookingResponseDetails={bookingResponseDetails}
          onCloseDialog={()=>{
            reset();
            setBookingResponseDetails({status:'',message:'',data:''})
          }}

        />
      }
    </Box >
  );
}
