export function formatDateTime(isoDateTime, dateType = "datetime") {
    let options = {};
    switch (dateType) {
        case "date":
            options = {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            };
            break;
        case "datetime":
            options = {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            };
            break;
        case "time":
            options = {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            };
    }
    return new Date(isoDateTime).toLocaleString(undefined, options);
}
