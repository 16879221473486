import { Dialog, DialogContent } from '@mui/material';
import React from 'react';
import LoadingImage from '../../assets/images/loading.gif'

const Loading = () => {
  return (
    <Dialog open 
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: 'transparent', // Adjust the last value (0.8) for desired transparency
          // backdropFilter: 'blur(10px)', // Creates a frosted glass effect
          boxShadow: 'none',
          // borderRadius: theme.shape.borderRadius,
        },
        width:'fit-content',
        m:'auto',
      }}
    >
      <DialogContent>
        <img src={LoadingImage} width={'200vw'}/>
      </DialogContent>
    </Dialog>
  );
}

export default Loading;
