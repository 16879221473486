import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box, Card, Stack, Typography, Button, Modal, Grid, Divider, Paper, TextField, FormControl, InputLabel, Select, MenuItem, Tooltip, IconButton } from '@mui/material';
import { isMobile } from 'react-device-detect';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { Services } from './Service';
import AddVariation from './AddVariation';
import { CategoryService } from '../Category/CategoryService';
import { useForm, Controller } from 'react-hook-form';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';

const style = {
    position: 'absolute',
    top: (isMobile) ? '80%' : '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #ddd',
    boxShadow: 24,
    p: 2,
};


const VariationTab = ({ }) => {
    const { control, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const [selectedTab, setSelectedTab] = useState(0);
    const [variations, setVatiations] = useState([]);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };
    const [loading, setLoading] = useState(false);
    const [controller, setController] = useState({
        page: 0,
        pageSize: 5,
        filters: {
            searchKey: "",
            category: "",
        }
    });
    const [deleteCat, setDeleteCat] = useState(0);

    /************************  Modal State Manage **************************** */
    const [open, setOpen] = useState(false);
    const [ids, setIds] = useState({});
    const [catType, setCatType] = useState(1);
    const [populateTable, setPopulateTable] = useState(0);
    const [checkFirst, setCheckFirst] = useState(true);
    const [categories, setCategories] = useState(null);

    const getCatData = async () => {
        try {
            setLoading(true);
            const response = await CategoryService.allCategories({
                searchKey: "",
                status: ""
            });
            setCategories(response.data.list);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const getData = async () => {
        try {
            setLoading(true)
            const listResponse = await Services.allVariations({
                page: controller.page,
                limit: controller.pageSize,
                filters: {
                    searchKey: controller.filters.searchKey,
                    category: controller.filters.category
                }
            });
            setVatiations(listResponse.data.list)
        } catch (err) {
            Swal.fire(err.message,'','error')
        }
    }

    useEffect(() => {
        if (checkFirst) {
            getCatData();
        }
        getData();
        setCheckFirst(false);
    }, [controller, deleteCat, populateTable])

    function onSubmit(data) {
        const filtersData = getValues();
        setController({
            ...controller,
            filters: {
                ...controller.filters,
                searchKey: filtersData.searchKey,
                category: filtersData.category,
            }
        });
    }

    const clearFilters = () => {
        reset();
        setController({
            ...controller,
            filters: {
                ...controller.filters,
                searchKey: "",
                category: "",
            }
        });
    }
    /************************  Modal Manage **************************** */
    const modalOpen = (id, catType) => {
        setOpen(true);
        setIds(id);
        setCatType(catType)
    }

    const handleClose = () => {
        setOpen(false);
        setIds(0);
    };
    const afterAddPop = (data) => {
        setPopulateTable(data);
    }

    /************************  Remove Category **************************** */
    const removeTableRow = async (id) => {
        if (!id) return;
        const delP = await Services.deleteVariation(id);
        if (delP.success) {
            setDeleteCat(id);
            Swal.fire('Deleted!', '', 'success')
        } else {
            Swal.fire(delP.message, '', 'error')
        }
    }
    const deleteRow = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Yes, delete it!',
            denyButtonText: `No, cancel!`,
        }).then((result) => {
            if (result.isConfirmed) {
                removeTableRow(id);
            }
        })
    }
    return (
        <div>
            <div>
                <Modal
                    open={open}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{ overflow: "scroll" }}
                >
                    <Box sx={style} width={(isMobile) ? "95%" : "25%"}>
                        <AddVariation populateList={afterAddPop} closeEvent={handleClose} catType={catType} rowId={ids} />
                    </Box>
                </Modal>
            </div>
            <Card sx={{ minWidth: "100%", marginBottom: "10px" }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={8}>
                        <Typography
                            gutterBottom
                            variant='h5'
                            component='div'
                            sx={{ padding: '20px' }}
                        >
                            Manage Variations & Type
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Grid container spacing={1} sx={{ padding: 2 }}>
                            <Grid item xs={6} lg={5}>
                                <Button variant='contained' onClick={() => modalOpen({}, 1)} sx={{ height: "30px", marginTop: "10px", marginRight: "10px" }} endIcon={<AddCircleIcon />}>
                                    Variation
                                </Button>
                            </Grid>
                            <Grid item xs={6} lg={7}>
                                <Button variant='contained' onClick={() => modalOpen({}, 2)} sx={{ height: "30px", marginTop: "10px", marginRight: "10px" }} endIcon={<AddCircleIcon />}>
                                    Variation Type
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Card>
            <Card sx={{ minWidth: "100%", marginBottom: "10px" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} sx={{ p: 2 }}>
                        <Grid item xs={12} lg={2}>
                            <Controller
                                name="searchKey"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        placeholder="Search here....."
                                        label="Search"
                                        size="small"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} lg={2}>
                            <FormControl sx={{ minWidth: "100%" }} size="small">
                                <InputLabel id="demo-simple-select-autowidth-label">Select Category</InputLabel>
                                <Controller
                                    name="category"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            label="Select Category"
                                        >
                                            {categories && categories.map((category) => (
                                                <MenuItem value={category.id}>{category.title}</MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={2}>
                            <Tooltip title="Search Button">
                                <IconButton
                                    color="primary"
                                    type="submit"
                                    sx={{ border: "1px solid", borderRadius: "7px", padding: "6px 6px" }}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Clear All Filters">
                                <IconButton
                                    color="primary"
                                    sx={{ marginLeft: "10px", border: "1px solid", color: "#f00", borderRadius: "7px", padding: "6px 6px" }}
                                    onClick={clearFilters}
                                >
                                    <RestartAltIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </form>
            </Card>
            <Box sx={{ marginTop: 2 }}>
                <Grid container spacing={1}>
                    <Grid item xs={5} lg={2}>
                        <Tabs
                            value={selectedTab}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            orientation="vertical" // Set the orientation to 'vertical'
                            variant="scrollable" // Use 'scrollable' variant for longer lists
                            scrollButtons="auto"
                            sx={{ backgroundColor: "#fff" }}
                            align="left"
                        >
                            {variations.map((variation, index) => (
                                <Tab key={index} label={variation.title} />
                            ))}
                        </Tabs>
                    </Grid>
                    <Grid item xs={7} lg={10}>
                        <Box sx={{ padding: 2, backgroundColor: "#f9f9f9" }}>
                            <Box>
                                <Stack spacing={1} direction="row">
                                    <Button
                                        //variant='contained' 
                                        onClick={(e) => modalOpen(variations[selectedTab], 1)}
                                        sx={{ height: "30px", marginTop: "10px", marginRight: "10px" }}
                                        startIcon={<EditIcon />}
                                        variant="outlined"
                                        color="primary"
                                    >
                                        {isMobile ? "" : "Edit Variation"}
                                    </Button>
                                    <Button
                                        //variant='contained' 
                                        onClick={() => deleteRow(variations[selectedTab].id)}
                                        sx={{ height: "30px", marginTop: "10px", marginRight: "10px" }}
                                        startIcon={<DeleteIcon />}
                                        variant="outlined"
                                        color="error"
                                    >
                                        {isMobile ? "" : "Delete Variation"}
                                    </Button>
                                </Stack>
                            </Box>

                            <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                            <Grid container spacing={2}>
                                {variations[0] && variations[selectedTab].variation_list.map((variation, index) => (

                                    <Grid item xs={12} lg={4}>
                                        <Paper elevation={1} style={{ padding: 7 }}>
                                            <Grid container spacing={2} >
                                                <Grid item xs={9}>
                                                    <Typography variant="h6" sx={{ fontSize: "15px", fontWeight: 600 }} gutterBottom>
                                                        {variation.title}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Stack spacing={2} direction="row" justifyContent="flex-end">

                                                        <EditIcon
                                                            style={{
                                                                fontSize: "20px",
                                                                color: "blue",
                                                                cursor: "pointer",
                                                            }}
                                                            className="cursor-pointer"
                                                            onClick={(e) => modalOpen(variation, 2)}
                                                        />

                                                        <DeleteIcon
                                                            style={{
                                                                fontSize: "20px",
                                                                color: "red",
                                                                cursor: "pointer",
                                                            }}
                                                            className="cursor-pointer"
                                                            onClick={(e) => deleteRow(variation.id)}
                                                        />
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Paper>

                                    </Grid>
                                ))}

                            </Grid>
                        </Box>
                    </Grid>
                </Grid>



            </Box>
        </div>
    );
};

export default VariationTab;
