import { commonRequest } from "../../services/ApiCall";
import { Helpers } from "../../services/Helpers";

export const CategoryService = {
    allCategories : async (data) =>{
        return await commonRequest("get",  `${Helpers.URL()}/api/service-category`,{}, Helpers.token());
    },

    addCategory : async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/api/service-category/`,data, Helpers.token());
    },

    updateCategory : async (id, data) =>{
        return await commonRequest("PUT",  `${Helpers.URL()}/api/service-category/${id}`, data, Helpers.token());
    },

    deleteCategory : async (id) =>{
        return await commonRequest("DELETE",  `${Helpers.URL()}/category/${id}`, {}, Helpers.token());
    },

    getAllVariation: async (searchkey) =>{
        return await commonRequest("GET", `${Helpers.URL()}/variation?searchKey=${searchkey}`, {}, Helpers.token());
    },

    getCatVariations: async (id) =>{
        return await commonRequest("GET", `${Helpers.URL()}/variation/categoryWiseVarientList/${id}`, {}, Helpers.token());
    },

    addCategoryVariation : async (data) =>{
        return await commonRequest("POST",  `${Helpers.URL()}/category/create/categoryVaraiation`, data, Helpers.token());
    },

    deleteCatVariation : async (data) =>{
        return await commonRequest("POST",  `${Helpers.URL()}/category/categoryVariation`, data, Helpers.token());
    },

}
