import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Card, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Layout = ({children,pageName}) => {
    const navigate = useNavigate();
  return (
    <Box sx={{ flexGrow: 1, p: 2 }}>
        <Card sx={{ minWidth: "100%", marginBottom: "1%" ,padding:'1%'}}>
            <Stack direction="row" spacing={2} sx={{justifyContent:'center',alignItems:'center'}}>
            <Typography
                gutterBottom
                variant='h5'
                component='div'
                // sx={{ padding: '20px' }}
            >
                {pageName}
            </Typography>
            <Typography
                variant='h3'
                component='div'
                sx={{ flexGrow: 1 }}
            ></Typography>
            
                <Button variant='contained' onClick={() => navigate(-1)} endIcon={<ArrowBack />} sx={{height:'fit-content'}}>
                Back
                </Button>
            

            </Stack>
        </Card>
        <Box>
            {children}
        </Box>
    </Box>
  );
}

export default Layout;
