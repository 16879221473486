import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Private from "./components/Private";
import Home from "./pages/Home";

import 'react-toastify/dist/ReactToastify.css';
import Otp from "./pages/Login/Otp";
import Sidebar from "./components/Sidebar";
import { Box } from "@mui/material";
import Navbar from "./components/Navbar";
import { LocalStore } from "./storage/authStore";
import Product from "./pages/Product";
import Brand from "./pages/Brand";
import Category from "./pages/Category";
import Service from "./pages/Service";
import User from "./pages/AdminUser";
import UserProfile from "./pages/AdminUser/UserProfile";
import SellerProduct from "./pages/SellerProduct";
import AddProduct from "./pages/Product/AddProduct";
import AddSellerProduct from "./pages/SellerProduct/AddSellerProduct";
import Test from "./pages/Category/Test";
import Variation from "./pages/Variation";
import Orders from "./pages/Order";
import AddEditOrder from "./pages/Order/AddEditOrder";
import AddEditProduct from "./pages/Product/AddEditProduct";
import Customer from "./pages/Customer";
import CustomerProfile from "./pages/Customer/CustomerProfile";
import Booking from "./pages/Booking";
import BookingInfo from "./pages/Booking/BookingInfo";

function App() {
  const isLogin = LocalStore.isLogin();
  const userDetail = LocalStore.getCurrentUserDetails();
  const role = (userDetail) ? userDetail.role : 0;
  return (
    <>
      <div className={isLogin && 'bodyBgColor'}>
        {isLogin && <Navbar />}
        <Box sx={{ display: "flex" }} mt={isLogin && 8}>
          {isLogin && <Sidebar />}
          <Routes>
            <Route path="/" exact element={<Login />} />
            <Route path="/login" exact element={<Login />} />
            {/* <Route path="/otpVerify" exact element={<Otp />} /> */}

            {/* Authorised Path */}
            <Route path="/admin" element={<Private />} >
              {(role == 'admin' || role == 'provider') && (
                <>
                  <Route path="users" element={<User />} />
                  <Route path="products" element={<Product />} />
                  <Route path="products/addEdit/:id" element={<AddEditProduct />} />
                  <Route path="brands" element={<Brand />} />
                  <Route path="category" element={<Category />} />
                  <Route path="service" element={<Service />} />
                  <Route path="variation" element={<Variation />} />
                  <Route path="customers" element={<Customer />} />
                  <Route path="bookings" element={<Booking />} />
                  <Route path="customerProfile/:id" element={<CustomerProfile />} />
                  <Route path="booking/:id" element={<BookingInfo />} />
                </>
              )}
              <Route path="addEditOrder/:id" element={<AddEditOrder />} />
              <Route path="sellerProduct" element={<SellerProduct />} />
              <Route path="addSellerProduct/:sellerId/:sellerProductId/:productId" element={<AddSellerProduct />} />
              <Route path="" element={<Home />} />
              <Route path="dashboard" element={<Home />} />
              <Route path="userProfile/:id" element={<UserProfile />} />
              
              <Route path="orders" element={<Orders />} />
            </Route>
          </Routes>
        </Box>
      </div>
    </>
  );
}

export default App;
