import { Box, Button, Checkbox, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import Test from "./Test";
import { CategoryService } from "./CategoryService";
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from "sweetalert2";
import SearchIcon from '@mui/icons-material/Search';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';

export default function Variations({ closeEvent, catDetail }) {
    const [widgets, setWidgets] = useState([]);
    const [loading, setLoading] = useState(false)
    const [variationList, setVariationList] = useState([])
    const [delRow, setDelRow] = useState("")
    const allVariations = async (searchkey = "") => {
        try {

            const listResponse = await CategoryService.getAllVariation(searchkey);
            if (listResponse.success) {
                setLoading(true)
                setVariationList(listResponse.data)
            }
            setLoading(false)
        } catch (err) {
            Swal.fire(err.message, '', 'error')
        }
    }
    const catVariations = async (id) => {
        try {
            const listResponse = await CategoryService.getCatVariations(id);
            if (listResponse.success) {
                setLoading(true)
                setWidgets(listResponse.data)
            }
            setLoading(false)
        } catch (err) {
            Swal.fire(err.message, '', 'error')
        }
    }
    useEffect(() => {
        allVariations();
        catVariations(catDetail.catData.id);
    }, [delRow])

    function handleOnDrag(e, widgetType) {
        let checkExist = false;
        widgets.filter((item) => {
            if (item.id === widgetType.id) {
                checkExist = true;
            }
        });
        if (!checkExist) {
            e.dataTransfer.setData("widgetType", JSON.stringify(widgetType));
        }
    }

    const arrayContainsObject = (widgets, obj) => {
        return widgets.some(item => Object.keys(item).every(key => item[key] === obj[key]))
    }

    function handleOnDrop(e) {
        if (e.dataTransfer.getData("widgetType")) {
            const widgetType = JSON.parse(e.dataTransfer.getData("widgetType"));
            setWidgets([...widgets, widgetType])
        }
    }

    function handleDragOver(e) {
        e.preventDefault();
    }

    const removeCatVar = async (data) => {
        try {

            const response = await CategoryService.deleteCatVariation(data);
            if (response.success) {
                Swal.fire(response.message, '', 'success')
            }
        } catch (error) {
            Swal.fire(error.message, '', 'error')
        }
    }
    const deleteVariation = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Remove this category variation!",
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Yes, delete it!',
            denyButtonText: `No, cancel!`,
        }).then((result) => {
            if (result.isConfirmed) {
                const removeRowData = {
                    category_id: catDetail.catData.id,
                    variation_id: data.id
                };
                const newWidgets = widgets.filter((item) => {
                    if (item.id !== data.id) {
                        return item;
                    }
                });
                setWidgets(newWidgets);
                if (data.type != "new") {
                    removeCatVar(removeRowData);
                }
            }
        })

    }
    const checkboxHandle = (e, widgetData) => {
        const selectVal = (e.target.checked) ? 1 : 0;
        const newWidgets = widgets.map((wid) => {
            if (wid.id == widgetData.id) {
                return {
                    ...wid,
                    mark_select: selectVal
                }
            } else {
                return {
                    ...wid
                }
            }
        })
        setWidgets(newWidgets);
    }

    const saveVariations = () => {
        const createRow = async () => {
            let catVarList = [];
            if (widgets.length < 2) {
                Swal.fire("Choose at least two variations ", '', 'error');
                return false;
            }
            let countVar = 0;
            for (let varit of widgets) {
                catVarList.push({
                    category_id: catDetail.catData.id,
                    variation_id: varit.id,
                    sort: countVar + 1,
                    mark_select: varit.mark_select
                })
                countVar++;
            }
            const createRowRes = await CategoryService.addCategoryVariation({ category_id: catDetail.catData.id, list: catVarList });
            if (createRowRes.success) {
                Swal.fire("Category Variation Updated successfully", '', 'success')
            } else {
                Swal.fire(createRowRes.message, '', 'error')
            }
        }
        createRow();
    }
    const debounce = (funct) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null
                funct.apply(context, args);
            }, 500)
        }
    }

    const serachVariationHandle = (e) => {
        const searchKey = e.target.value;
        allVariations(searchKey)
    }

    const optimizedEvent = useCallback(debounce(serachVariationHandle));
    
    const dragVariation = useRef(null);
    const draggedOverVariation = useRef(null);
    function handleSort(){
        let widgetClone = [...widgets];
        // remove and save the dragged item content
        const draggedVariationContent = widgetClone.splice(dragVariation.current, 1)[0];

        //switch the position
        widgetClone.splice(draggedOverVariation.current, 0, draggedVariationContent);

        //reset the position ref
        dragVariation.current = null;
        draggedOverVariation.current = null;

        // update widgets
        setWidgets(widgetClone);
    }
    return (

        <Box>
            <Grid container spacing={1}>
                <Grid item lg={12} xs={12}>
                    <Typography variant="h5" align="center">
                        <strong>{catDetail.catData.title}</strong> Variations Manage
                    </Typography>
                    <IconButton
                        style={{ position: "absolute", top: 0, right: 0 }}
                        onClick={closeEvent}
                    >
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item lg={5} xs={5} p={1}>
                    <Grid container spacing={1} sx={{ maxHeight: '300px', overflowY: 'scroll' }}>
                        <Grid item lg={12} xs={12}>
                            <input
                                style={{ width: "100%", borderRadius: '5px', padding: "4px 8px", border: '1px solid #595757' }}
                                placeholder="Type Variation Name Here...."
                                onChange={optimizedEvent}
                            />
                        </Grid>
                        {variationList && variationList.map((variation, index) => (
                            <Grid item lg={12} xs={12}>
                                <Box
                                    sx={{
                                        fontSize: "11px",
                                        width: "100%",
                                        backgroundColor: "#e35a6d",
                                        color: "#fff",
                                        textAlign: "center",
                                        cursor: "grab",
                                        borderRadius: "7px",
                                        padding: "4px 0px"
                                    }}
                                    draggable
                                    onDragStart={(e) => handleOnDrag(e, { id: variation.id, title: variation.title, type: "new" })}
                                >
                                    {variation.title}
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid item lg={7} xs={7}>
                    <Box
                        sx={{
                            width: "100%",
                            height: "300px",
                            maxHeight: "300px",
                            overflowY: "scroll",
                            padding: "11px",
                            backgroundColor: "#f3f3f3"
                        }}
                        className="page"
                        onDrop={handleOnDrop}
                        onDragOver={handleDragOver}
                    >
                        <Grid container spacing={1}>
                            {widgets.map((widget, index) => (
                                <Grid item lg={12} sx={12}>
                                    <Stack
                                        direction="row"
                                        spacing={0}
                                        sx={{ cursor: "move" }}
                                        draggable
                                        onDragStart={() => (dragVariation.current = index)}
                                        onDragEnter={() => (draggedOverVariation.current = index)}
                                        onDragEnd={handleSort}
                                        onDragOver={(e) => e.preventDefault()}
                                    >
                                        <HorizontalSplitIcon sx={{marginRight:'5px', color: '#8d8b8b'}}/>
                                        <Box
                                            sx={{
                                                fontSize: "11px",
                                                width: "75%",
                                                color: "#fff",
                                                textAlign: "center",
                                                borderRadius: "5px",
                                                padding: "4px 0px",
                                                backgroundColor: "#81b27e",
                                            }}
                                            key={index}
                                        >
                                            {widget.title}
                                        </Box>
                                        <Checkbox
                                            onChange={(e) => checkboxHandle(e, widget)}
                                            sx={{
                                                padding: "0px"
                                            }}
                                            checked={(widget.mark_select) ? true : false}
                                        />
                                        <DeleteIcon
                                            style={{
                                                fontSize: "19px",
                                                borderRadius: "3px",
                                                marginLeft: "2px",
                                                marginTop: "3px",
                                                color: "red",
                                                cursor: "pointer",
                                                padding: "2px",
                                                border: "1px solid"
                                            }}
                                            className="cursor-pointer"
                                            onClick={(e) => deleteVariation(widget)}
                                        />
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Grid>
                <Grid item lg={12} align="center">
                    <Button variant="contained" onClick={saveVariations}>Update Variation</Button>
                </Grid>
            </Grid>
        </Box>
    )
}