import { commonRequest } from "../../services/ApiCall";
import { Helpers } from "../../services/Helpers";

export const Product = {
    allProduct : async (data) =>{
        return await commonRequest("POST",  `${Helpers.URL()}/products/`, data, Helpers.token());
    },

    addProduct : async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/products/create`,data, Helpers.token());
    },

    updateProduct : async (id, data) =>{
        return await commonRequest("PUT",  `${Helpers.URL()}/products/${id}`, data, Helpers.token());
    },

    getProductById : async(id) => {
        return await commonRequest("GET", `${Helpers.URL()}/products/${id}`);
    },

    getBrandList : async () =>{
        return await commonRequest("GET", `${Helpers.URL()}/brand/select`, {}, Helpers.token());
    },

    getCategoryList : async() => {
        return await commonRequest("GET", `${Helpers.URL()}/category`, {}, Helpers.token());
    },

    getSubCategoryList : async(category_id) => {
        return await commonRequest("GET", `${Helpers.URL()}/category/subcategory/${category_id}`, {}, Helpers.token());
    },

    getSubSubCategoryList : async(subcategory_id) => {
        return await commonRequest("GET", `${Helpers.URL()}/category/subsubcategory/${subcategory_id}`, {}, Helpers.token());
    },

    deleteProduct : async (id) =>{
        return await commonRequest("DELETE",  `${Helpers.URL()}/products/${id}`, {}, Helpers.token());
    },

    changeStatus: async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/products/statusChange`,data, Helpers.token());
    },

    productImageCreate: async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/products/image/create`,data, Helpers.token());
    },
    
}