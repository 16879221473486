import { Close } from '@mui/icons-material';
import { Box, Card, Dialog, DialogContent, IconButton, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';

const ViewDetails = ({selectedBookingDetails,setSelectedBookingDetails}) => {
    const [dialogOpen,setDialogOpen] = useState(true);
  return (
    <Dialog
        open={dialogOpen}
        PaperProps={{

        }}
        fullWidth
    >
        <Card sx={{display:'flex',justifyContent:'space-between',alignItems:'center',p:'1% 2%'}}>
            <Typography variant={'h5'}>Booking Detail's </Typography>
            <IconButton size='small' color='error' onClick={()=>setSelectedBookingDetails(null)}><Close/></IconButton>
        </Card>
        <DialogContent>
            <Box textAlign={'center'}>
                <Typography fontWeight={'bold'}>Booking Number: {selectedBookingDetails.bookingNumber}</Typography>
            </Box>
            <Box sx={{display:'flex',my:'2%',alignItems:'flex-end'}}>
                <Box sx={{width:'28%'}}>
                    <Typography sx={{fontWeight:'bold'}}>Service Status:</Typography>
                </Box>
                <Typography sx={{textTransform:'uppercase',color:'#778899',fontSize:'smaller'}}>{selectedBookingDetails.bookingStatus}</Typography>
            </Box>
            <Box sx={{my:'2%'}}>
                <Typography sx={{fontWeight:"bold"}}>Service Detail's :</Typography>
                <Paper sx={{p:'2%'}}>
                    <Box sx={{display:'flex',alignItems:'flex-end'}}>
                        <Typography fontWeight={'bold'} sx={{width:'30%',color:'#778899',fontSize:'smaller'}}>Requested Services</Typography>
                        <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
                        {
                            selectedBookingDetails.bookingItems.map((item,index)=>(
                                <Typography fontWeight={'medium'} sx={{fontSize:'small'}}>{item.serviceName}{selectedBookingDetails.bookingItems.length>1 && index<selectedBookingDetails.bookingItems.length-1 && ','}</Typography>
                                ))
                        }
                    </Box>
                </Paper>
            </Box>
            <Box sx={{my:'2%'}}>
                <Typography sx={{fontWeight:"bold"}}>Customer Detail's :</Typography>
                <Paper sx={{p:'2%'}}>
                    <Box sx={{display:'flex',alignItems:'flex-end'}}>
                        <Typography fontWeight={'bold'} sx={{width:'30%',color:'#778899',fontSize:'smaller'}}>Name</Typography>
                        <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
                        <Typography fontWeight={'medium'} sx={{fontSize:'small',textTransform:'capitalize'}}>{selectedBookingDetails.add_customerFullName}</Typography>    
                    </Box>
                    <Box sx={{display:'flex',alignItems:'flex-end'}}>
                        <Typography fontWeight={'bold'} sx={{width:'30%',color:'#778899',fontSize:'smaller'}}>Contact</Typography>
                        <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
                        <Typography fontWeight={'medium'} sx={{fontSize:'small'}}>{selectedBookingDetails.add_mobileNumber}</Typography>         
                    </Box>
                    <Box sx={{display:'flex',alignItems:'flex-end'}}>
                        <Typography fontWeight={'bold'} sx={{width:'30%',color:'#778899',fontSize:'smaller'}}>Address</Typography>
                        <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
                        <Typography fontWeight={'medium'} sx={{fontSize:'small'}}>{selectedBookingDetails.add_address}</Typography>         
                    </Box>
                    <Box sx={{display:'flex',alignItems:'flex-end'}}>
                        <Typography fontWeight={'bold'} sx={{width:'30%',color:'#778899',fontSize:'smaller'}}>Landmark</Typography>
                        <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
                        <Typography fontWeight={'medium'} sx={{fontSize:'small'}}>{selectedBookingDetails.add_landmark}</Typography>         
                    </Box>
                </Paper>
            </Box>
            <Box sx={{my:'2%'}}>
                <Typography sx={{fontWeight:"bold"}}>Provider Detail's <Box component={'span'} sx={{alignSelf:'right'}}>:</Box></Typography>
                <Paper sx={{p:'2%'}}>
                    <Box sx={{display:'flex',alignItems:'flex-end'}}>
                        <Typography fontWeight={'bold'} sx={{width:'30%',color:'#778899',fontSize:'smaller'}}>Name</Typography>
                        <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
                        <Typography fontWeight={'medium'} sx={{fontSize:'small',textTransform:'capitalize'}}>{selectedBookingDetails.provider.UserProfile.firstName}{' '}{selectedBookingDetails.provider.UserProfile.lastName}</Typography>    
                    </Box>
                    <Box sx={{display:'flex',alignItems:'flex-end'}}>
                        <Typography fontWeight={'bold'} sx={{width:'30%',color:'#778899',fontSize:'smaller'}}>Contact</Typography>
                        <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
                        <Typography fontWeight={'medium'} sx={{fontSize:'small'}}>{selectedBookingDetails.provider.mobileNumber}</Typography>         
                    </Box>
                </Paper>
            </Box>
        </DialogContent>
    </Dialog>
  );
}

export default ViewDetails;
