import { Close, Download, Share } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';

const BookingResult = ({onCloseDialog,bookingResponseDetails}) => {
    const [openDialog,setOpenDialog] = useState(true);
  return (
    <Dialog open={openDialog} fullWidth
        PaperProps={{
            sx: { borderRadius: '1rem' }
        }}
    >
        <Box textAlign={'right'} p={1}>
            <IconButton size='small' color='error' onClick={()=>onCloseDialog()}><Close/></IconButton>
        </Box>
      <DialogContent>
        <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
            <Typography 
              sx={{fontWeight:700,
                color:`${bookingResponseDetails.status?'green':'false'}`,
                fontSize:'xx-large',mb:'5%',textTransform:'capitalize'}}
            >{bookingResponseDetails.message}</Typography>
            <Typography sx={{fontWeight:700,fontSize:'large',mb:'5%'}}>Booking Number: {bookingResponseDetails.data.orderDetails.bookingNumber}</Typography>
            <Typography sx={{fontWeight:700,fontSize:'large',mb:'5%'}}>Total Amount To Pay - Rs. 2500/-</Typography>
            <Button endIcon={<Download/>} onClick={()=>onCloseDialog()}>Download Order Summary</Button>
            <Button endIcon={<Share/>} onClick={()=>onCloseDialog()}>Share</Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default BookingResult;
