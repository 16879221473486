import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { isMobile } from 'react-device-detect';
import { Box, Grid, Typography } from '@mui/material';
import { uploadImageS3Bucket } from '../../utils/s3bucketImg';
import { Services } from './Services';
import Swal from 'sweetalert2';

const ImgUpload = ({ open, onClose, payRowData, trigStatus }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [image, setImage] = useState("");
    const [imgErr, setImgErr] = useState("");

    useEffect(() => {
        setImage("");
    }, [open])

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setImage(URL.createObjectURL(file))
        setSelectedImage(file);
    };

    const handleUpload = async () => {
        if (selectedImage == "" || selectedImage == null) {
            setImgErr("Please choose payment image.")
            return false;
        }
        setImgErr("");
        const imageOption = {
            path: "order_payment_Img",
            file: selectedImage,
            fileName: `order-payment-img-${payRowData.id}.jpeg`,
            imgWidth: "600",
            imgHeight: "600",
            imgType: "JPEG",
            quality: "70"
        }
        const imageRes = await uploadImageS3Bucket(imageOption);
        if (imageRes) {
            const updateImageData = {
                client_payment_image: imageRes.Location,
            }
            const uploadRes = await Services.updatePaidPaymentImg(payRowData.id, updateImageData);
            if (uploadRes.success) {
                Swal.fire("Image uploaded successfully", "", "success")
                trigStatus(Math.floor(Math.random() * 100));
                onClose();
            } else {
                Swal.fire("Faild", "", "error")
            }
        }
    };

    return (
        <div>
            <Dialog
                onClose={onClose}
                open={open}
                maxWidth="lg"
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" >
                    Image Upload
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers sx={{ width: '420px', height: '300px' }}>
                    <Grid container spacing={2}>
                        <Grid item lg={12}>
                            <label htmlFor="upload-image">
                                <Button sx={{ width: "100%" }} variant="outlined" component="span">
                                    Choose Product Images
                                </Button>

                                <input
                                    id="upload-image"
                                    hidden
                                    accept="image/*"
                                    type="file"
                                    onChange={handleImageChange}
                                />
                            </label>
                        </Grid>
                        {imgErr && (
                            <Grid item lg={12}>
                                <Typography variant='body' color="red">
                                    {imgErr}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item lg={12} >
                            {image && (
                                <Box
                                    component="img"
                                    sx={{
                                        width: 100,
                                        marginTop: "20px",
                                        marginLeft: "5px",
                                        borderRadius: "10px"
                                        // maxHeight: { xs: 233, md: 167 },
                                        // maxWidth: { xs: 350, md: 250 },
                                    }}
                                    alt="Payment Image"
                                    src={image}
                                />
                            )}
                        </Grid>
                    </Grid>
                    {/* <input type="file" accept="image/*" onChange={handleImageChange} /> */}


                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleUpload}>
                        Upload Image
                    </Button>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ImgUpload;
