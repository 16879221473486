import { commonRequest } from "./ApiCall";
import { Helpers } from "./Helpers";
export const Auth = {
    checkMobile : async (data) =>{
        return await commonRequest("POST",  `${Helpers.URL()}/api/auth/verifyMobileNumber`, data);
    },

    verifyOtp : async (data) =>{
        console.log("dddddddddddddddddd", data)
        return await commonRequest("POST",  `${Helpers.URL()}/api/auth/verifyOtp`, data);
    }
}