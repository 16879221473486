import { useState, useEffect, useCallback } from 'react';
import { Card, Typography, Divider, Stack, TextField, Grid, Button, Box, Toolbar, MenuItem, FormControl, InputLabel, Select, TableCell, IconButton, Tooltip, Chip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import Modal from '@mui/material/Modal';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { isMobile } from 'react-device-detect';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import CheckIcon from '@mui/icons-material/Check';

import AddBrand from './AddBrand';
import { Services } from './Services';
import { LocalStore } from '../../storage/authStore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { formatDateTime } from '../../utils/dateUtils';
import { useForm, Controller } from 'react-hook-form';
import CustomNoRowsOverlay from '../../components/NoRows';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';
import { formatCurrency } from '../../utils/currencyFormatter';


const style = {
    position: 'absolute',
    top: (isMobile) ? '80%' : '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: (isMobile) ? "95%" : "25%",
    bgcolor: 'background.paper',
    border: '2px solid #ddd',
    boxShadow: 24,
    p: 2,
};


export default function OrderList() {
    const { control, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const userDetail = LocalStore.getCurrentUserDetails();
    const role = (userDetail) ? userDetail.role : 0;
    const [populateTable, setPopulateTable] = useState(0);

    /************************  Table Data State Manage **************************** */
    const [list, setList] = useState([]); // Filtered data    const [allRowCount, setAllRowCount] = useState(0);
    const [deleteProd, setDeleteRow] = useState(0);
    const [allRowCount, setAllRowCount] = useState(0);
    const [controller, setController] = useState({
        page: 0,
        pageSize: 5,
        store_id: (role != 2) ? 0 : userDetail.id,
        filters: {
            searchKey: "",
            order_status: 0,
            payment_status: 0,
        }
    });

    const [loading, setLoading] = useState(false);

    /************************  Modal State Manage **************************** */
    const [open, setOpen] = useState(false);
    const [ids, setIds] = useState({});

    /************************  Manage Use Effect **************************** */
    useEffect(() => {
        const getData = async () => {
            try {
                setLoading(true);
                const listResponse = await Services.orderList({
                    page: controller.page,
                    limit: controller.pageSize,
                    store_id: controller.store_id,
                    filters: {
                        searchKey: controller.filters.searchKey,
                        order_status: controller.filters.order_status,
                        payment_status: controller.filters.payment_status,
                    }
                });
                setLoading(false);
                if (listResponse.success) {
                    const data = listResponse.data.list;
                    setList(listResponse.data.list);
                    setAllRowCount(listResponse.data.totalRows);
                } else {
                    Swal.fire('Request failed', '', 'error')
                }
            } catch (error) {
                Swal.fire(error.message, '', 'error')
            }
        };
        getData();
    }, [controller, deleteProd, populateTable]);

    /************************  Modal Manage **************************** */
    const modalOpen = (id) => {
        setOpen(true);
        setIds(id);
    }

    const handleClose = () => {
        setOpen(false);
        setIds(0);
    };


    /************************  Table Column Manage **************************** */
    const columns = [
        { field: "id", headerName: "ID", width: 50, editable: false },
        { field: "order_number", headerName: "Order Number", width: 160, editable: false },
        {
            field: "customer", headerName: "Customer Name", width: 130, valueGetter: (params) => {
                return `${params.row.customer.first_name} ${params.row.customer.last_name}`
            }
        },
        {
            field: "delivery_charge", headerName: "Delivery Charge", width: 130, renderCell: (params) => {
                return formatCurrency(params.row.delivery_charge)
            }
        },
        {
            field: "subtotal", headerName: "Sub Total", width: 100, renderCell: (params) => {
                return formatCurrency(params.row.subtotal)
            }
        },
        {
            field: "total_amount", headerName: "Total Amt", width: 100, renderCell: (params) => {
                return formatCurrency(params.row.total_amount)
            }
        },
        {
            field: "payment_status", headerName: "Payment Status", width: 140, renderCell: (params) => {
                return (
                    <>
                        {
                            (params.row.payment_status == 1) ? (
                                <Chip variant="outlined" size='small' sx={{ backgroundColor: "#d3db5d", color: "#000000" }} icon={<CheckIcon />} label="Pending" />
                            ) : (
                                (params.row.payment_status == 2) ? (
                                    <Chip variant="outlined" size='small' sx={{ backgroundColor: "#b7e369", color: "#000000" }} icon={<CheckIcon />} label="Partially Paid" />
                                ) : (
                                    (params.row.payment_status == 3) ? (
                                        <Chip variant="outlined" size='small' sx={{ backgroundColor: "#7fd353", color: "#000000" }} icon={<CheckIcon />} label="Fully Paid" />
                                    ) : (
                                        <Chip variant="outlined" size='small' sx={{ backgroundColor: "#f69393", color: "#000000" }} icon={<CheckIcon />} label="Canceled" />
                                    )
                                )
                            )
                        }
                    </>
                );
            }
        },
        {
            field: "order_status", headerName: "ORD Status", width: 100, editable: false, renderCell: (params) => {
                return (
                    <>
                        {
                            (params.row.order_status.status_id == 1) ? (
                                <Chip variant="outlined" size='small' sx={{ backgroundColor: "#d3db5d", color: "#000000" }} icon={<CheckIcon />} label={params.row.order_status.short_title} />
                            ) : (
                                (params.row.order_status.status_id == 2) ? (
                                    <Chip variant="outlined" size='small' sx={{ backgroundColor: "#cdffdd", color: "#000000" }} icon={<CheckIcon />} label={params.row.order_status.short_title} />
                                ) : (
                                    (params.row.order_status.status_id == 3) ? (
                                        <Chip variant="outlined" size='small' sx={{ backgroundColor: "#aacfeb", color: "#000000" }} icon={<CheckIcon />} label={params.row.order_status.short_title} />
                                    ) : (
                                        (params.row.order_status.status_id == 4) ? (
                                            <Chip variant="outlined" size='small' sx={{ backgroundColor: "#7fd353", color: "#000000" }} icon={<CheckIcon />} label={params.row.order_status.short_title} />
                                        ) : (
                                            <Chip variant="outlined" size='small' sx={{ backgroundColor: "#f69393", color: "#000000" }} icon={<CheckIcon />} label={params.row.order_status.short_title} />
                                        )
                                    )
                                )
                            )
                        }
                    </>
                );
            }
        },
        {
            field: "createdAt", headerName: "Created Date", flex: 1, valueGetter: (params) => {
                return formatDateTime(params.row.createdAt, "datetime")
            }
        }, {
            field: "actions", headerName: "Action", width: 100, renderCell: (params) => {
                return (
                    <Stack spacing={2} direction="row">
                        {(role == 2) ? (
                            <Button
                                //variant='contained' 
                                onClick={(e) => window.open(`addEditOrder/${params.row.id}`, '_blank')}
                                sx={{ height: "30px", marginTop: "10px", marginRight: "10px" }}
                                startIcon={<VisibilityIcon />}
                                variant="outlined"
                                color="primary"
                                size='small'
                            >
                                {isMobile ? "" : ""}
                            </Button>
                        ) : (
                            <Button
                                //variant='contained' 
                                onClick={(e) => window.open(`addEditOrder/${params.row.id}`, '_blank')}
                                sx={{ height: "30px", marginTop: "10px", marginRight: "10px" }}
                                startIcon={<EditIcon />}
                                variant="outlined"
                                color="primary"
                                size='small'
                            >
                                {isMobile ? "" : " Edit"}
                            </Button>
                            // <EditIcon
                            //     style={{
                            //         fontSize: "20px",
                            //         color: "blue",
                            //         cursor: "pointer",
                            //     }}
                            //     className="cursor-pointer"
                            //     onClick={(e) => window.open(`addEditOrder/${params.row.id}`, '_blank')}
                            // />
                        )}
                    </Stack>
                );
            }
        },
    ];

    const afterAddPop = (data) => {
        setPopulateTable(data);
    }


    const handlePaginationChange = (newPagination) => {
        setController({
            ...controller,
            page: newPagination.page,
            pageSize: newPagination.pageSize
        });
    }

    function onSubmit(data) {
        const filtersData = getValues();
        setController({
            ...controller,
            store_id: controller.store_id,
            filters: {
                ...controller.filters,
                searchKey: filtersData.searchKey,
                order_status: filtersData.order_status,
                payment_status: filtersData.payment_status
            }
        });
    }

    const clearFilters = () => {
        reset();
        setController({
            ...controller,
            store_id: controller.store_id,
            filters: {
                ...controller.filters,
                searchKey: "",
                order_status: "",
                payment_status: ""
            }
        });
    }
    return (
        <>
            <div>
                <Modal
                    open={open}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{ overflow: "scroll" }}
                >
                    <Box sx={style}>
                        <AddBrand populateList={afterAddPop} closeEvent={handleClose} rowId={ids} />
                    </Box>
                </Modal>
            </div>
            <Card sx={{ minWidth: "100%", marginBottom: "10px" }}>
                <Stack direction="row" spacing={2} className="">
                    <Typography
                        gutterBottom
                        variant='h5'
                        component='div'
                        sx={{ padding: '20px' }}
                    >
                        Order List
                    </Typography>
                    <Typography
                        variant='h3'
                        component='div'
                        sx={{ flexGrow: 1 }}
                    ></Typography>
                    {/* <Typography
                        gutterBottom
                        variant='h5'
                        component='div'
                        sx={{ padding: '10px' }}
                    >
                        <Button variant='contained' onClick={handleAddProduct} sx={{ height: "30px", marginTop: "10px" }} endIcon={<AddCircleIcon />}>
                            Add Product
                        </Button>
                    </Typography> */}

                </Stack>
            </Card>
            <Card sx={{ minWidth: "100%", marginBottom: "10px" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} sx={{ p: 2 }}>
                        <Grid item xs={12} lg={2}>
                            <Controller
                                name="searchKey"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        placeholder="Search here....."
                                        label="Search"
                                        size="small"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <FormControl sx={{ minWidth: "100%" }} size="small">
                                <InputLabel id="demo-simple-select-autowidth-label">Order Status</InputLabel>
                                <Controller
                                    name="order_status"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            label="Order Status"
                                        >
                                            <MenuItem value="0">All</MenuItem>
                                            <MenuItem value="1">Request For Quote</MenuItem>
                                            <MenuItem value="2">Quote Under Negotiation</MenuItem>
                                            <MenuItem value="3">Quote Final Accepted</MenuItem>
                                            <MenuItem value="4">Order Generated</MenuItem>
                                            <MenuItem value="5">Order Canceled</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} lg={2}>
                            <FormControl sx={{ minWidth: "100%" }} size="small">
                                <InputLabel id="demo-simple-select-autowidth-label">Payment Status</InputLabel>
                                <Controller
                                    name="payment_status"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            label="Payment Status"
                                        >
                                            <MenuItem value="0">All</MenuItem>
                                            <MenuItem value="1">Pending</MenuItem>
                                            <MenuItem value="2">Partially Paid</MenuItem>
                                            <MenuItem value="3">Fully Paid</MenuItem>
                                            <MenuItem value="4">Canceled</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={2}>
                            <Tooltip title="Search Button">
                                <IconButton
                                    color="primary"
                                    type="submit"
                                    sx={{ border: "1px solid", borderRadius: "7px", padding: "6px 6px" }}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Clear All Filters">
                                <IconButton
                                    color="primary"
                                    sx={{ marginLeft: "10px", border: "1px solid", color: "#f00", borderRadius: "7px", padding: "6px 6px" }}
                                    onClick={clearFilters}
                                >
                                    <RestartAltIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </form>
            </Card>
            <Box sx={{ overflow: "auto", backgroundColor: "#fff" }}>
                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                    <DataGrid
                        rows={list}
                        columns={columns}
                        getRowId={(row) => row.id}
                        //checkboxSelection={true}
                        rowCount={allRowCount}  // Use the length of the filtered list
                        pageSizeOptions={[5, 10, 20, 50, 100]}
                        paginationModel={controller}
                        paginationMode="server"
                        onPaginationModelChange={handlePaginationChange}
                        components={{ Toolbar: GridToolbar }}
                        loading={loading}

                        disableColumnMenu
                        slots={{
                            noRowsOverlay: () => <CustomNoRowsOverlay />,
                        }}
                    />
                </Box>
            </Box>
        </>
    )
}