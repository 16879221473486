import { Box, Button, Card, Divider, Grid, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Services } from "./SellerService";
import { CategoryService } from "../Category/CategoryService";
import { useForm, Controller } from 'react-hook-form';
import Swal from "sweetalert2";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from 'react-select';
import { GenerateUniqueVal } from "../../utils/generateUniqValue";
import VarientProdForm from "./component/VarientProdForm";
import VarientDetailsForm from "./component/VarientDetailsForm";
import { Helpers } from "../../services/Helpers";

export default function AddSellerProduct() {
    const { control, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const validationRules = {
        required: 'This field is required',
    };

    const navigate = useNavigate();
    const { sellerId, sellerProductId, productId } = useParams();
    const menuDispatch = useDispatch();

    menuDispatch({
        type: 'menuSelect',
        payload: 'sellerProduct'
    });


    const [searchText, setSearchText] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [productDetail, setProductDetail] = useState([]);
    const [variations, setVariations] = useState([]);
    const [productName, setProductName] = useState("");
    const [productImages, setProductImages] = useState([]);
    const [selectedVar, setSelectedVar] = useState([]);
    const [productSpec, setProductSpec] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [trig, setTrig] = useState(0);
    const getProductDetail = async (sellerProductId) => {
        try {
            const response = await Services.getSellerProductBySellerProdId(sellerProductId, sellerId, productId);
            if (response.data) {

                setSearchText(response.data.product_master.title);
                setValue('category', response.data.product_master.product_categories[0].category_name)
                setValue("brand", response.data.product_master.product_brand.brand_name);
                setProductDetail(response.data);
                setProductImages(response.data.product_master.product_master_images);
                setValue('stock', response.data.stock);
                setValue('mrp', response.data.mrp);
                //setProductName(response.data.product_name)
                setValue('productName', `${response.data.product_master.title}`);
                setValue('sale_price', response.data.sale_price);
                setValue('unit_price', response.data.unit_price);
                const variations = response.data.store_product_variations;
                const getVariation = await CategoryService.getCatVariations(response.data.product_master.product_categories[0].category);

                let newVariations = [];
                let dataR = [];
                for (let varDataRow of getVariation.data) {
                    varDataRow.data = [];
                    let data = {
                        id: 0,
                        variation_details_id: 0,
                        variation_title: varDataRow.title,
                        variation_value: "",
                        sort: varDataRow.sort,
                        mark_select: varDataRow.mark_select
                    }

                    for (let varRow of variations) {
                        if (varRow.variation_title == varDataRow.title) {
                            varDataRow.data = varRow;
                            data.id = varRow.id;
                            data.variation_details_id = varRow.variation_details_id;
                            data.variation_value = varRow.variation_value;
                            data.sort = varDataRow.sort;
                            data.mark_select = varDataRow.mark_select;
                        }
                    }
                    newVariations.push(varDataRow);
                    dataR.push(data);
                }
                setVariations(newVariations);
                setSelectedVar(dataR);
                setProductSpec(response.data.product_master.store_product_variations)
            }
        } catch (err) {
            Swal.fire(err.message, '', 'error')
        }
    };
    useEffect(() => {
        if (sellerProductId > 0) {
            getProductDetail(sellerProductId)
            console.log(sellerProductId, "sellerProductId")
        }
    }, [sellerProductId, trig])

    const getProducts = async (searchKey) => {
        try {
            const response = await Services.searchProductsByKey(searchKey);
            if (response.data) {
                setSearchResults(response.data);
            }
        } catch (err) {
            Swal.fire(err.message, '', 'error')
        }
    };

    const productDetails = async (id) => {
        setVariations([]);
        const response = await Services.getProductDetailsById(id);
        if (response.data.product_categories[0]) {
            const category_id = response.data.product_categories[0].category;
            setValue('category', response.data.product_categories[0].category_name)
            const getVariation = await CategoryService.getCatVariations(category_id);

            setVariations(getVariation.data);
            let dataR = [];
            for (let varDataRow of getVariation.data) {
                let data = {
                    id: 0,
                    variation_details_id: 0,
                    variation_title: varDataRow.title,
                    variation_value: "",
                    sort: varDataRow.sort,
                    mark_select: varDataRow.mark_select
                }
                dataR.push(data);
            }
            setSelectedVar(dataR);
        }
        setValue("brand", response.data.product_brand.brand_name);
        setProductDetail(response.data);
        setProductImages(response.data.product_master_images);
        setProductSpec(response.data.store_product_variations)
    }
    const selectProduct = async (data) => {

        const checkExistProduct = await Services.getSellerProductBySellerProdId(0, sellerId, data.id);

        const productVariations = (checkExistProduct.data.variantProducts) ? checkExistProduct.data.variantProducts : [];
        if (productVariations.length > 0) {
            navigate(`/admin/addSellerProduct/${sellerId}/${checkExistProduct.data.variantProducts[0].storeProductId}/${data.id}`);
        } else {
            reset();
            setSearchResults([]);
            setSelectedVar([])
            setSearchText("");
            setProductName(data.title)
            setValue("productName", data.title);
            productDetails(data.id);
        }
    }

    function onSubmit(data) {
        if (data.varientProducts.length == 0) {
            Swal.fire("Please add product variation", '', 'error');
            return false;
        }
        const variationMap = data.varientProducts.reduce((acc, curr) => {
            const key = `${curr.variation1}-${curr.variation2}`;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(curr);
            return acc;
        }, {});

        const duplicates = Object.values(variationMap).filter(arr => arr.length > 1);
        if (duplicates.length > 0) {
            Swal.fire("Duplicate product variation found", '', 'error');
            return false;
        }

        let newProductVariation = [];
        for (let dataVar of data.varientProducts) {
            let newProductData = dataVar;
            for (let varRow of variations) {
                for (let varDetail of varRow.list) {
                    if (varDetail.id == dataVar.variation1) {
                        newProductData.variation1_detail = { variation_name: varRow.title, variation_value: varDetail.title };
                    }
                    if (varDetail.id == dataVar.variation2) {
                        newProductData.variation2_detail = { variation_name: varRow.title, variation_value: varDetail.title };
                    }
                }
            }
            // newProductData.productVariationName = `${data.productName} (${newProductData.variation1_detail.variation_value}, ${newProductData.variation2_detail.variation_value})`;            
            // const slugname = `${data.productName.trim()}`;
            // newProductData.slug = Helpers.generateSlug(slugname);
            newProductVariation.push(newProductData)
        }

        const saveData = {
            store_id: sellerId,
            product_id: (sellerProductId > 0) ? productDetail.product_master.id : productDetail.id,
            product_variations: newProductVariation
        }

        // console.log(saveData, "saveData")
        // return false;
        const createRow = async () => {
            let createRowRes = "";
            if (sellerProductId > 0) {
                createRowRes = await Services.updateStoreProduct(sellerProductId, saveData);
            } else {
                createRowRes = await Services.addSellerProduct(saveData)
            }
            //return false;
            if (createRowRes.success) {
                setTrig(GenerateUniqueVal());
                reset();
                setProductDetail([]);
                setVariations([]);
                setProductImages([]);
                setSelectedVar([]);
                // navigate(`/admin/addSellerProduct/${sellerId}/0/0`);
                Swal.fire(createRowRes.message, '', 'success');
            } else {
                Swal.fire(createRowRes.message, '', 'error');
            }
        }
        createRow();
    }
    return (
        <>
            <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
                <Card sx={{ minWidth: "100%", marginBottom: "10px" }}>
                    <Stack direction="row" spacing={2} className="">
                        <Typography
                            gutterBottom
                            variant='h5'
                            component='div'
                            sx={{ padding: '20px' }}
                        >
                            {(sellerProductId > 0) ? "Edit Store Product" : "Add New Store Product"}
                        </Typography>
                    </Stack>
                </Card>
                {(sellerProductId == 0) && (
                    <Card sx={{ minWidth: "100%", marginBottom: "10px", p: 2 }}>
                        <Grid container spacing={2} >
                            <Grid item lg={7} xs={12}>
                                <Box
                                    sx={{
                                        position: "relative",
                                        zIndex: 9999, // Set a higher z-index to make it appear above the card
                                    }}
                                >
                                    <Select
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        options={searchResults.map((result) => ({
                                            label: result.title,
                                            value: result.id,
                                        }))}
                                        value={
                                            searchResults.find((result) => result.title === searchText) || null
                                        }
                                        onChange={(selectedOption) => {
                                            const selectedProduct = searchResults.find(
                                                (result) => result.id === selectedOption.value
                                            );
                                            if (selectedProduct) {
                                                selectProduct(selectedProduct);
                                            }
                                        }}
                                        onInputChange={(inputValue) => {
                                            setSearchText(inputValue);
                                            getProducts(inputValue);
                                        }}
                                        placeholder="Choose Product"
                                    />
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={() => setAnchorEl(null)}
                                    >
                                        {searchResults.map((result) => (
                                            <MenuItem
                                                key={result.id} // Add a unique key here
                                                onClick={() => {
                                                    selectProduct(result);
                                                    setAnchorEl(null);
                                                }}
                                            >
                                                {result.title}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Box>
                            </Grid>
                        </Grid>
                    </Card>
                )}
                {productDetail.length !== 0 && (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Card sx={{ minWidth: "100%", marginBottom: "10px", p: 2 }}>

                            <Grid container spacing={2}>
                                <Grid item lg={9} xs={12}>
                                    <Controller
                                        name="productName"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                placeholder="Enter Product Name"
                                                label="Product Name"
                                                size="small"
                                                fullWidth
                                                disabled={true}
                                                InputProps={{ readOnly: true }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item lg={3} xs={12}>
                                    <Controller
                                        name="brand"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                placeholder="Enter Brand"
                                                label="Brand"
                                                size="small"
                                                fullWidth
                                                disabled={true}
                                                InputProps={{ readOnly: true }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                    <Controller
                                        name="category"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                placeholder="Enter Catetory"
                                                label="Category"
                                                size="small"
                                                disabled={true}
                                                InputProps={{ readOnly: true }}
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Card>
                        <Card sx={{ minWidth: "100%", marginBottom: "10px", p: 2 }}>
                            {variations && variations.length > 0 && (
                                <VarientProdForm control={control} variations={variations} productDetail={productDetail} getValues={getValues} sellerProductId={sellerProductId} errors={errors} />
                            )}
                        </Card>
                        <Box>
                            <Grid container spacing={2}>
                            <Grid item lg={6} xs={12}>
                                    <Card sx={{ minWidth: "100%", p: 2 }}>
                                        <Typography
                                            gutterBottom
                                            variant='h6'
                                            component='div'
                                        >
                                            Product Images
                                        </Typography>
                                        <Divider />
                                        <Grid container spacing={2} sx={{ paddingTop: '15px' }}>
                                            {productImages && productImages.map((image, index) => (
                                                <Grid item lg={4} xs={12} key={index}>
                                                    <Box
                                                        component="img"
                                                        sx={{
                                                            height: 200,
                                                            marginLeft: "5px",
                                                            borderRadius: "10px"
                                                            // maxHeight: { xs: 233, md: 167 },
                                                            // maxWidth: { xs: 350, md: 250 },
                                                        }}
                                                        alt="The house from the offer."
                                                        src={`${image.image_url}md/${image.image_title}`}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Card>
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <Card sx={{ minWidth: "100%", marginBottom: "10px", p: 2 }}>
                                        <Typography
                                            gutterBottom
                                            variant='h6'
                                            component='div'
                                        >
                                            Product Specifications
                                        </Typography>
                                        <Divider />
                                        <Box>
                                            <Grid container spacing={2} sx={{ marginTop: '0.7rem' }}>
                                                {productSpec && productSpec.map((item, index) => {
                                                    console.log("item", item, index)
                                                    return (
                                                        <Grid key={index} item xs={12} lg={6} >
                                                            <Typography variant="caption" component="p" >
                                                                <span style={{ marginRight: '8px' }}>{item.variation_title}: </span> <strong style={{ fontSize: '1rem' }}>{item.variation_value}</strong>
                                                            </Typography>
                                                        </Grid>
                                                    );
                                                }
                                                )}
                                            </Grid>
                                        </Box>
                                    </Card>
                                </Grid>                                
                            </Grid>
                        </Box>


                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                            <Button variant="outlined" type="submit">Save</Button>
                            <Button variant="outlined" onClick={() => navigate('/seller-product')}>Cancel</Button>
                        </Box>
                    </form>
                )}
            </Box >
        </>
    )
}
