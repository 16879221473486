import { Box, Typography } from '@mui/material';
import React from 'react';
import Cudel_logo from '../../assets/images/cudel_logo.svg'

const LogoSection = () => {
  return (
    <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center',p:'2% 5%',borderBottom:'1px solid #5252bd'}}>
        <Box sx={{width:'25%'}}>
            <img src={Cudel_logo} alt='cudel_logo' style={{width:'100%'}} />
        </Box>
        <Box sx={{flex:1,textAlign:'right'}}>
            <Typography sx={{cursor:'pointer',color:'#1976d2',fontSize:'small'}}>? Get Help</Typography>
        </Box>
    </Box>
  );
}

export default LogoSection;
