import { commonRequest } from "../../services/ApiCall";
import { Helpers } from "../../services/Helpers";

export const Services = {
    searchProductsByKey : async (searchKey) =>{
        return await commonRequest("GET",  `${Helpers.URL()}/products/search/${searchKey}`);
    },

    getProductDetailsById:  async (id) =>{
        return await commonRequest("GET",  `${Helpers.URL()}/products/${id}`);
    },

    addSellerProduct : async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/store_products/create`,data, Helpers.token());
    },

    changeStatus: async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/store_products/statusChange`,data, Helpers.token());
    },

    getSellerProductBySellerProdId :  async (id, sellerId, productId) =>{
        return await commonRequest("GET",  `${Helpers.URL()}/store_products/${id}/${sellerId}/${productId}`);
    },

    allStoreProducts : async (data) =>{
        return await commonRequest("POST",  `${Helpers.URL()}/store_products/`, data);
    },

    getSellerList :  async (id) =>{
        return await commonRequest("GET",  `${Helpers.URL()}/store_products/list/sellers`);
    },

    updateStoreProduct : async (id, data) =>{
        return await commonRequest("PUT",  `${Helpers.URL()}/store_products/${id}`, data, Helpers.token());
    },

    disableProduct : async (id) =>{
        return await commonRequest("DELETE",  `${Helpers.URL()}/store_products/${id}`, {}, Helpers.token());
    },
    
}