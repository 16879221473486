import { Box } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import BrandList from "./BrandList";

export default function Brand(){
    const menuDispatch = useDispatch();
    menuDispatch({
        type: 'menuSelect',
        payload: 'brand'
    });
    return (
        <>
            <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
                <BrandList />
            </Box>
        </>
    )
}