import Resizer from "react-image-file-resizer";
import { LocalStore } from "../storage/authStore";
import AWS from "aws-sdk";
import Swal from "sweetalert2";

const resizeFile = (file, imgWidth, imgHeight, imgType="JPEG", quality="100") => new Promise(resolve => {
    Resizer.imageFileResizer(file, imgWidth, imgHeight, imgType, quality, 0,
    uri => {
      resolve(uri);
    }, 'base64' );
});

const REGION = process.env.REACT_APP_REGION;
const ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;

export const Helpers = {
    URL: () => {
        return process.env.REACT_APP_API_URL;
    },
    token: () => {
        const details = LocalStore.getCurrentUserDetails();
        const token = (details) ? details.token : 0;
        return token;
    },
    generateSlug: (title) => {
        return title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
    },

    uploadImageS3Bucket: async (options) => {
        // S3 Bucket Name
        
        const S3_BUCKET = `bharat2bharat/images/${options.path}`;

        // S3 Region
        const REGION = "ap-south-1";

        // S3 Credentials
        AWS.config.update({
            accessKeyId: ACCESS_KEY_ID,
            secretAccessKey: SECRET_ACCESS_KEY,
        });
        const s3 = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
        });

        const imageUri = await resizeFile(options.file, options.imgWidth, options.imgHeight, options.imgType, options.quality);
        try {
            if(imageUri){
                const base64ImageData = imageUri;
                // Extract the image data from the data URI
                const base64Data = base64ImageData.replace(/^data:image\/\w+;base64,/, '');

                // Create a Uint8Array from the Base64 data
                const imageBuffer = new Uint8Array(atob(base64Data).split('').map(char => char.charCodeAt(0)));

                const params = {
                    Bucket: S3_BUCKET,
                    Key: options.fileName,
                    Body: imageBuffer,
                    ContentEncoding: 'base64',
                    ContentType: 'image/jpeg'
                };
                const resS3 = await s3.upload(params).promise(); // Use .promise() to await the promise
                return resS3; // You can return the response data if needed
            }            
        } catch (error) {
            Swal.fire(error.message,'','error')
        }
    }
}
