import { commonRequest } from "../../services/ApiCall";
import { Helpers } from "../../services/Helpers";

export const CustomerService = {
    unsortedCustomerList : async (data) =>{
        return await commonRequest("get",  `${Helpers.URL()}/api/customer`, {}, Helpers.token());
    },
    sortedCustomerList : async (data) =>{
        return await commonRequest("get",  `${Helpers.URL()}/api/customer?page=${data.page+1}&limit=${data.limit}&searchKey=${data.filters.searchKey}`, {}, Helpers.token());
    },

    customorDetailById : async (id) =>{
        return await commonRequest("get", `${Helpers.URL()}/customer/${id}`, {}, Helpers.token());
    },

    addCustomer : async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/api/public/customer/createCustomer`,data, Helpers.token());
    },

    updateCustomer : async (id, data) =>{
        return await commonRequest("PUT",  `${Helpers.URL()}/customer/${id}`, data, Helpers.token());
    },

    changeStatus: async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/customer/statusChange`,data, Helpers.token());
    },

    // addBankDetail : async (data) =>{
    //     return await commonRequest("POST", `${Helpers.URL()}/users/bankDetail/create`,data, Helpers.token());
    // },

    // updateBankDetail : async (id, data) =>{
    //     return await commonRequest("PUT",  `${Helpers.URL()}/users/bankDetail/${id}`, data, Helpers.token());
    // },

    // addAddressDetail : async (data) =>{
    //     return await commonRequest("POST", `${Helpers.URL()}/users/addressDetail/create`,data, Helpers.token());
    // },

    // updateAddressDetail : async (id, data) =>{
    //     return await commonRequest("PUT",  `${Helpers.URL()}/users/addressDetail/${id}`, data, Helpers.token());
    // },

    // deleteBrand : async (id) =>{
    //     return await commonRequest("DELETE",  `${Helpers.URL()}/brand/${id}`, {}, Helpers.token());
    // },
    
}