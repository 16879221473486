import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  Typography,
  Grid,
  Box,
  InputLabel,
  Stack,
} from '@mui/material';
import { User } from './UserService';
import Swal from 'sweetalert2';
import { Helpers } from '../../services/Helpers';

export default function BankDetails({ userIdProp, bankDetail, onSubmitBank, tabNextChange }) {
  const { control, handleSubmit, setValue, formState: { errors } } = useForm({
    mode: "onChange"
  });
  const validationRules = {
    required: 'This field is required',
  };

  useEffect(() => {
    
    if (bankDetail) {
      setValue('bank_name', bankDetail.bank_name);
      setValue('bank_account_number', bankDetail.bank_account_number);
      setValue('ifsc_code', bankDetail.ifsc_code);
      setValue('user_bank_name', bankDetail.user_bank_name);
      setValue('blank_check_image', bankDetail.blank_check_image);
      setValue('paytm_mobile_no', bankDetail.paytm_mobile_no);
      setValue('phonepay_mobile_no', bankDetail.phonepay_mobile_no);
      setValue('gpay_mobile_no', bankDetail.gpay_mobile_no);
      setValue('upi_id', bankDetail.upi_id);
    }
  }, [bankDetail]);

  const handleNext = () => {
    tabNextChange(2)
  }
  const onSubmit = (data) => {
    const bankData = {
      userId: userIdProp,
      bank_name: data.bank_name,
      bank_account_number: data.bank_account_number,
      ifsc_code: data.ifsc_code,
      user_bank_name: data.user_bank_name,
      blank_check_image: data.blank_check_image,
      paytm_mobile_no: data.paytm_mobile_no,
      phonepay_mobile_no: data.phonepay_mobile_no,
      gpay_mobile_no: data.gpay_mobile_no,
      upi_id: data.upi_id,
    }
    try{
      const createRow = async () => {
        let createRowRes = "";
        let msg = "";
        if (userIdProp > 0 && bankDetail.id>0) {        
          createRowRes = await User.updateBankDetail(userIdProp, bankData);
          bankData.id = userIdProp;
          msg="Update Successfully";
        } else {
          if (userIdProp > 0) { 
            createRowRes = await User.addBankDetail(bankData);
            bankData.id = createRowRes.data.id;
            msg="Bank Detail Saved";
          }
        }
  
        if (createRowRes.success) {
          onSubmitBank(bankData)
          Swal.fire(msg, '', 'success')
        } else {
          Swal.fire(createRowRes.message, '', 'error');
        }
      }
      createRow();
    }catch(error){
      Swal.fire(error.message, '', 'error');
    }    
  };


  return (
    <Box sx={{ overflow: "auto", backgroundColor: "#fff" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} sx={{ paddingTop: '15px' }}>
          <Grid item lg={5} xs={12}>
            <Grid container spacing={2} sx={{ paddingTop: '15px' }}>
              <Grid item lg={6} xs={12}>
                <Controller
                  name="bank_name"
                  control={control}
                  defaultValue=""
                  rules={validationRules}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter Bank Name"
                      label="Bank Name"
                      size="small"
                      fullWidth
                      error={!!errors.bank_name}
                      helperText={errors.bank_name && errors.bank_name.message}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <Controller
                  name="bank_account_number"
                  control={control}
                  defaultValue=""
                  rules={validationRules}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter Bank A/C Number"
                      label="Bank A/C Number"
                      size="small"
                      fullWidth
                      error={!!errors.bank_account_number}
                      helperText={errors.bank_account_number && errors.bank_account_number.message}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <Controller
                  name="ifsc_code"
                  control={control}
                  defaultValue=""
                  rules={validationRules}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter Bank IFSC Code"
                      label="IFSC Code"
                      size="small"
                      fullWidth
                      error={!!errors.ifsc_code}
                      helperText={errors.ifsc_code && errors.ifsc_code.message}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <Controller
                  name="user_bank_name"
                  control={control}
                  defaultValue=""
                  rules={validationRules}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter Register User Name In Bank"
                      label="User Name"
                      size="small"
                      fullWidth
                      error={!!errors.user_bank_name}
                      helperText={errors.user_bank_name && errors.user_bank_name.message}
                    />
                  )}
                />
              </Grid>
              
              <Grid item lg={6} xs={12}>
                <Controller
                  name="paytm_mobile_no"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter PayTm Mobile No"
                      label="Paytm Mobile Number"
                      size="small"
                      fullWidth
                      error={!!errors.paytm_mobile_no}
                      helperText={errors.paytm_mobile_no && errors.paytm_mobile_no.message}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <Controller
                  name="phonepay_mobile_no"
                  control={control}
                  defaultValue=""
                  
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter PhonePay Mobile No"
                      label="PhonePay Mobile Number"
                      size="small"
                      fullWidth
                      error={!!errors.phonepay_mobile_no}
                      helperText={errors.phonepay_mobile_no && errors.phonepay_mobile_no.message}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <Controller
                  name="gpay_mobile_no"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter GPAY Mobile Number"
                      label="GPAY Mobile Number"
                      size="small"
                      fullWidth
                      error={!!errors.gpay_mobile_no}
                      helperText={errors.gpay_mobile_no && errors.gpay_mobile_no.message}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <Controller
                  name="upi_id"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter UPI Id"
                      label="UPI Id"
                      size="small"
                      fullWidth
                      error={!!errors.upi_id}
                      helperText={errors.upi_id && errors.upi_id.message}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <Stack direction="row">
                  <Button type="submit" variant="contained" color="primary">
                    Save
                  </Button>
                  <Box sx={{ flexGrow: 1 }}></Box>
                  <Button type="button" onClick={handleNext} variant="contained" color="primary">
                    Next
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={7} xs={12}>

          </Grid>
        </Grid>
      </form>
    </Box >
  );
}
