import { commonRequest } from "../../services/ApiCall";
import { Helpers } from "../../services/Helpers";

export const Services = {
    // Payment API
    createOrderStatusQrPayment : async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/payment/create`,data, Helpers.token());
    },

    getOrderPaymentList: async (id) =>{
        return await commonRequest("GET",  `${Helpers.URL()}/payment/${id}`, {}, Helpers.token());
    },

    changePaymentStatus : async (id, data) =>{
        return await commonRequest("PUT",  `${Helpers.URL()}/payment/status/${id}`, data, Helpers.token());
    },

    updatePaidPaymentImg: async (id, data) =>{
        return await commonRequest("PUT",  `${Helpers.URL()}/payment/orderPaid/img/${id}`, data, Helpers.token());
    },

    generateNewQr : async (amount) => {
        return await commonRequest("GET",  `https://upiqr.in/api/qr?name=Akash&vpa=8755667535@ybl&amount=${amount}`);        
    },

    // Order Api
    orderList : async (data) =>{
        return await commonRequest("POST",  `${Helpers.URL()}/order/`, data, Helpers.token());
    },

    orderById:  async (id, sellerId) =>{
        return await commonRequest("GET",  `${Helpers.URL()}/order/${id}/${sellerId}`, {}, Helpers.token());
    },

    updateStoreOrder : async (id, data) =>{
        return await commonRequest("PUT",  `${Helpers.URL()}/order/${id}`, data, Helpers.token());
    },

    createUpdateOrderMsg : async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/order/message/create`,data, Helpers.token());
    },

    // getSellerProductBySellerProdId :  async (id) =>{
    //     return await commonRequest("GET",  `${Helpers.URL()}/store_products/${id}`);
    // },

    

    orderStatusList :  async (id) =>{
        return await commonRequest("GET",  `${Helpers.URL()}/order/orderstatusList`);
    },

    addNewOrderProduct: async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/order/product/create`,data, Helpers.token());
    },

    updateNewOrderProduct: async (id, data) =>{
        return await commonRequest("PUT", `${Helpers.URL()}/order/product/${id}`, data, Helpers.token());
    },

    

    // disableProduct : async (id) =>{
    //     return await commonRequest("DELETE",  `${Helpers.URL()}/store_products/${id}`, {}, Helpers.token());
    // },
    
}