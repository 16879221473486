import { useState, useEffect, useCallback } from 'react';
import { Card, Typography, Divider, Stack, TextField, Grid, Button, Box, Toolbar, FormControl, InputLabel, Select, Tooltip, IconButton } from '@mui/material';
import Swal from 'sweetalert2';
import Modal from '@mui/material/Modal';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { isMobile } from 'react-device-detect';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import { User } from './UserService';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import { formatDateTime } from '../../utils/dateUtils';
import { useForm, Controller } from 'react-hook-form';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';


const style = {
    position: 'absolute',
    top: (isMobile) ? '80%' : '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: (isMobile) ? "95%" : "25%",
    bgcolor: 'background.paper',
    border: '2px solid #ddd',
    boxShadow: 24,
    p: 2,
};


export default function UserList() {
    const { control, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm({
        mode: "onChange"
    });
    /************************  Table Data State Manage **************************** */
    const [allRowCount, setAllRowCount] = useState(0);
    const [list, setList] = useState([]);
    const [deleteRowData, setDeleteRowData] = useState(0);
    const [controller, setController] = useState({
        page: 0,
        pageSize: 5,
        filters: {
            searchKey: "",
            status: 1,
        }
    });
    
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    /************************  Manage Use Effect **************************** */
    useEffect(() => {
        const getData = async () => {
            try {
                setLoading(true)
                const listResponse = await User.allUser({
                    page: controller.page,
                    limit: controller.pageSize,
                    filters: {
                        searchKey: controller.filters.searchKey,   // Use the title filter from the controller
                        status: controller.filters.status,
                    }
                });
                setValue('status', controller.filters.status)
                if (listResponse.success) {
                    setList(listResponse.data.list);
                    setAllRowCount(listResponse.data.totalRows);
                } else {
                    Swal.fire("Request failed",'', 'error');
                }
            } catch (error) {
                Swal.fire(error.message,'', 'error')
            }
            setLoading(false)
        };
        getData();
    }, [controller, deleteRowData]);

    /************************  Table Column Manage **************************** */
    const columns = [
        { field: "id", headerName: "User ID", width: 100, editable: false },
        {
            field: "name", headerName: "Name", width: 150, editable: false, valueGetter: (params) => {
                return `${params.row.first_name} ${params.row.last_name}`;
            }
        },
        { field: "email", headerName: "@EMAIL", width: 200, editable: false },
        { field: "mobile_number", headerName: "Mobile Number", width: 170, editable: false },
        {
            field: "role", headerName: "User Type", width: 150, editable: false, renderCell: (params) => {
                return (
                    <>
                        <Box>
                            {params.row.role == 2 && (
                                <Typography sx={{ backgroundColor: "#d7e9f7", width: "113px", color: "#1565c0", textAlign: "center", borderRadius: "4px", fontSize: "13px", padding: "4px 10px" }}>
                                    Seller
                                </Typography>
                            )}
                            {params.row.role == 3 && (
                                <Typography sx={{ backgroundColor: "#f5ff9c", width: "113px", color: "#827717", textAlign: "center", borderRadius: "4px", fontSize: "13px", padding: "4px 10px" }}>
                                    Sarthi
                                </Typography>
                            )}
                            {params.row.role == 4 && (
                                <Typography sx={{ backgroundColor: "#ffe9b9", width: "113px", color: "#e65100", textAlign: "center", borderRadius: "4px", fontSize: "13px", padding: "4px 10px" }}>
                                    Super Admin
                                </Typography>
                            )}
                        </Box>
                    </>
                )
            }
        },
        {
            field: "status", headerName: "Status", width: 100, renderCell: (params) => {
                return (
                    <>
                        {params.row.status ? (
                            <Button
                                variant="contained"
                                size='small'
                                sx={{ fontSize: "11px" }}
                                color="success"
                                onClick={(e) => changeStatus(params.row.id, params.row.status)}
                            >
                                Active
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                size='small'
                                sx={{ fontSize: "11px" }}
                                color="error"
                                onClick={(e) => changeStatus(params.row.id, params.row.status)}
                            >
                                InActive
                            </Button>
                        )}
                    </>
                )
            }
        },
        {
            field: "createdAt", headerName: "Created Date", width: 250, valueGetter: (params) => {
                return formatDateTime(params.row.createdAt)
            }
        },
        {
            field: "actions", headerName: "Action", width: 100, renderCell: (params) => {

                return (
                    <Stack spacing={2} direction="row">
                        <RemoveRedEyeIcon
                            style={{
                                fontSize: "20px",
                                color: "blue",
                                cursor: "pointer",
                            }}
                            className="cursor-pointer"
                            onClick={(e) => navigate(`/admin/userProfile/${params.row.id}`)}
                        />
                        {/* <DeleteIcon
                            style={{
                                fontSize: "20px",
                                color: "red",
                                cursor: "pointer",
                            }}
                            className="cursor-pointer"
                            onClick={(e) => deleteRow(params.row.id)}
                        /> */}
                    </Stack>
                );
            }
        },
    ];

    /************************  Remove table row **************************** */
    const removeTableRow = async (id) => {
        // if (!id) return;
        // const delP = await Brand.deleteBrand(id);
        // if (delP.success) {
        //     setDeleteRow(id);
        //     Swal.fire('Deleted!', '', 'success')
        // } else {
        //     Swal.fire(delP.message, '', 'error')
        // }
    }

    const deleteRow = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Yes, delete it!',
            denyButtonText: `No, cancel!`,
        }).then((result) => {
            if (result.isConfirmed) {
                removeTableRow(id);
            }
        })
    }

    const handlePaginationChange = (newPagination) => {
        setController({
            ...controller,
            page: newPagination.page,
            pageSize: newPagination.pageSize
        });
    }

    function onSubmit(data) {
        const filtersData = getValues();
        setController({
            ...controller,
            filters: {
                ...controller.filters,
                searchKey: filtersData.searchKey,
                status: filtersData.status
            }
        });
    }

    const updateStatus = async (id, status) => {
        if (!id) return;
        const statusRes = await User.changeStatus({ id: id, status: status });
        if (statusRes.success) {
            setDeleteRowData(id);
            Swal.fire('Status Change Successfully', '', 'success')
        } else {
            Swal.fire(statusRes.message, '', 'error')
        }
    }
    const changeStatus = (id, status) => {
        Swal.fire({
            title: 'Are you sure to change status?',
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Yes, Change Status!',
            denyButtonText: `No, cancel!`,
        }).then((result) => {
            if (result.isConfirmed) {
                const newStatus = (status == 0) ? 1 : 0;
                updateStatus(id, newStatus);
            }
        })
    }
    const clearFilters = () => {
        reset();
        setController({
            ...controller,
            filters: {
                ...controller.filters,
                searchKey: "",
                status: ""
            }
        });
    }
    return (
        <>
            <Card sx={{ minWidth: "100%", marginBottom: "10px" }}>
                <Stack direction="row" spacing={2} className="">
                    <Typography
                        gutterBottom
                        variant='h5'
                        component='div'
                        sx={{ padding: '20px' }}
                    >
                        Manage Users
                    </Typography>
                    <Typography
                        variant='h3'
                        component='div'
                        sx={{ flexGrow: 1 }}
                    ></Typography>
                    <Typography
                        gutterBottom
                        variant='h5'
                        component='div'
                        sx={{ padding: '10px' }}
                    >
                        <Button variant='contained' onClick={() => navigate(`/admin/userProfile/0`)} sx={{ height: "30px", marginTop: "10px" }} endIcon={<AddCircleIcon />}>
                            Add
                        </Button>
                    </Typography>

                </Stack>
            </Card>

            <Card sx={{ minWidth: "100%", marginBottom: "10px" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} sx={{ p: 2 }}>
                        <Grid item xs={12} lg={2}>
                            <Controller
                                name="searchKey"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        placeholder="Search here....."
                                        label="Search"
                                        size="small"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} lg={2}>
                            <FormControl sx={{ minWidth: "100%" }} size="small">
                                <InputLabel id="demo-simple-select-autowidth-label">Select Status</InputLabel>
                                <Controller
                                    name="status"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            label="Select Status"
                                        >
                                            <MenuItem value="2">All</MenuItem>
                                            <MenuItem value="1">Active</MenuItem>
                                            <MenuItem value="0">Inactive</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={2}>
                            <Tooltip title="Search Button">
                                <IconButton
                                    color="primary"
                                    type="submit"
                                    sx={{ border: "1px solid", borderRadius: "7px", padding: "6px 6px" }}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Clear All Filters">
                                <IconButton
                                    color="primary"
                                    sx={{ marginLeft: "10px", border: "1px solid", color: "#f00", borderRadius: "7px", padding: "6px 6px" }}
                                    onClick={clearFilters}
                                >
                                    <RestartAltIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </form>
            </Card>

            <Box sx={{ overflow: "auto", backgroundColor: "#fff" }}>
                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                    <DataGrid
                        rows={list}
                        columns={columns}
                        getRowId={(row) => row.id}
                        //checkboxSelection={true}
                        rowCount={allRowCount}  // Use the length of the filtered list
                        pageSizeOptions={[5, 10, 20, 50, 100]}
                        paginationModel={controller}
                        paginationMode="server"
                        onPaginationModelChange={handlePaginationChange}
                        components={{ Toolbar: GridToolbar }}

                        loading={loading}

                        disableColumnMenu
                    // slots={{
                    //     noRowsOverlay: () => <CustomNoRowsOverlay />,
                    // }}
                    />
                </Box>
            </Box>
        </>
    )
}