import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  Box,
  Stack,
  Divider,
  Autocomplete,
} from '@mui/material';
import { User } from './UserService';
import Swal from 'sweetalert2';

export default function AddressDetails({ userIdProp, addressDetail, onSubmitAddress, tabNextChange }) {
  const { control, handleSubmit, setValue, formState: { errors } } = useForm({
    mode: "onChange"
  });
  const validationRules = {
    required: 'This field is required',
  };
  const [locations, setLocations] = useState([]);
  const searchPincode = async (pincode) => {
    const locations = await User.locationByPincode(pincode);
    console.log(locations)
    if (locations.success) {
      if(locations.data){
        setValue('district', locations.data.district);
        setValue('state', locations.data.state);
      }else{
        setValue('district', "");
        setValue('state', "");
      }
    }
  }
  useEffect(() => {
    if (addressDetail) {
      setValue('full_address', addressDetail.full_address);
      setValue('home_building_no', addressDetail.home_building_no);
      setValue('landmark', addressDetail.landmark);
      setValue('city', addressDetail.city);
      setValue('district', addressDetail.district);
      setValue('state', addressDetail.state);
      setValue('postal_code', addressDetail.postal_code);
    }
  }, [addressDetail]);
  const onPincodeChange = (e) => {
    
    const value = e.target.value;
    console.log(value.length)
    if (value.length === 6) {
      console.log("ddddddddd",value.length)
      searchPincode(value);
    }
  }
  const handleNext = () => {
    tabNextChange(0)
  }
  const onSubmit = (data) => {
    if(data.postal_code.length>6 || data.postal_code.length<6){
      Swal.fire("PINCODE should be 6 digits.", '', 'error')
      return false;
    }
    const addressData = {
      userId: userIdProp,
      full_address: data.full_address,
      home_building_no: data.home_building_no,
      landmark: data.landmark,
      city: data.city,
      district: data.district,
      state: data.state,
      postal_code: data.postal_code,
    }
    try {
      const createRow = async () => {
        let createRowRes = "";
        let msg = "";
        if (userIdProp > 0 && addressDetail.id > 0) {
          console.log("adddd", addressData)
          createRowRes = await User.updateAddressDetail(userIdProp, addressData);
          addressData.id = userIdProp;
          msg = "Update Successfully";
        } else {
          if (userIdProp > 0) {
            createRowRes = await User.addAddressDetail(addressData);
            addressData.id = createRowRes.data.id;
            msg = "Address Detail Saved";
          }
        }

        if (createRowRes.success) {
          onSubmitAddress(addressData)
          Swal.fire(msg, '', 'success')
        } else {
          Swal.fire(createRowRes.message, '', 'error');
        }
      }
      createRow();
    } catch (error) {
      Swal.fire(error.message, '', 'error');
    }
  };


  return (
    <Box sx={{ overflow: "auto", backgroundColor: "#fff" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} sx={{ paddingTop: '15px' }}>
          <Grid item lg={5} xs={12}>
            <Grid container spacing={2} sx={{ paddingTop: '15px' }}>
              <Grid item lg={6} xs={12}>
                <Controller
                  name="full_address"
                  control={control}
                  defaultValue=""
                  rules={validationRules}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter Full Name"
                      label="Full Name"
                      size="small"
                      fullWidth
                      error={!!errors.full_address}
                      helperText={errors.full_address && errors.full_address.message}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <Controller
                  name="home_building_no"
                  control={control}
                  defaultValue=""
                  rules={validationRules}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter Home/Building Number"
                      label="Home/Building Number"
                      size="small"
                      fullWidth
                      error={!!errors.home_building_no}
                      helperText={errors.home_building_no && errors.home_building_no.message}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <Controller
                  name="landmark"
                  control={control}
                  defaultValue=""
                  rules={validationRules}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter Landmark"
                      label="Landmark"
                      size="small"
                      fullWidth
                      error={!!errors.landmark}
                      helperText={errors.landmark && errors.landmark.message}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <Controller
                  name="city"
                  control={control}
                  defaultValue=""
                  rules={validationRules}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter City"
                      label="City"
                      size="small"
                      fullWidth
                      error={!!errors.city}
                      helperText={errors.city && errors.city.message}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={12} xs={12}><Divider /></Grid>
              <Grid item lg={6} xs={12}>
                {/* <Autocomplete
                  id="combo-box-demo"
                  options={locations}
                  onInputChange={onPincodeChange}
                  getOptionLabel={(option) => `${option.pincode}, ${option.pincode}`}
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Pincode"
                      variant="outlined"
                    />
                  )}
                /> */}
                <Controller
                  name="postal_code"
                  control={control}
                  defaultValue=""
                  rules={validationRules}                  
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter Postal Code"
                      label="Postal Code"
                      size="small"
                      type="number"                      
                      fullWidth
                      onChange={(e) => {
                        field.onChange(e);
                        onPincodeChange(e);
                      }}
                      error={!!errors.postal_code}
                      helperText={errors.postal_code && errors.postal_code.message}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6} xs={0}></Grid>
              <Grid item lg={6} xs={12}>
                <Controller
                  name="district"
                  control={control}
                  defaultValue=""
                  rules={validationRules}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter District"
                      label="District"
                      size="small"
                      fullWidth
                      error={!!errors.district}
                      helperText={errors.district && errors.district.message}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <Controller
                  name="state"
                  control={control}
                  defaultValue=""
                  rules={validationRules}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter State"
                      label="State"
                      size="small"
                      fullWidth
                      error={!!errors.state}
                      helperText={errors.state && errors.state.message}
                    />
                  )}
                />
              </Grid>

              <Grid item lg={12} xs={12}>
                <Stack direction="row">
                  <Button type="submit" variant="contained" color="primary">
                    Save
                  </Button>
                  <Box sx={{ flexGrow: 1 }}></Box>
                  <Button type="button" onClick={handleNext} variant="contained" color="primary">
                    Next
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={7} xs={12}>

          </Grid>
        </Grid>
      </form>
    </Box >
  );
}
