import { createReducer } from "@reduxjs/toolkit";
import { isMobile } from "react-device-detect";

const intialState = {
    menu: (isMobile) ? false : true,
    menuDefaultSelected: "dashboard"
};

export const customReducer = createReducer(intialState, {
    menuStatus: (state) => {
        state.menu = !state.menu;
    },
    menuSelect: (state, action) => {
        state.menuDefaultSelected = action.payload
    }
});